<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :inline="true"
      label-position="top"
      class="flow-form">
      <header-title title="现经销商个人基础信息" />
      <RequiredFormItemInput
        class="formItem"
        prop="idCard"
        label="身份证"
        v-model="form.idCard"
        :rules="rules.idCard"
        :disabled="isOA || disabled"
        @blur="getBossDetail($event)" />
      <RequiredFormItemInput
        class="formItem"
        prop="realName"
        label="经销商（实际控股人）"
        v-model="form.realName"
        :rules="rules.realName"
        maxlength="20"
        :disabled="isOA || isDisableEdit || disabled"
        @change="changeRealName" />
      <RequiredFormItemInput
        class="formItem"
        prop="contactInfo"
        label="经销商（实际控股人）联系方式"
        v-model="form.contactInfo"
        :rules="rules.contactInfo"
        maxlength="20"
        :disabled="isOA || isDisableEdit || disabled" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="education"
        label="学历"
        v-model="form.education"
        :required="false"
        type="education"
        :disabled="isOA || isDisableEdit || disabled" />
      <RequiredFormItemInput
        class="formItem"
        prop="age"
        label="年龄"
        v-model="form.age"
        :rules="rules.age"
        type="number"
        :min="1"
        maxlength="4"
        :disabled="isOA || isDisableEdit || disabled" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="maritalStatus"
        label="婚姻状况"
        v-model="form.maritalStatus"
        :required="false"
        type="marital_status"
        :disabled="isOA || isDisableEdit || disabled" />
      <RequiredFormItemInput
        class="formItem"
        prop="nativePlace"
        label="籍贯"
        v-model="form.nativePlace"
        maxlength="10"
        :disabled="isOA || isDisableEdit || disabled" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="sex"
        label="性别"
        v-model="form.sex"
        type="sex"
        :disabled="isOA || isDisableEdit || disabled" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="channelType"
        v-model="form.channelType"
        label="经销商类型"
        type="dealer_type"
        network />
      <RequiredModuleFormItemSelect
        class="formItem"
        ref="secondaryChannelTypeRef"
        v-model="form.secondaryChannelType"
        type="dealer_sub_type"
        label="经销商类型二级分类"
        @change="
          () => {
            form.thirdChannelType = ''
          }
        " />
      <RequiredModuleFormItemSelect
        class="formItem"
        ref="thirdChannelTypeRef"
        v-model="form.thirdChannelType"
        type="dealer_thirdary_type"
        label="经销商类型三级分类"
        :parent-code="form.secondaryChannelType"
        emptyDisabled
        dependentParentCode />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="ifRelevance"
        v-model="form.ifRelevance"
        label="是否关联方"
        type="ifRelevance" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="joinNature"
        v-model="form.joinNature"
        label="经销商性质"
        type="join_nature" />
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemInput,
  RequiredModuleFormItemSelect
} from '@/components/CustomFormItem'
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  validUserId,
  validName,
  phoneReg,
  userIdReg
} from '@/utils/useFormValidator'
import { checkNumber } from '@/utils/validate'
import HeaderTitle from './headerTitle.vue'
import { getBusinessCityList, getBossDetail } from '@/api/businessOos'
import { checkBossPhone } from '@/api/common'
export default {
  props: {
    authorizedCityCode: {
      type: String,
      default: ''
    },
    formStatus: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    RequiredFormItemInput,
    RequiredModuleFormItemSelect,
    HeaderTitle
  },
  data() {
    const check_boss_number = async (rule, value, callback) => {
      try {
        phoneReg.lastIndex = 0
        if (!phoneReg.test(value)) {
          callback(new Error('格式有误'))
          return
        }
        if (!this.isOA && !this.isDisableEdit) {
          await checkBossPhone({
            mobilePhone: value
          }).then(res => {
            if (res.data) {
              callback(new Error('该手机号码被占用'))
            } else {
              callback()
            }
          })
        } else {
          callback()
        }
      } catch {
        callback()
      }
    }
    return {
      form: {
        realName: '',
        contactInfo: '',
        idCard: '',
        education: '',
        age: '',
        maritalStatus: '',
        nativePlace: '',
        sex: '',
        channelType: '', //经销商分类"
        secondaryChannelType: '', //经销商二级分类 "
        thirdChannelType: '', //经销商三级分类 "
        ifRelevance: '',
        joinNature: ''
      },
      keyArr: [
        'channelType',
        'secondaryChannelType',
        'thirdChannelType',
        'ifRelevance',
        'joinNature'
      ],
      disabled: true,
      isDisableEdit: true,
      rules: {
        realName: [{ validator: validName, trigger: 'blur' }],
        contactInfo: [{ validator: check_boss_number, trigger: 'blur' }],
        idCard: [{ validator: validUserId, trigger: 'blur' }],
        age: [{ validator: checkNumber, trigger: ['blur', 'change'] }]
      }
    }
  },
  watch: {
    authorizedCityCode(val) {
      if (this.formStatus == '' || [0, 20].includes(this.formStatus)) {
        if (val && this.businessLicense) {
          let params = {
            bossId: this.businessLicense['bossId'],
            authorizedCityCode: val
          }
          this.getBusinessCityList(params)
        }
      }
    },
    businessLicense: {
      handler(businessLicense) {
        if (this.formStatus == '' || [0, 20].includes(this.formStatus)) {
          for (const key in this.form) {
            if (!this.keyArr.includes(key)) {
              if (!businessLicense) {
                this.form[key] = ''
              } else {
                this.form[key] = businessLicense[key]
              }
            } else {
              if ([0, 20].includes(this.formStatus)) {
                const { bossJoinModel } = this.historicalData
                this.form[key] = bossJoinModel[key]
              }
            }
          }
          if (!businessLicense) {
            this.disabled = false
          } else {
            if (this.authorizedCityCode) {
              let params = {
                bossId: businessLicense['bossId'],
                authorizedCityCode: this.authorizedCityCode
              }
              this.getBusinessCityList(params)
            }
            this.disabled = true
          }
          this.$emit('changeRealName', this.form['realName'])
        }
      },
      deep: true
    },
    historicalData: {
      handler(historicalData) {
        const { bossJoinModel } = historicalData
        const { dealerBoss, dealerBusinessLicense } = bossJoinModel
        const { creditCode } = dealerBusinessLicense
        for (const key in this.form) {
          if (this.keyArr.includes(key) && this.formStatus !== '') {
            this.form[key] = bossJoinModel[key]
          } else {
            if (dealerBoss[key]) {
              this.form[key] = dealerBoss[key]
            }
          }
        }
        if (
          (this.formStatus == '' || [0, 20].includes(this.formStatus)) &&
          this.form['idCard']
        ) {
          this.changeIsDisableEdit(this.form['idCard'])
          if (!creditCode) {
            this.disabled = false
          }
        } else {
          this.disabled = true
        }
        this.$emit('changeRealName', this.form['realName'])
      },
      deep: true
    }
  },
  computed: {
    ...mapState('data', [
      'educationData',
      'dealerType',
      'maritalStatus',
      'sex'
    ]),
    ...mapState('dealershipTransfer', [
      'isOA',
      'dealerBossInfo',
      'historicalData',
      'businessLicense'
    ])
  },
  methods: {
    ...mapMutations('dealershipTransfer', [
      'setDealerBossInfo',
      'setBusinessLicense'
    ]),
    ...mapActions('dealershipTransfer', [
      'getDealerBossInfo',
      'getBusinessLicense'
    ]),
    async changeIsDisableEdit(val) {
      const { code, data } = await getBossDetail({ id_card: val })
      if (code != 0) {
        return
      }
      data.length ? (this.isDisableEdit = true) : (this.isDisableEdit = false)
    },
    async getBossDetail(e) {
      if (!e.target.value) {
        return
      }
      if (!userIdReg.test(e.target.value)) {
        return
      }
      const { code, data } = await getBossDetail({ id_card: e.target.value })
      if (code != 0) {
        return
      }
      if (data.length) {
        this.isDisableEdit = true
        for (const key in this.form) {
          if (!this.keyArr.includes(key)) {
            if (!!data[0][key]) {
              this.form[key] = data[0][key]
            } else {
              if (key !== 'idCard') {
                this.form[key] = ''
              }
            }
          }
        }
      } else {
        this.isDisableEdit = false
      }
      this.$emit('changeRealName', this.form['realName'])
    },
    getBusinessCityList(params) {
      getBusinessCityList(params).then(res => {
        if (res.code === 0 && res.data.length) {
          let data = res.data[0]
          let { channelType, secondaryChannelType, thirdChannelType } = data
          this.form.channelType = channelType
          this.form.secondaryChannelType = secondaryChannelType
          this.form.thirdChannelType = thirdChannelType
        }
      })
    },
    changeRealName(val) {
      this.$emit('changeRealName', val)
    }
  }
}
</script>
