<!--
 * @Author: wangmq
 * @Date: 2022-12-13 11:43:21
 * @LastEditors: wangmq
 * @LastEditTime: 2023-04-25 11:42:27
-->
<template>
  <div>
    <el-form
      class="flow-form"
      :model="formData"
      :inline="true"
      ref="ruleForm"
      label-position="top"
      @validate="handleValidate"
    >
      <HeaderTitle style="margin-bottom: 10px">
        <template #form>
          <div>
            <RequestInput
              class="formItem"
              label="流程标题"
              :value="flowTitle"
              :required="false"
              readonly
            />
            <!-- <RequestSelect
            disabled
            class="formItem"
            label="加盟类型"
            v-model="formData.joinType"
            prop="joinType"
            type="join_status"
          /> -->
          </div>
        </template>
      </HeaderTitle>
      <HeaderTitle title="一、城市基本信息">
        <template #form>
          <div>
            <RequestFormSelect
              prop="joinProvince"
              class="formItem"
              v-model="formData.joinProvince"
              label="省份"
              :get-data-list="getProvince"
              value-key="province"
              label-key="province"
              @changeSelectedItem="
                e => (formData.joinProvinceCode = e ? e.provinceCode : '')
              "
              :selectListeners="{
                change: data => {
                  if (data) {
                    formData.joinCity = ''
                    formData.joinCityCode = ''
                    formData.joinArea = ''
                    formData.joinAreaCode = ''
                    formData.joinTown = ''
                  }
                }
              }"
            />
            <RequestFormSelect
              prop="joinCity"
              :disabled="!!!formData.joinProvince"
              class="formItem"
              v-model="formData.joinCity"
              label="地级市"
              :get-data-list="getAuthCityList"
              :get-data-list-params="formData.joinProvince"
              value-key="city"
              label-key="city"
              @changeSelectedItem="
                e => (formData.joinCityCode = e ? e.cityCode : '')
              "
              :selectListeners="{
                change: data => {
                  if (data) {
                    formData.joinArea = ''
                    formData.joinAreaCode = ''
                    formData.joinTown = ''
                  }
                }
              }"
            />
            <RequestFormSelect
              prop="joinArea"
              :disabled="!!!formData.joinCity"
              class="formItem"
              v-model="formData.joinArea"
              :required="false"
              label="区/县"
              :get-data-list="getAuthAreaList"
              :get-data-list-params="`${formData.joinProvince},${formData.joinCity}`"
              value-key="area"
              label-key="area"
              @changeSelectedItem="
                e => (formData.joinAreaCode = e ? e.areaCode : '')
              "
              :selectListeners="{
                change: data => {
                  if (data) {
                    formData.joinTown = ''
                  }
                }
              }"
            />
            <RequestInput
              delay-update
              :required="false"
              prop="joinTown"
              class="formItem"
              label="镇/乡"
              v-model="formData.joinTown"
            />
            <RequestInput
              delay-update
              prop="permanentPopulationQuantity"
              class="formItem"
              label="人口(万人)"
              v-model="formData.permanentPopulationQuantity"
              type="number"
            />
            <RequestInput
              delay-update
              prop="localGdp"
              class="formItem"
              label="当地GDP(亿)"
              v-model="formData.localGdp"
              type="number"
            />
            <RequestInput
              delay-update
              prop="perCapitaGdp"
              class="formItem"
              label="人均GDP(万)"
              v-model="formData.perCapitaGdp"
              type="number"
            />
            <RequestInput
              delay-update
              prop="houseAveragePrice"
              class="formItem"
              label="房价均价(元)"
              v-model="formData.houseAveragePrice"
              type="number"
            />
            <RequestFormSelect
              :disabled="!formData.joinCity"
              prop="authorizedCode"
              class="formItem"
              label="授权城市确认"
              :get-data-list="getAuthAreaList"
              :get-data-list-params="`${formData.joinProvince},${formData.joinCity}`"
              v-model="formData.authorizedCode"
              :label-key="
                item => `${item.city}${item.area || ''}${item.town || ''}`
              "
              valueKey="authorizedCityCode"
              @changeSelectedItem="
                e => {
                  formData.authorizedCity = e ? e.authorizedCityName : ''
                  formData.cityLevel = e ? e.marketLevelName : ''
                }
              "
            />
            <RequestInput
              delay-update
              prop="cityLevel"
              class="formItem"
              label="城市级别"
              v-model="formData.cityLevel"
              readonly
              :disabled="true"
            />
            <RequestInput
              delay-update
              prop="salesSituation"
              class="formItem"
              label="软体定制品牌情况及年销售额"
              v-model="formData.salesSituation"
              type="number"
            />
            <RequestInput
              delay-update
              prop="cityAuthSituation"
              class="formItem"
              label="本城市授权情况"
              v-model="formData.cityAuthSituation"
            />
          </div>
        </template>
      </HeaderTitle>
      <HeaderTitle title="二、转让方情况">
        <template #form>
          <div>
            <HeaderTitle title="①经销商基本信息" :hiddenDivider="false">
              <template #form>
                <div style="margin-top: 20px">
                  <RequestInput
                    delay-update
                    prop="assignorLegalPersonName"
                    class="formItem"
                    label="法人姓名"
                    v-model="formData.assignorLegalPersonName"
                  />
                  <RequestInput
                    delay-update
                    prop="assignorLegalPersonContact"
                    class="formItem"
                    label="法人联系方式"
                    v-model="formData.assignorLegalPersonContact"
                    type="number"
                    :validations="validations['contactInfo'] || []"
                  />
                  <RequestInput
                    delay-update
                    prop="assignorLegalPersonIdCard"
                    class="formItem"
                    label="法人身份证"
                    v-model="formData.assignorLegalPersonIdCard"
                    type="number"
                    :validations="validations['idCard'] || []"
                  />
                  <RequestInput
                    delay-update
                    prop="assignorRealName"
                    class="formItem"
                    label="实控人姓名"
                    v-model="formData.assignorRealName"
                  />
                  <RequestInput
                    delay-update
                    prop="assignorContactInfo"
                    class="formItem"
                    label="实控人联系方式"
                    v-model="formData.assignorContactInfo"
                    type="number"
                    :validations="validations['contactInfo'] || []"
                  />
                  <RequestInput
                    delay-update
                    prop="assignorIdCard"
                    class="formItem"
                    label="实控人身份证"
                    v-model="formData.assignorIdCard"
                    type="number"
                    :validations="validations['idCard'] || []"
                  />
                </div>
              </template>
            </HeaderTitle>
            <HeaderTitle title="②门店及代理系列信息" :hiddenDivider="false">
              <template #form>
                <div style="margin-top: 20px">
                  <required-brand-form-item-select
                    class="formItem"
                    :prop="`assignorShopSeriesName`"
                    label="转让方代理系列"
                    v-model="formData.assignorShopSeriesName"
                    :old-value-keys="['id']"
                    :select-props="{
                      valueKey: 'name'
                    }"
                    :value-translate="
                      value => {
                        return value && typeof value === 'string'
                          ? value.split(',')
                          : []
                      }
                    "
                    :value-output-translate="
                      value => {
                        if (
                          Object.prototype.toString.call(value) ===
                          '[object Array]'
                        ) {
                          return value.join(',')
                        }
                        return value
                      }
                    "
                    @changeSelectedItem="
                      e =>
                        (formData.assignorBrandSeriesList = (e || []).map(
                          item => ({
                            code: item.code,
                            name: item.name
                          })
                        ))
                    "
                  />
                  <RequestInput
                    delay-update
                    :prop="`assignorrShopQuantity`"
                    class="formItem"
                    label="目前门店数"
                    v-model="formData.assignorrShopQuantity"
                    type="number"
                  />
                </div>
              </template>
            </HeaderTitle>
            <HeaderTitle title="③经销商经营及转让情况" :hiddenDivider="false">
              <template #form>
                <div style="margin-top: 20px">
                  <RequestInput
                    delay-update
                    class="formItem"
                    :prop="`assignorLastThreeYearDeliveryAmount`"
                    v-model="formData.assignorLastThreeYearDeliveryAmount"
                    label="近三年提货额"
                  />
                  <RequestInput
                    delay-update
                    class="formItem"
                    :prop="`assignorLastThreeYearCompletionRate`"
                    v-model="formData.assignorLastThreeYearCompletionRate"
                    label="近三年完成率"
                  />
                  <RequestInput
                    delay-update
                    class="formItem"
                    :prop="`assignorReason`"
                    v-model="formData.assignorReason"
                    label="转让原因"
                  />
                  <RequestFormSelect
                    :disabled="!formData.joinCity"
                    prop="assignorCityCode"
                    class="formItem"
                    label="转让城市"
                    :get-data-list="getAuthAreaList"
                    :get-data-list-params="`${formData.joinProvince},${formData.joinCity}`"
                    v-model="formData.assignorCityCode"
                    :label-key="
                      item => `${item.city}${item.area || ''}${item.town || ''}`
                    "
                    valueKey="authorizedCityCode"
                    @changeSelectedItem="
                      e => {
                        formData.assignorCity = e ? e.authorizedCityName : ''
                      }
                    "
                  />
                  <required-brand-form-item-select
                    class="formItem"
                    :prop="`assignorSeriesName`"
                    label="转让系列"
                    v-model="formData.assignorSeriesName"
                    :old-value-keys="['id']"
                    :select-props="{
                      valueKey: 'name'
                    }"
                    :value-translate="
                      value => {
                        return value && typeof value === 'string'
                          ? value.split(',')
                          : []
                      }
                    "
                    :value-output-translate="
                      value => {
                        if (
                          Object.prototype.toString.call(value) ===
                          '[object Array]'
                        ) {
                          return value.join(',')
                        }
                        return value
                      }
                    "
                    @changeSelectedItem="
                      e =>
                        (formData.assignorSeriesList = (e || []).map(item => ({
                          code: item.code,
                          name: item.name
                        })))
                    "
                  />
                </div>
              </template>
            </HeaderTitle>
          </div>
        </template>
      </HeaderTitle>
      <HeaderTitle title="三、承接方信息">
        <template #form>
          <div>
            <HeaderTitle title="①经销商基本信息" :hiddenDivider="false">
              <template #form>
                <div style="margin-top: 20px">
                  <RequestInput
                    delay-update
                    prop="legalPersonName"
                    class="formItem"
                    label="法人姓名"
                    v-model="formData.legalPersonName"
                  />
                  <RequestInput
                    delay-update
                    prop="legalPersonContact"
                    class="formItem"
                    label="法人联系方式"
                    v-model="formData.legalPersonContact"
                    type="number"
                    :validations="validations['contactInfo'] || []"
                  />
                  <RequestInput
                    delay-update
                    prop="legalPersonIdCard"
                    class="formItem"
                    label="法人身份证"
                    v-model="formData.legalPersonIdCard"
                    type="number"
                    :validations="validations['idCard'] || []"
                  />
                  <RequestInput
                    delay-update
                    prop="realName"
                    class="formItem"
                    label="实控人姓名"
                    v-model="formData.realName"
                  />
                  <RequestInput
                    delay-update
                    prop="contactInfo"
                    class="formItem"
                    label="实控人联系方式"
                    v-model="formData.contactInfo"
                    type="number"
                    :validations="validations['contactInfo'] || []"
                  />
                  <RequestInput
                    delay-update
                    prop="idCard"
                    class="formItem"
                    label="实控人身份证"
                    v-model="formData.idCard"
                    type="number"
                    :validations="validations['idCard'] || []"
                  />
                </div>
              </template>
            </HeaderTitle>
            <HeaderTitle title="②门店及转接系列信息" :hiddenDivider="false">
              <template #form>
                <div style="margin-top: 20px">
                  <RequestInput
                    delay-update
                    :prop="`contractorShopQuantity`"
                    class="formItem"
                    label="门店数"
                    v-model="formData.contractorShopQuantity"
                    type="number"
                  />
                  <required-brand-form-item-select
                    class="formItem"
                    :prop="`contractorShopSeriesName`"
                    label="承接方代理系列"
                    v-model="formData.contractorShopSeriesName"
                    :old-value-keys="['id']"
                    :select-props="{
                      valueKey: 'name'
                    }"
                    :value-translate="
                      value => {
                        return value && typeof value === 'string'
                          ? value.split(',')
                          : []
                      }
                    "
                    :value-output-translate="
                      value => {
                        if (
                          Object.prototype.toString.call(value) ===
                          '[object Array]'
                        ) {
                          return value.join(',')
                        }
                        return value
                      }
                    "
                    @changeSelectedItem="
                      e =>
                        (formData.contractorBrandSeriesList = (e || []).map(
                          item => ({
                            code: item.code,
                            name: item.name
                          })
                        ))
                    "
                  />
                  <RequestInput
                    delay-update
                    :prop="`contractorPlanShop`"
                    class="formItem"
                    label="计划做慕思的门店"
                    v-model="formData.contractorPlanShop"
                    type="number"
                  />
                </div>
              </template>
            </HeaderTitle>
            <HeaderTitle title="③经销商经营情况" :hiddenDivider="false">
              <template #form>
                <div style="margin-top: 20px">
                  <RequestInput
                    delay-update
                    class="formItem"
                    :prop="`contractorSalesSituation`"
                    v-model="formData.contractorSalesSituation"
                    type="number"
                    label="年销售额"
                  />
                  <RequestInput
                    delay-update
                    class="formItem"
                    :prop="`contractorBankStatement`"
                    v-model="formData.contractorBankStatement"
                    type="number"
                    label="银行流水"
                  />
                  <RequestInput
                    delay-update
                    class="formItem"
                    :prop="`contractorTeamQuantity`"
                    v-model="formData.contractorTeamQuantity"
                    type="number"
                    label="团队人数"
                  />
                </div>
              </template>
            </HeaderTitle>
          </div>
        </template>
      </HeaderTitle>
      <HeaderTitle title="当地市场情况（建材、家居、家电）">
        <template v-if="!isOA">
          <div>
            <el-button type="primary" @click="addMarketData">新增</el-button>
            <el-button
              :disabled="!marketDataListRowId.length"
              type="danger"
              @click="delMarketData"
              >删除</el-button
            >
          </div>
        </template>
        <template #form>
          <el-form-item style="width: 100%">
            <el-table
              border
              row-key="sort"
              ref="filterTable"
              :data="formData.localMarketSituationList"
              @selection-change="handleSelectionMarketChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="序号" type="index" width="55">
              </el-table-column>
              <el-table-column label="品牌名称">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      class="selectItem"
                      :prop="`localMarketSituationList.${$index}.brandName`"
                      v-model="row.brandName"
                      label="品牌名称"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="经营情况概述">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      class="selectItem"
                      :prop="`localMarketSituationList.${$index}.businessSummary`"
                      v-model="row.businessSummary"
                      label="经营情况概述"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </HeaderTitle>
      <div>
        <UploadFile
          class="formItem"
          label="上传附件"
          v-model="formData.businessPlanFileRecordList"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'businessPlanFileRecordList')"
        >
          <div slot="describe">
            <div>附件：</div>
            <div>经营计划</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          label="上传附件"
          v-model="formData.openShopPromiseFileList"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'openShopPromiseFileList')"
        >
          <div slot="describe">
            <div>附件：</div>
            <div>开店及业绩承诺</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          label="上传附件"
          v-model="formData.integrityCommitmentFileList"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="
            file => handleUpload(file, 'integrityCommitmentFileList')
          "
        >
          <div slot="describe">
            <div>附件：</div>
            <div>廉洁承诺书</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          label="上传附件"
          v-model="formData.financialStatementFileRecordList"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="
            file => handleUpload(file, 'financialStatementFileRecordList')
          "
        >
          <div slot="describe">
            <div>附件：</div>
            <div>资金证明</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          label="上传附件"
          v-model="formData.idCardFileRecordList"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="file => handleUpload(file, 'idCardFileRecordList')"
        >
          <div slot="describe">
            <div>附件：</div>
            <div>身份证复印件</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
        <UploadFile
          class="formItem"
          label="上传附件"
          v-model="formData.shareholderInfoFileRecordList"
          :required="false"
          :onPreview="handlePreview"
          @onUploaded="
            file => handleUpload(file, 'shareholderInfoFileRecordList')
          "
        >
          <div slot="describe">
            <div>附件：</div>
            <div>股东信息（身份证、分配比例等）</div>
          </div>
          <div slot="tip">单文件不能超过400M</div>
        </UploadFile>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
  watchEffect,
  computed,
  onMounted,
  onUnmounted
} from 'vue'
import HeaderTitle from '../headerTitle.vue'
import RequestInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequestSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequestFormSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequestDatePick from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import { getProvinceOrCityOrAreaList } from '@/api/common/'
import { getSession, setSession, removeSession } from '@/utils/storage'
import { getStorage } from '@/utils/storage'
import moment from 'moment'
import { MessageBox, Message } from 'element-ui'
import { requestDraftFlow, requestSubmitFlow } from '@/api/flow/dealerInterview'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router/composables'
const userInfo = getStorage('userInfo')
export default defineComponent({
  components: {
    HeaderTitle,
    RequestInput,
    RequestSelect,
    RequestFormSelect,
    RequestDatePick,
    RequiredBrandFormItemSelect,
    RequestDatePick,
    UploadFile
  },
  props: {
    isOA: Boolean,
    validations: Object,
    dataValue: Object
  },
  setup(props, ctx) {
    const $route = useRoute()
    const $router = useRouter()
    const isCache = ref(true)
    const formId = ref()
    const marketDataListRowId = ref([])
    const formData = ref({
      dealerDeclarationForm: {
        title: '经销商意向加盟申请表',
        optUserId: userInfo?.accountId || '',
        optUserName: userInfo?.realName || '',
        optJobNumber: userInfo?.code || '',
        optDeptId: userInfo?.deptId || '',
        optDeptName: userInfo?.deptName || '',
        optTime: ''
      },
      realName: '',
      age: '',
      idCard: '',
      sex: null,
      education: '',
      maritalStatus: '',
      legalPersonName: '', //法人姓名
      legalPersonContact: '', //法人联系方式
      legalPersonIdCard: '', //法人身份证
      joinType: 1,
      joinTypeName: '转接',
      joinProvince: '',
      joinProvinceCode: '',
      joinCity: '',
      joinCityCode: '',
      joinArea: '',
      joinAreaCode: '',
      joinTown: '',
      authorizedCode: '',
      authorizedCity: '',
      cityLevel: '',
      permanentPopulationQuantity: '',
      flowPopulationQuantity: '',
      localGdp: '',
      perCapitaGdp: '',
      perCapitaIncome: '',
      urbanPopulation: '',
      thisYearhouseDeliverQuantity: '',
      salesSituation: '',
      cityAuthSituation: '',
      houseAveragePrice: '',
      planShopArea: '',
      assignorLastThreeYearDeliveryAmount: '', //转让方 近三年提货额
      assignorLastThreeYearCompletionRate: '', //转让方 近三年完成率
      assignorReason: '', //转让方 转让原因
      assignorCity: '', //转让方 转让城市
      assignorCityCode: '', //转让方 转让城市
      assignorSeriesName: '', //转让方 转让系列
      assignorSeriesList: '', //转让方 转让系列
      //转让方情况 经销商基本信息
      assignorRealName: '', //转让方真实姓名
      assignorContactInfo: '', //转让方联系方式
      assignorIdCard: '', //转让方身份证
      assignorLegalPersonName: '', //转让方法人姓名
      assignorLegalPersonContact: '', //转让方法人联系方式
      assignorLegalPersonIdCard: '', //转让方 转让方法人身份证
      //转让方情况 门店及代理系列信息
      assignorShopSeriesId: '', //转让方 代理系列 ID
      assignorBrandSeriesList: [], //转让方 代理系列 CODE
      assignorShopSeriesName: '', //转让方 代理系列
      assignorrShopQuantity: '', //转让方 门店数
      assignorPlanShop: '', //转让方 计划做慕思的门店
      //转让方情况 经销商经营及转让情况

      //承接方信息 门店及转接系列信息
      contractorShopSeriesId: '', //承接方 代理系列 ID
      contractorBrandSeriesList: [], //承接方 代理系列 CODE
      contractorShopSeriesName: '', //承接方 代理系列
      contractorShopQuantity: '', //承接方 门店数
      contractorPlanShop: '', //承接方 计划做慕思的门店

      //承接方 经销商经营情况
      contractorSalesSituation: '', //承接方 年销售额
      contractorBankStatement: '', //承接方 银行流水
      contractorTeamQuantity: '', //承接方 团队人数˝
      //经营计划
      businessPlanFileRecordList: [],
      //开店及业绩承诺
      openShopPromiseFileList: [],
      //廉洁承诺书
      integrityCommitmentFileList: [],
      //资金证明
      financialStatementFileRecordList: [],
      //身份证复印件
      idCardFileRecordList: [],
      //股东信息（身份证、分配比例等）
      shareholderInfoFileRecordList: [],
      localMarketSituationList: [
        {
          id: '',
          brandName: '',
          businessSummary: ''
        }
      ]
    })
    // watch(formData.value, newVal => {
    //   const val = newVal
    //   const applyBrandSeries = val.contractorShopSeriesName
    //     ? '门店品牌：' + val.contractorShopSeriesName
    //     : ''
    //   let data = [val.authorizedCity, val.realName, applyBrandSeries].filter(
    //     item => item !== ''
    //   )
    //   val.dealerDeclarationForm.title =
    //     data.length > 0
    //       ? `经销商意向加盟申请表（${data.join('，')}）`
    //       : `经销商意向加盟申请表`
    // })
    // watchEffect(() => {
    //   const val = formData.value
    //   const applyBrandSeries = val.contractorShopSeriesName
    //     ? '门店品牌：' + val.contractorShopSeriesName
    //     : ''
    //   let data = [val.authorizedCity, val.realName, applyBrandSeries].filter(
    //     item => item !== ''
    //   )
    //   val.dealerDeclarationForm.title =
    //     data.length > 0
    //       ? `经销商意向加盟申请表（${data.join('，')}）`
    //       : `经销商意向加盟申请表`
    // })
    watchEffect(() => {
      if (formId.value && props.dataValue.dealerDeclarationForm.id) {
        formData.value = props.dataValue
      }
    })

    /**
     * @description: 新增当地市场情况
     */
    const addMarketData = () => {
      formData.value.localMarketSituationList.push({
        sort: formData.value.localMarketSituationList.length,
        id: '',
        brandName: '',
        businessSummary: ''
      })
    }
    /**
     * @description: 删除当地市场情况
     */
    const delMarketData = () => {
      const list = formData.value.localMarketSituationList.filter(
        l => !marketDataListRowId.value.includes(l.sort)
      )
      formData.value.localMarketSituationList = [...list]
      if (formData.value.localMarketSituationList.length === 0) {
        formData.value.localMarketSituationList = [
          {
            sort: formData.value.localMarketSituationList.length,
            id: '',
            brandName: '',
            businessSummary: ''
          }
        ]
      }
    }
    /** 选择当地市场列
     * @description:
     * @param {Array<{id:string}>} r
     */
    const handleSelectionMarketChange = r => {
      marketDataListRowId.value = r.map(item => item.sort)
    }
    /**
     * @description: 获取授权城市
     * @param {*} getDataListParams
     * @return {Array<{province:string,city:string,area:string,town:string,id:string}>}
     */
    const getProvince = async () => {
      return await getProvinceOrCityOrAreaList({
        type: 'province'
      }).then(res => res.data)
    }
    const getAuthCityList = ({ getDataListParams: province }) => {
      if (!province) return []
      return getProvinceOrCityOrAreaList({
        type: 'city',
        province
      }).then(({ data }) => data)
    }
    const getAuthAreaList = ({ getDataListParams: dataString }) => {
      const [province, city] = dataString.split(',')
      if (!province || !city) return []
      return getProvinceOrCityOrAreaList({
        type: 'area',
        province,
        city
      }).then(({ data }) => data)
    }

    /**
     * @description: 文件预览
     * @param {*} url
     * @return {*}
     */
    const handlePreview = ({ url }) => {
      window.open(url)
    }
    const handleUpload = (fileList, fileName) => {
      formData.value[fileName] = fileList.map(item => {
        return {
          ...item.response,
          functionAttribution: `${fileName}`
        }
      })
    }
    const getFormFlowTitle = () => {
      const val = formData.value
      const applyBrandSeries = val.contractorShopSeriesName
        ? '门店品牌：' + val.contractorShopSeriesName
        : ''
      let data = [val.authorizedCity, val.realName, applyBrandSeries].filter(
        item => item !== ''
      )
      return data.length > 0
        ? `经销商意向加盟申请表（${data.join('，')}）`
        : `经销商意向加盟申请表`
    }
    const flowTitle = computed(() => {
      const title = getFormFlowTitle()
      return title
    })
    /**
     * @description:保存0/提交1
     * @param {0|1} code
     * @return {*}
     */
    const flagVisable = ref(true)
    const handleValidate = (_, flag, message) => {
      if (!flag && flagVisable.value) {
        Message.warning(message)
        flagVisable.value = false
      }
    }

    const ruleForm = ref()
    const handleSave = async code => {
      isCache.value = false
      formData.value.dealerDeclarationForm.optTime = moment().format(
        'yyyy-MM-DD HH:mm:ss'
      )
      formData.value.dealerDeclarationForm.title = getFormFlowTitle()
      if (code) {
        flagVisable.value = true
        ruleForm.value.validate(async valid => {
          if (valid) {
            try {
              const res = await requestDraftFlow(formData.value)
              await requestSubmitFlow({
                formId: res.id
              })
              MessageBox.alert('提交成功！', '提示', {
                type: 'success'
              }).then(() => {
                removeSession('dealerInterview')
                $router.push({
                  name: 'myApply'
                })
              })
            } catch (error) {
              console.log(error.message)
            }
          }
        })
      } else {
        await requestDraftFlow(formData.value)
        MessageBox.alert('保存成功！', '提示', {
          type: 'success'
        }).then(() => {
          removeSession('dealerInterview')
          $router.push({
            name: 'draft'
          })
        })
      }
    }
    /**
     * @description: 是否启动缓存机制
     */
    const establishCache = () => {
      console.log('establishCache')
      const formOptions = formData.value
      if (isCache.value) {
        setSession('dealerInterview', formOptions)
      } else {
        removeSession('dealerInterview')
      }
    }
    window.addEventListener('beforeunload', establishCache)
    onMounted(async () => {
      formId.value = $route.query.id || null
      const cacheData = getSession('dealerInterview')
      if (cacheData && !formId.value) {
        formData.value = cacheData
        return
      }
      // if (formId.value) {
      //   await getFlowDetail()
      // }
    })
    onUnmounted(() => {
      window.removeEventListener('beforeunload', establishCache)
    })
    onBeforeRouteLeave((to, from, next) => {
      establishCache()
      next()
    })
    return {
      formData,
      marketDataListRowId,
      flowTitle,
      ruleForm,
      handleValidate,
      getProvince,
      getAuthCityList,
      getAuthAreaList,
      handleSelectionMarketChange,
      delMarketData,
      addMarketData,
      handlePreview,
      handleUpload,
      handleSave
    }
  }
})
</script>

<style scoped lang="scss">
.selectItem {
  margin: 0;
  width: 100%;
  &.is-error {
    margin-bottom: 22px;
  }
}
</style>
