<template>
  <div>
    <flow-form
      ref="flowFormRef"
      :model="form"
      :on-submit="onSubmit"
      :get-detail="getDetail"
      title="经销商考核补贴流程"
    >
      <required-form-item-input
        v-for="(value, key) in {
          title: '标题',
          orderNumber: '单号',
          optTime: '提单日期',
          endDate: '归档日期'
        }"
        :key="key"
        delay-update
        class="formItem"
        :prop="key"
        :value="form[key]"
        :label="value"
        placeholder="/"
        disabled
        :required="false"
      />

      <HeaderTitle
        id="base-info"
        style="margin-top: 20px"
        title="流程基础信息"
      />

      <required-form-item-input
        v-for="(value, key) in {
          optJobNumber: '提单人工号',
          optUserName: '提单人',
          optDeptName: '提单部门'
        }"
        :key="key"
        v-model.trim="form[key]"
        delay-update
        class="formItem"
        :prop="key"
        :label="value"
        disabled
        placeholder="/"
      />
      <required-form-item-select
        ref="costBelongCompanyRef"
        v-model="form.costBelongCompany"
        label="费用所属公司"
        class="formItem"
        prop="costBelongCompany"
        label-key="name"
        value-key="id"
        :select-props="{ remote: true, filterable: true }"
        :total-count="companyTotal"
        :get-data-list="getCompanyList"
        :get-next-list="getCompanyList"
        @onOptionsListNotFound="onNotFoundBusinessLicense"
      />
      <required-form-item-date-picker
        class="formItem"
        v-model="form.rebateExpirationDate"
        label="返利截止日期"
      />
      <required-form-item-date-picker
        class="formItem"
        v-model="form.schemeStartDate"
        label="方案年起"
      />
      <required-form-item-date-picker
        class="formItem"
        v-model="form.schemeEndDate"
        label="方案年止"
      />
      <required-form-item-select
        ref="settleRef"
        v-model="form.settle"
        prop="settle"
        class="formItem"
        label="冲账方式"
        :data-list="[
          { label: '费用池', value: 0 },
          { label: '借贷项', value: 1 }
        ]"
        label-key="label"
        value-key="value"
      />
      <required-form-item-input
        v-model.trim="form.theme"
        delay-update
        class="formItem large"
        prop="theme"
        label="活动主题"
        placeholder="请填写活动主题"
        maxlength="200"
      />
      <required-form-item-date-picker
        class="formItem"
        v-model="form.activityStartTime"
        label="活动开始时间"
      />
      <required-form-item-date-picker
        class="formItem"
        v-model="form.activityEndTime"
        label="活动结束时间"
      />
      <el-form-item
        class="formItem large"
        prop="subsidyTotal"
        label="方案总额（汇总补贴明细内的补贴金额）"
      >
        <div class="subsidy-money">
          <el-input
            v-model="form.subsidyTotal"
            class="money-num"
            readonly
            disabled
            placeholder="/"
          ></el-input>
          <div class="money-zh">
            {{ subsidyTotalText }}
          </div>
        </div>
      </el-form-item>
      <required-form-item-select
        v-model="form.receiptPaymentType"
        prop="receiptPaymentType"
        class="formItem"
        label="收付类型"
        :data-list="['收', '付']"
      />

      <HeaderTitle
        id="subsidy-detail"
        style="margin-top: 20px"
        title="补贴明细"
      >
        <div class="btn-list" v-if="!isOA">
          <el-button type="primary" :loading="loading" @click="onDownloadExcel">
            下载模板
          </el-button>
          <el-upload
            action="/"
            :show-file-list="false"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            :before-upload="onImportExcel"
          >
            <el-button type="primary" :loading="loading">导入明细</el-button>
          </el-upload>
          <el-button type="primary" :loading="loading" @click="onExportExcel">
            导出明细
          </el-button>
          <el-button
            type="primary"
            :loading="loading"
            icon="el-icon-plus"
            circle
            @click="onPlus"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-minus"
            circle
            :loading="loading"
            :disabled="multipleSelection.length == 0"
            @click="onMinus"
          ></el-button>
        </div>
      </HeaderTitle>

      <el-table
        ref="tableRef"
        style="width: 100%"
        fit
        border
        :data="form.detailList"
        @selection-change="values => (multipleSelection = values)"
      >
        <el-table-column
          v-if="!isOA"
          width="40"
          type="selection"
          fixed="left"
        />
        <el-table-column width="60" type="index" label="序号" align="center"/>
        <el-table-column prop="licenseNumber" label="售达方编号" width="100" />
        <el-table-column
          prop="businessLicenseName"
          label="售达方名称"
          width="150"
        />
        <el-table-column prop="realName" label="售达方实控人" width="150" />
        <el-table-column prop="province" label="省份" width="150" />
        <el-table-column prop="city" label="城市" width="100" />
        <el-table-column
          prop="costCenterNumber"
          label="成本中心编号"
          width="100"
        />
        <el-table-column prop="costCenter" label="成本中心" />
        <el-table-column prop="brandType" label="品牌类型" width="150" />
        <el-table-column prop="brand" label="品牌" width="150" />
        <el-table-column prop="subsidy" label="补贴金额" width="150" />
        <el-table-column v-if="!isOA" label="操作" width="60" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="delExcelItem(scope.row)"
            >
              删除
            </el-button>
            <!-- <el-button
              type="text"
              size="small"
              @click="editExcelItem(scope.row)"
            >
              编辑
            </el-button> -->
          </template>
        </el-table-column>

        <template v-if="form.detailList.length > 0" #append>
          <div class="table-page">
            <el-pagination
              background
              :current-page="pageIndex"
              :page-sizes="[10, 30, 50]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="excelTotal"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </template>
      </el-table>

      <HeaderTitle
        id="link-files"
        style="margin-top: 20px"
        title="相关流程/附件"
      />
      <required-form-item-upload-file
        v-model="form.policyFileList"
        class="formItem large"
        prop="policyFileList"
        label="方案策略"
        limit-size="400"
        :on-preview="onPreview"
      >
        <div slot="tip">单个文件不能超过400M</div>
      </required-form-item-upload-file>
      <required-form-item-upload-file
        v-model="form.otherFileList"
        class="formItem large"
        prop="otherFileList"
        label="其他附件（核酸明细）"
        limit-size="400"
        :on-preview="onPreview"
      >
        <div slot="tip">单个文件不能超过400M</div>
      </required-form-item-upload-file>

      <AnchorNavigate
        :options="[
          { id: 'base-info', title: '流程基础信息' },
          { id: 'subsidy-detail', title: '补贴明细' },
          { id: 'link-files', title: '相关流程/附件' },
          { id: 'anchor-bz', title: '备注' }
        ]"
      />
    </flow-form>

    <el-drawer
      class="form-drawer"
      title="添加补贴明细"
      :visible.sync="showDrawer"
      size="50%"
      append-to-body
    >
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        class="rule-form"
        label-position="top"
      >
        <required-form-item-select
          ref="dealerBusinessLicenseRef"
          v-model="ruleForm.businessLicenseId"
          label="售达方编号"
          class="formItem"
          prop="businessLicenseId"
          label-key="licenseNumber"
          value-key="id"
          :select-props="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :total-count="businessCountTotal"
          :get-data-list="getBusinessLicense"
          :get-next-list="getBusinessLicense"
          @onOptionsListNotFound="onNotFoundBusinessLicense"
          @changeSelectedItem="
            ({ businessLicenseName, realName, licenseNumber }) => {
              ruleForm = {
                ...ruleForm,
                businessLicenseName,
                realName,
                licenseNumber
              }
            }
          "
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.licenseNumber }}</span>
            <small>{{ scope.row.businessLicenseName }}</small>
          </template>
        </required-form-item-select>
        <required-form-item-input
          v-for="(value, key) in {
            businessLicenseName: '售达方名称',
            realName: '售达方实控人'
          }"
          :key="key"
          delay-update
          class="formItem"
          :prop="key"
          :value="ruleForm[key]"
          :label="value"
          placeholder="/"
          readonly
          required
        />
        <required-form-item-input
          v-for="(value, key) in {
            province: '省份',
            city: '城市'
          }"
          :key="key"
          :label="value"
          class="formItem"
          :prop="key"
          v-model="ruleForm[key]"
          :placeholder="`请输入${value}`"
          delay-update
        />
        <required-form-item-select
          v-model="ruleForm.costCenterNumber"
          class="formItem"
          label="成本中心编码"
          prop="costCenterNumber"
          label-key="id"
          value-key="id"
          :select-props="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :total-count="costCenterTotal"
          :get-data-list="getCostCenterList"
          :get-next-list="getCostCenterList"
          @onOptionsListNotFound="onNotFoundCostCenterList"
          @changeSelectedItem="
            ({ content }) => (ruleForm = { ...ruleForm, costCenter: content })
          "
        >
          <template v-slot="{ scope }">
            <span>{{ scope.row.id }}</span>
            <small>{{ scope.row.content }}</small>
          </template>
        </required-form-item-select>
        <required-form-item-input
          v-model="ruleForm.costCenter"
          class="formItem"
          prop="costCenter"
          label="成本中心"
          placeholder="请输入成本中心"
          delay-update
        />
        <required-form-item-select
          v-model="ruleForm.brandType"
          label="品牌类型"
          class="formItem"
          prop="brandType"
          :data-list="[
            { label: '通用品牌', value: '通用品牌' },
            { label: '招商品牌', value: '招商品牌' }
          ]"
          label-key="label"
          value-key="value"
        />
        <required-brand-form-item-select
          v-model="ruleForm.brand"
          label="品牌"
          class="formItem"
          prop="brand"
          :select-props="{
            valueKey: 'name',
            filterable: true,
            multiple: false
          }"
          @changeSelectedItem="
            ({ id }) => (ruleForm = { ...ruleForm, brandId: id })
          "
        />
        <required-form-item-input
          v-model.number.trim="ruleForm.subsidy"
          type="number"
          label="补贴金额"
          delay-update
          class="formItem"
          prop="subsidy"
          placeholder="请输入补贴金额"
          maxlength="7"
        />
        <el-form-item>
          <el-button @click="showDrawer = false"> 取消 </el-button>
          <el-button type="primary" @click="submitExcelJson"> 提交 </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import 'element-ui/lib/theme-chalk/display.css'
import moment from 'moment'
import HeaderTitle from './components/headerTitle.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect.vue'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import AnchorNavigate from '@/components/anchorNavigate.vue'
import FlowForm from './components/flowForm'
import RemarkItem from './components/remarkItem'
import * as SubsidyApi from '@/api/flow/subsidyApi.js'
import * as BusinessApi from '@/api/attractPlatform/business'
import { setSession, removeSession, getSession } from '@/utils/storage.js'
import Nzh from 'nzh'

export default {
  name: 'CustomerMainChange',
  components: {
    HeaderTitle,
    RequiredFormItemInput,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequiredBankFormItemSelect,
    RequiredBrandFormItemSelect,
    RequiredFormItemSelect,
    AnchorNavigate,
    FlowForm,
    RemarkItem
  },
  props: {},
  data() {
    return {
      form: {
        title: '经销商考核补贴', // 标题
        orderNumber: '', // 单号
        optJobNumber: '', // 提单人工号
        optUserId: '', // 提单人id
        optUserName: '', // 提单人工号
        optDeptId: '', // 提单部门id
        optDeptName: '', // 提单部门
        optTime: moment().format('YYYY-MM-DD HH:mm:ss'), // 提单日期
        detailList: [],
        processCode: 'JXS014'
      },
      businessCountTotal: null, // 售达方总条数
      companyTotal: null, //费用所属公司总条数
      costCenterTotal: null, //成本中心总条数
      subsidyTotalText: '',
      excelData: [], // 记录导入表格数据
      excelTotal: 0, // 记录表格总页数
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      multipleSelection: [],
      search: '',
      showDrawer: false,
      ruleForm: {
        businessLicenseName: null,
        realName: null,
        costCenterNumber: null,
        costCenter: null
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('dealershipTransfer', ['isOA'])
  },
  watch: {
    'form.subsidyTotal': {
      handler(val) {
        this.subsidyTotalText = Nzh.hk.encodeB(val)
      },
      deep: true
    }
  },
  methods: {
    async getDetail(type, cachedFormData) {
      if (type === 'create') {
        const { code, realName, deptId, accountId, deptName } = this.userInfo
        this.form = {
          ...this.form,
          orderNumber: '',
          optUserId: accountId,
          optUserName: realName,
          optJobNumber: code,
          optDeptId: deptId || '',
          optDeptName: deptName || '',
          optTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          detailList: [],
          title: '经销商考核补贴',
          ...cachedFormData
        }
      } else {
        const { id } = this.$route.query
        const { code, data } = await SubsidyApi.getDetail({ id })
        if (code != 0) return
        this.form = { ...this.form, ...data, id }
        this.setCurrentPageData()
      }
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    async onNotFoundCostCenterList(values, callback) {
      console.log('onNotFoundCostCenterList', values)
      callback(
        await BusinessApi.getCostCenterList({
          module: 'distributor',
          type: 'cost_center',
          currPage: 1,
          pageSize: 6
        }).then(res => res.data)
      )
    },
    // 售达方下拉加载使用
    getCompanyList({ page, searchText }) {
      return SubsidyApi.getCompanyList({
        currPage: page,
        pageSize: 6,
        name: searchText
      }).then(res => {
        this.companyTotal = res.totalPage
        return res.data
      })
    },
    // 售达方下拉加载使用
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    // 获取成本中心列表
    async getCostCenterList({ page, searchText }) {
      return BusinessApi.getCostCenterList({
        module: 'distributor',
        type: 'cost_center',
        currPage: page,
        pageSize: 6,
        contenr: searchText,
        status: 0
      }).then(res => {
        this.costCenterTotal = res.totalPage
        return res.data
      })
    },
    onBrandChange(values, row) {
      row.brandId = values.id
    },
    // 成本中心变化
    onCostCenterChange(values, row) {
      this.form = {
        ...this.form,
        detailList: this.form.detailList.map(item => {
          if (item.index == row.index)
            return { ...item, costCenter: values.content }
          return item
        })
      }
    },
    onPreview(file) {
      window.open(file.url)
    },
    // 手动减少表格
    onMinus() {
      this.multipleSelection.forEach(row => {
        this.delExcelItem(row)
      })
      this.setCurrentPageData()
    },
    // 手动添加表格
    onPlus() {
      this.ruleForm = {
        businessLicenseName: null,
        realName: null,
        costCenterNumber: null,
        costCenter: null
      }
      this.showDrawer = true
      this.$nextTick(() => {
        this.$refs.ruleFormRef.resetFields()
      })
    },
    delExcelItem(row) {
      this.form = {
        ...this.form,
        detailList: this.form.detailList.filter(item => item.id != row.id)
      }
    },
    editExcelItem(row) {
      console.log('editExcelItem', row)
      this.ruleForm = row
      this.showDrawer = true
    },
    submitExcelJson() {
      this.$refs.ruleFormRef.validate(async valid => {
        if (valid) {
          console.log(this.ruleForm)
          await this.saveDraft()
          if (!this.form.id) return
          const { code } = await SubsidyApi.submitExeclJson([
            { ...this.ruleForm, declarationFormId: this.form.id }
          ])
          if (code != 0) return
          this.$message.success('提交成功')
          this.showDrawer = false
          if (this.form.id) {
            this.setCurrentPageData()
          } else {
            this.$router.replace({
              path: '/flow/subsidyVerification',
              query: { id: this.form.id }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 设置表格当前数据
    async setCurrentPageData() {
      const { code, data } = await SubsidyApi.getExeclList({
        id: this.form.id,
        pageSize: this.pageSize,
        currPage: this.pageIndex
      })
      if (code != 0 || data.length == 0) return
      this.excelTotal = data.totalCount
      this.form = { ...this.form, subsidyTotal: data[0].subsidyTotal }
      this.form = {
        ...this.form,
        detailList: data.map(item => ({ ...item, realName: item.bossName }))
      }
      console.log('setCurrentPageData', this.form)
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.setCurrentPageData()
    },
    handleCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.setCurrentPageData()
    },
    // 导出表格
    onExportExcel() {
      if (!this.form.id) return
      SubsidyApi.exportExecl({ declarationFormId: this.form.id })
    },
    // 下载模板
    onDownloadExcel() {
      SubsidyApi.downloadExecl()
    },
    //导入表格
    async onImportExcel(file) {
      console.log('file', file)
      await this.saveDraft()
      if (!this.form.id) return false
      const formData = new FormData()
      formData.append('declarationFormId', this.form.id)
      formData.append('file', file)
      await SubsidyApi.updateExecl(formData)
      this.setCurrentPageData()
      return false
    },
    async saveDraft() {
      console.log(this.form)
      const { detailList, ...params } = this.form
      const { data } = await SubsidyApi.save(params)
      this.form = {
        ...this.form,
        id: data
      }
    },
    async onSubmit(type) {
      await this.saveDraft()
      const { detailList, id, ...params } = this.form
      if (type !== 'submit') return
      return SubsidyApi.submit(params)
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
  font-size: 12px;
  color: #999;
}

.oa-select::v-deep .el-input.is-disabled .el-input__suffix {
  display: none;
}
.oa-select::v-deep .el-input.is-disabled .el-input__inner {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  color: #333;
  cursor: default;
  padding-left: 0;
}
.large.formItem {
  min-width: 40%;
}

.divider-title .el-divider__text {
  font-size: 18px;
  font-weight: 600;
}
.subsidy-money {
  display: flex;
  align-items: center;
  .money-num {
    flex: 1;
    margin-right: 10px;
  }
  .money-zh {
    flex: 2;
  }
}
.btn-list {
  display: flex;
  align-items: center;
  &::v-deep .el-button {
    margin-left: 10px;
  }
}
.infinite-list {
  max-height: 300px;
}
.table-page {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.form-drawer::v-deep {
  /*1.显示滚动条：当内容超出容器的时候，可以拖动：*/
  .el-drawer__body {
    overflow: auto;
  }
  /*2.隐藏滚动条，太丑了*/
  .el-drawer__container ::-webkit-scrollbar {
    display: none;
  }
}

.rule-form {
  margin: 0 20px;
  &::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 20px;
  }
  &::v-deep .el-form-item--small .el-form-item__label {
    line-height: 1;
    padding-bottom: 4px;
  }
}
</style>
