<template>
  <div>
    <HeaderTitle style="margin-top:10px" id="anchor-bz" title="备注" />
    <RequiredFormItemInput
      delay-update
      v-model="inputValue"
      style="width:100%"
      class="formItem"
      :inputProps="{
        type: 'textarea',
        resize: 'none',
        rows: 6,
        maxlength: '500',
        showWordLimit: true
      }"
      :required="false"
      label="备注"
      :disabled="disabled"
    />
    <div>
      <UploadFile
        class="formItem"
        label="上传附件"
        v-model="imageList"
        :required="false"
        @onUploaded="file => handleUpload(file)"
        :disabled="disabled"
      >
        <div slot="tip">单文件不能超过400M</div>
      </UploadFile>
    </div>
  </div>
</template>

<script>
import HeaderTitle from './headerTitle'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
export default {
  components: {
    HeaderTitle,
    UploadFile,
    RequiredFormItemInput
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    fileList: {
      type: Array,
      default: () => []
    },
    disabled: {
      required: false,
      type: Boolean
    }
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.inputValue = value
        }
      }
    },
    inputValue: {
      handler(value) {
        if (value) {
          this.$emit('changeValue', value)
        }
      }
    },
    fileList: {
      immediate: true,
      handler(data) {
        this.imageList = data
      }
    }
  },
  data() {
    return {
      inputValue: '',
      imageList: []
    }
  },
  methods: {
    handleUpload(file) {
      this.imageList = file
      this.$emit('onUpload', file)
    }
  }
}
</script>

<style lang="scss" scoped></style>
