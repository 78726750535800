<template>
  <div class="box">
    <el-form :model="titleSearch" :inline="true">
      <el-form-item label="流程类型">
        <el-select
          ref="typeSelectDraft"
          v-model="titleSearch.processCode"
          placeholder="请选择流程类型"
          @visible-change="visibleTypeChange">
          <el-option label="全部" value=" " />
          <el-option
            v-for="(item, index) in flowList"
            :key="index"
            :label="item.content"
            :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="requestList"
          >查询</el-button
        >
        <el-button type="danger" icon="el-icon-delete" @click="delList"
          >删除</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="tableList"
      style="width: 100%"
      height="680"
      border
      :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" />
      <el-table-column type="index" width="100" label="序号" />
      <el-table-column prop="zsOrderNumber" width="100" label="招商单号" />
      <el-table-column prop="title" label="流程名称" />
      <el-table-column prop="status" label="审批状态">
        <template slot-scope="scope">
          {{ scope.row.status | setStatus }}
        </template>
      </el-table-column>
      <el-table-column prop="optTime" label="保存时间" />
      <el-table-column prop="id" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click.stop="detail(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div class="paginationBar">
        <el-pagination
          background
          layout="total,prev,pager, next,sizes,jumper"
          :total="totalCount"
          :page-sizes="[10, 30, 50]"
          :page-size="pageSize"
          :current-page="currPage"
          @size-change="handleSizeChange"
          @current-change="currentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { getList, getFlowList, deleteList } from '@/api/business/draft'
import { getStorage } from '@/utils/storage'
import { selectMinxi } from '@/mixin/select'
import flow from '@/router/modules/flow'
export default {
  filters: {
    setStatus(val) {
      const arr = {
        0: '草稿',
        1: '已发布',
        2: '已失效',
        3: '已归档'
      }
      return arr[val]
    }
  },
  mixins: [selectMinxi],
  data() {
    return {
      titleSearch: {
        processCode: ''
      },
      tableList: [],
      flowList: [], // 流程信息列表,
      totalCount: 50, // 总条数
      currPage: 1, // 当前页
      pageSize: 10, // 每页条数,
      multipleSelection: [],
      loading: false
    }
  },
  computed: {
    userInfo() {
      return getStorage('userInfo')
    },
    // flowList(){
    //   const arr = []
    //   flow().forEach(item => {
    //     const ruler = /表$/i
    //     if(ruler.test(item.meta.title)){
    //       const str = item.meta.title.replace(/表$/i,'流程')
    //       arr.push(str)
    //     } else {
    //       arr.push(item.meta.title)
    //     }
    //   })
    //   return arr
    // },
    routerList() {
      return flow()
    }
  },
  mounted() {
    this.requestList() // 请求数据
    this.requestFlowList() // 请求流程类型
    console.log('flowList', flow())
  },
  methods: {
    async requestFlowList() {
      const res = await getFlowList()
      this.flowList = res.data
      console.log('this.flowList', this.flowList)
    },
    async requestList() {
      this.loading = true
      const data = {
        ...this.titleSearch,
        currPage: this.currPage,
        limit: this.pageSize,
        userId: this.userInfo.accountId
      }
      console.log('data', data)
      const res = await getList(data)
      this.loading = false
      // console.log('res', res)
      this.tableList = res.data

      console.log(' res.data', res.data)
      this.totalCount = res.totalCount
      this.currPage = res.currPage
    },
    async delList() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error('请选择数据')
      }
      const data = this.multipleSelection.map(item => item.id)
      await deleteList(data)
      this.$message({
        type: 'success',
        message: '删除成功'
      })
      this.requestList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log(' this.multipleSelection', this.multipleSelection)
    },
    // 编辑
    detail(val) {
      this.routerList.forEach(item => {
        if (item.meta.code === val.processCode) {
          if (
            item.meta.declarationType &&
            item.meta.declarationType !== val.declarationType
          ) {
            return
          }
          this.$router.push({
            path: `/flow/${item.path}`,
            query: {
              id: val.id,
              type: val.title.includes('直营') ? 'direct' : ''
            }
          })
        }
      })
    },
    // 页面切换
    currentChange(page) {
      console.log('paga当前页', page)
      this.currPage = page
      // const data = {}
      // data.page = this.currPage
      // data.limit = this.pageSize

      this.requestList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.pageSize = page
      this.currPage = 1
      // const data = {}
      // data.page = this.currPage
      // data.limit = this.pageSize
      this.requestList()
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  &::v-deep .el-button--text {
    color: #409eff;
  }
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
