<!--
 * @Author: wangmq
 * @Date: 2024-01-09 10:43:37
 * @LastEditors: Michaels-MacBook.local
 * @LastEditTime: 2024-10-21 10:31:01
-->
<template>
  <div style="padding-bottom: 80px">
    <div class="tip">
      <p>1.流程适用范围：慕思经典经销商；</p>
      <br />
      <p>
        2.流程适用场景：经销商全新开店（包括空白加盟、换址新开、新开店面），整店重装和扩店装修。
      </p>
      <br />
      <p>3.一个流程仅允许提交一个门店信息，多个门店需要提交多个流程。</p>
      <br />
    </div>
    <BaseMessage
      :title="flowTitle"
      :number="formData.orderNumber"
      :zsOrderNumber="formData.zsOrderNumber" />
    <el-form
      class="flow-form"
      :model="formData"
      :inline="true"
      ref="ruleForm"
      label-position="top">
      <br />
      <required-form-item-select
        prop="storeOpeningType"
        class="formItem requiredFormItem"
        label="开店类型"
        v-model="formData.storeOpeningType"
        labelKey="label"
        valueKey="value"
        :dataList="
          Object.keys(shopModelMap).map(item => ({
            value: item,
            label: item
          }))
        "
        :selectListeners="{
          change: data => {
            formData.storeOpeningMode = ''
            formData.marketShopCount = ''
            formData.shop = {
              decorationArea: '', // 门店装修面积
              ifParentShopNumber: '',
              parentShopNumber: '',
              // oldShopNumber: '',
              marketShopCount: '',
              id: '',
              storeNature: '',
              shopNumber: '',
              independentDoorHeadFlag: '',
              province: authorizedCityMsg?.province || '',
              city: authorizedCityMsg?.city || '',
              area: '',
              street: '',
              shopType: '',
              shopTypeValue: '',
              shopFloor: '',
              shopFloorNumber: '',
              shopPositionLevel: '',
              marketName: '',
              marketType: '',
              regionAreaCode: '',
              longitude: '',
              latitude: '',
              managementModel: '',
              contactPerson: '',
              contactPersonIdcard: '',
              contactPersonPhone: '',
              decorationTime: '',
              renovationLiablePerson: '',
              renovationPersonPhone: '',
              shopAlias: '',
              shopRent: '',
              shopArea: '',
              dianjiangDesigner: '',
              dianjiangDesignerName: '',
              ifDianjiangDesigner: '',
              shopBrandContentList: [],
              shopAddArea: '',
              oldShopArea: '',
              ifRenovation: 1,
              dianjiangDesignerPrice: '',
              dianjiangDesignerFactor: '',
              marketLevelName: authorizedCityMsg.marketLevelName,
              marketLevelCode: authorizedCityMsg.marketLevelCode,
              doorHeaderBeforeArea: '', //调整前门头面积
              doorHeaderRenovationArea: '', //门头装修面积
              doorHeaderArea: '' //门头实用面积(调整后门头面积)
            }
          }
        }">
      </required-form-item-select>
      <required-form-item-select
        prop="storeOpeningMode"
        class="formItem requiredFormItem"
        label="开店模式"
        v-model="formData.storeOpeningMode"
        labelKey="label"
        valueKey="value"
        :dataList="shopModelMap[formData.storeOpeningType] || []"
        @onChangeItem="
          e => {
            formData.marketShopCount = ''
            formData.shop = {
              decorationArea: '', // 门店装修面积
              ifParentShopNumber: '',
              parentShopNumber: '',
              // oldShopNumber: '',
              marketShopCount: '',
              id: '',
              storeNature: '',
              shopNumber: '',
              independentDoorHeadFlag: '',
              province: authorizedCityMsg?.province || '',
              city: authorizedCityMsg?.city || '',
              area: '',
              street: '',
              shopType: '',
              shopTypeValue: '',
              shopFloor: '',
              shopFloorNumber: '',
              shopPositionLevel: '',
              marketName: '',
              marketType: '',
              regionAreaCode: '',
              longitude: '',
              latitude: '',
              managementModel: '',
              contactPerson: '',
              contactPersonIdcard: '',
              contactPersonPhone: '',
              decorationTime: '',
              renovationLiablePerson: '',
              renovationPersonPhone: '',
              shopAlias: '',
              shopRent: '',
              shopArea: '',
              dianjiangDesigner: '',
              dianjiangDesignerName: '',
              ifDianjiangDesigner: '',
              shopBrandContentList: [],
              shopAddArea: '',
              oldShopArea: '',
              ifRenovation: 1,
              dianjiangDesignerPrice: '',
              dianjiangDesignerFactor: '',
              marketLevelName: authorizedCityMsg.marketLevelName,
              marketLevelCode: authorizedCityMsg.marketLevelCode,
              doorHeaderBeforeArea: '', //调整前门头面积
              doorHeaderRenovationArea: '', //门头装修面积
              doorHeaderArea: '' //门头实用面积(调整后门头面积)
            }
          }
        " />
      <br />
      <RequiredFormItemInput
        v-for="item in formColumns.flowBasicColumns"
        :key="item.name"
        :required="item.required"
        class="formItem"
        :value="formData[item.name] ?? ''"
        :label="item.label"
        :readonly="item.readonly"
        :placeholder="item.placeholder" />
      <BusinessItemSelect
        v-model="formData.businessLicenseId"
        prop="businessLicenseId"
        @onChange="handleChangeBusiness"
        :selectListeners="{
          change: data => {
            formData.authorizedCityCode = ''
            formData.brands = []
            authorizedCityMsg = {}
          }
        }" />
      <RequiredFormItemInput
        v-for="item in formColumns.businessLicenseColumns"
        :key="item.name"
        :required="item.required"
        class="formItem"
        :value="businessMsg[item.name] ?? ''"
        :label="item.label"
        :readonly="item.readonly"
        :placeholder="item.placeholder" />
      <RequiredFormItemSelect
        :disabled="!formData.businessLicenseId"
        v-model="formData.authorizedCityCode"
        prop="authorizedCityCode"
        class="formItem"
        label="授权城市确认"
        :labelKey="item => `${item.city}${item.area || ''}${item.town || ''}`"
        value-key="authorizedCityCode"
        :get-data-list="getAuthorizedCityByBusinessLicense"
        :get-data-list-params="formData.businessLicenseId"
        @changeSelectedItem="
          value => {
            authorizedCityMsg = value ? value : {}
            formData.shop.city = value ? value.city : ''
            formData.shop.province = value ? value.province : ''
          }
        "
        :selectListeners="{
          change: () => {
            formData.brands = []
          }
        }" />
      <RequiredFormItemInput
        v-for="item in formColumns.authorizedCityColumns"
        :key="item.name"
        :required="item.required"
        class="formItem"
        :value="authorizedCityMsg[item.name] ?? ''"
        :label="item.label"
        :readonly="item.readonly"
        :placeholder="item.placeholder" />
      <!-- <RequiredFormItemInput
        :required="false"
        class="formItem"
        :value="saveBrandList"
        label="已代理品牌"
        :readonly="true"
        placeholder="选择授权城市" /> -->
      <el-form-item :title="saveBrandList" label="已代理品牌" class="formItem">
        <div style="min-height: 33px">{{ saveBrandList }}</div>
      </el-form-item>
      <BrandSelect
        ref="brandSelect"
        class="requiredFormItem"
        label="新店品牌确认"
        showAll
        v-model="formData.brands"
        :authorizedCityCode="formData.authorizedCityCode"
        :businessLicenseId="formData.businessLicenseId"
        :status="flowStatus"
        prop="brands"
        :onlyClassic="true"
        @onChangeSelectedItem="
          data => {
            newShopBrandGet(data)
          }
        "
        @onChangeItem="handleChangeShopBrand" />
      <RequiredFormItemInput
        :required="false"
        class="formItem"
        :value="formData.bigRegionName"
        label="营销部"
        :readonly="true"
        placeholder="根据新店品牌自动带出" />
      <RequiredFormItemInput
        :required="false"
        class="formItem"
        :value="formData.marketCenterName"
        label="营销中心"
        :readonly="true"
        placeholder="根据新店品牌自动带出" />
      <RequiredFormItemInput
        :required="false"
        class="formItem"
        :value="formData.shopBrandNumber"
        label="送达方编号"
        :readonly="true" />
      <required-form-item-select
        v-if="!isOA && formData.storeOpeningMode === '换址新开'"
        prop="linkOrderNumber"
        class="formItem"
        label="关联撤店单号（若换址新开请填写关联撤店单号）"
        :getDataList="getFlowList"
        :getNextList="getFlowList"
        :totalCount="flowTotalCount"
        v-model="formData.linkOrderNumber"
        :selectProps="{
          filterable: true,
          remote: true,
          clearable: true,
          'popper-class': 'custom-business-select'
        }"
        labelKey="orderNumber"
        valueKey="orderNumber"
        @onOptionsListNotFound="onOptionsListNotFound"
        :required="formData.storeOpeningMode === '换址新开'"
        @changeSelectedItem="
          e => {
            if (!e) return
            formData.linkOrderFormId = e.id
            formData.linkOrderOaRequestId = e.oaRequestId
          }
        " />
      <el-form-item
        v-else-if="isOA && formData.storeOpeningMode === '换址新开'"
        class="formItem"
        label="关联撤店单号">
        <a @click="oaDetail(formData.linkOrderNumber)" href="#">{{
          formData.linkOrderNumber
        }}</a>
      </el-form-item>
      <br />
      <HeaderTitle title="申请开店信息" />
      <StoreFlowBaseTable
        :defaultData="formData.shop"
        :authorizedSelectedItem="authorizedCityMsg"
        :defaultDataBrandList="brandData"
        :businessLicense="businessMsg"
        :storeOpeningType="formData.storeOpeningType"
        :storeOpeningMode="formData.storeOpeningMode"
        :bigRegionName="formData.bigRegionName"
        @getMarketShopCount="
          e => {
            formData.shop.marketShopCount = formData.marketShopCount = e
          }
        " />
      <br />
      <RequiredFormItemUploadFile
        v-for="(item, idx) in uploadAttachmentColumns"
        v-if="!(item?.hidden ?? false)"
        :key="item.name"
        class="formItem"
        label="上传附件"
        v-model="formData[item.name]"
        :prop="`${item.name}`"
        :rules="item.rules || []"
        :required="item?.required ?? true"
        :onPreview="handlePreview"
        @onUploaded="file => handleUpload(file, item.name)">
        <div slot="describe">
          <div>附件：</div>
          <div>{{ item.label }}</div>
          <div v-if="item.fileUrl">
            <el-button @click="downloadFile(item.fileUrl)">模板下载</el-button>
          </div>
          <div v-if="item?.exampleUrlList?.length > 0">
            <div
              v-for="(url, i) in item?.exampleUrlList"
              :key="url"
              class="example">
              <el-image
                :src="url"
                style="width: 30px; height: 30px"
                fit="contain"></el-image>
              <div :title="url" class="link">示例{{ idx + 1 }}-{{ i + 1 }}</div>
              <el-button type="text" @click="handlePreview({ url })">
                示例预览</el-button
              >
            </div>
          </div>
        </div>
        <div slot="tip">单文件不能超过400M</div>
      </RequiredFormItemUploadFile>
      <RequiredFormItemInput
        :required="false"
        class="formItem"
        v-model="formData.remark"
        label="备注"
        type="textarea"
        style="width: 100%"
        :rows="6" />
    </el-form>
    <div class="d-footer" v-if="!isOA">
      <el-button type="primary" :loading="isLoading" @click="handleSave(5)"
        >提交</el-button
      >
      <el-button type="info" :loading="isLoading" @click="handleSave(0)"
        >保存</el-button
      >
      <el-button style="margin-left: 20px" @click="goBack">取消</el-button>
    </div>
  </div>
</template>

<script setup>
import Vue, {
  ref,
  computed,
  watchEffect,
  onMounted,
  onUnmounted,
  watch
} from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router/composables'
import store from '@/store'
import { getStorage } from '@/utils/storage'
import moment from 'moment'
import BaseMessage from './components/baseMessage'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import BusinessItemSelect from '@/components/BaseSelect/businessItemSelect'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import BrandSelect from '@/components/BaseSelect/brandSelect'
import HeaderTitle from './components/headerTitle.vue'
import StoreFlowBaseTable from './components/StoreFlowBaseTable.vue'
import {
  getAuthorizedCityData,
  requestGetSizeBarListByDept,
  requestGetCityLevel,
  requestGetHistoryFlow
} from '@/api/common'
import { getShopListPage } from '@/api/business/draft'
import { getMasterDataList } from '@/api/businessOos'
import { requestSaveFlow } from '@/api/flow/storeManagement'
import { Message, MessageBox } from 'element-ui'
import { deepCopy } from 'ali-oss/lib/common/utils/deepCopy'
import { getSession, setSession, removeSession } from '@/utils/storage'
import { isArray } from 'ali-oss/lib/common/utils/isArray'
const VMDdemoUrl =
  'https://derucci-app-obs.iderucci.com/upload/20230425/45b3eac4a7031decec38f99fc076af1b.docx'
const bigShopAreaUrl =
  'https://derucci-app-obs.iderucci.com/upload/20230913/efa8a90e9fd3ffab5b6d85d828e15b4f.xlsx'
const shopModelMap = {
  全新开店: [
    {
      value: '空白加盟',
      label: '空白加盟'
    },
    {
      value: '换址新开',
      label: '换址新开'
    },
    {
      value: '新开店面',
      label: '新开店面'
    },
    {
      value: '转让加盟',
      label: '转让加盟'
    }
  ],
  扩店装修: [
    {
      value: '原店扩大',
      label: '原店扩大（原专卖店不装修，仅装修扩店部分）'
    },
    {
      value: '全新扩店',
      label: '全新扩店（原专卖店与扩店部分一起装修）'
    }
  ],
  整店重装: [
    {
      value: '整店重装',
      label: '整店重装'
    }
  ],
  部分装修: [
    {
      value: '部分装修',
      label: '部分装修'
    }
  ]
  // 重装: [
  //   {
  //     value: '整店重装',
  //     label: '整店重装'
  //   },
  //   {
  //     value: '换址新开',
  //     label: '换址新开'
  //   },
  //   {
  //     value: '部分装修',
  //     label: '部分装修'
  //   }
  // ]
}
const userInfo = getStorage('userInfo')
const route = useRoute()
const router = useRouter()
const isCache = ref(true)

const isOA = computed(() => {
  return store.state.dealershipTransfer.isOA
})
const bigBrandMap = computed(() => {
  return store.getters['commonConfig/bigBrandMap']
})
const isMobile = computed(() => {
  return store.state.app.device === 'mobile' || store.state.app.isWxworkMoblie
})
// 流程标题
const flowTitle = computed(() => {
  const authorizedCity = `${authorizedCityMsg.value.city || ''} ${
    authorizedCityMsg.value.area || ''
  }`
  const legalPersonName = businessMsg.value.legalPersonName || ''
  const title = `慕思经典经销商门店管理申请表（${authorizedCity}，${legalPersonName}，门店品牌：${brandData.value
    .map(item => item.name)
    .join(',')}，门店编码：***，招商单号：***）`
  return title
})
const isLoading = ref(false)
const ruleForm = ref()
const brandSelect = ref()
// 表单状态值
const flowStatus = ref(0)
// 门店品牌
const brandData = ref([])
// 表单配置表
const formColumns = ref({
  flowBasicColumns: [
    {
      label: '提单人工号',
      name: 'optJobNumber',
      readonly: true,
      required: false
    },
    {
      label: '提单人',
      name: 'optUserName',
      readonly: true,
      required: false
    },
    {
      label: '提单部门',
      name: 'optDeptName',
      readonly: true,
      required: false
    },
    {
      label: '提单日期',
      name: 'optTime',
      required: false,
      readonly: true,
      placeholder: '归档后自动生成'
    }
  ],
  businessLicenseColumns: [
    {
      label: '售达方名称',
      name: 'businessLicenseName',
      required: false,
      readonly: true,
      placeholder: '选择售达方'
    },
    {
      label: '经销商（实际控股人）',
      name: 'realName',
      required: false,
      readonly: true,
      placeholder: '选择售达方'
    },
    {
      label: '经销商（实际控股人）联系方式',
      name: 'contactInfo',
      required: false,
      readonly: true,
      placeholder: '选择售达方'
    },
    {
      label: '银企直连账户',
      name: 'bankAccount',
      required: false,
      readonly: true,
      placeholder: '选择售达方'
    },
    {
      label: 'OA账户',
      name: 'bossNumber',
      required: false,
      readonly: true,
      placeholder: '选择售达方'
    }
  ],
  authorizedCityColumns: [
    {
      label: '城市级别',
      name: 'marketLevelName',
      required: false,
      readonly: true,
      placeholder: '选择授权城市'
    },
    {
      label: '省份',
      name: 'province',
      required: false,
      readonly: true,
      placeholder: '选择授权城市'
    },
    {
      label: '地级市',
      name: 'city',
      required: false,
      readonly: true,
      placeholder: '选择授权城市'
    },
    {
      label: '区县',
      name: 'area',
      required: false,
      readonly: true,
      placeholder: '选择授权城市'
    }
  ]
})
const uploadAttachmentColumns = computed(() => {
  return [
    {
      label: '卖场外立面照片（必填）',
      name: 'shopOutsideFileList',
      exampleUrlList: [
        'https://derucci-app-obs.iderucci.com/upload/20230616/5287e66d68ea8632d737b48a84c333b8.png'
      ]
    },
    {
      label:
        '商场平面图标注该慕思门店位置、所在楼层、最近的电梯/手扶梯位置，如在一楼要标注主入口，是否有外开独立门头（必填)',
      name: 'shopMarketInsideLocationFileList',
      exampleUrlList: [
        'https://derucci-app-obs.iderucci.com/upload/20230616/082d0f5f8f9321cf8d363290c73f764b.png',
        'https://derucci-app-obs.iderucci.com/upload/20230616/d7f9da39f1ec8cefeaf595f45f6537a9.png'
      ]
    },
    {
      label:
        '店面周围左右对面品牌照片不少于三张（如有电梯/手扶梯，照片要体现该门店和电梯/手扶梯的位置关系）',
      name: 'storeAroundOppositeBrandFileList',
      exampleUrlList: [
        'https://derucci-app-obs.iderucci.com/upload/20230621/53b7eb9212ca137543434b543a16b5de.png',
        'https://derucci-app-obs.iderucci.com/upload/20230616/6e6eafaf1f253a4fd4360fce08ab85f3.png',
        'https://derucci-app-obs.iderucci.com/upload/20230616/e229d1e5a81cbbbf72214c1068c5f723.png'
      ],
      rules: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (value.length < 3) {
              callback(new Error('请上传不少于三张图片'))
            } else {
              callback()
            }
          },
          trigger: 'change'
        }
      ]
    },
    {
      label: '二级分销商身份证照片、分销协议',
      name: 'secondaryDistributorFileList',
      required: formData.value.shop.managementModel === '20',
      hidden: formData.value.shop.managementModel !== '20'
    },
    {
      label: '店铺CAD图纸',
      name: 'shopCADFileList'
    },
    {
      label: 'VMD设计需求表',
      name: 'vmdDesignFileList',
      fileUrl: VMDdemoUrl
    },
    {
      label: '店铺租赁合同',
      name: 'shopLeaseContractFileList'
    },
    {
      label: '该门店一镜到底视频，必须上传（必填）',
      name: 'oneShotfilmFileList'
    }
    // {
    //   label: '经典大店补贴位置及面积审核附件（必填）',
    //   name: 'classicBigStoreAreaFileList',
    //   required:
    //     ['空白加盟', '转让加盟'].includes(formData.value.storeOpeningMode) &&
    //     Number(formData.value.shop.shopArea) > 400,
    //   fileUrl: bigShopAreaUrl,
    //   hidden: !(
    //     ['空白加盟', '转让加盟'].includes(formData.value.storeOpeningMode) &&
    //     Number(formData.value.shop.shopArea) > 400
    //   )
    // }
  ]
})
// 表单数据源
const formData = ref({
  zsOrderNumber: '',
  linkOrderOaRequestId: '',
  linkOrderFormId: '',
  id: '',
  bossId: '',
  title: '',
  bossName: '',
  bossNumber: '',
  orderNumber: '', // 流程单号
  optUserId: userInfo?.accountId || '',
  optUserName: userInfo?.realName || '',
  optJobNumber: userInfo?.code || '',
  optDeptId: userInfo?.deptId || '',
  optDeptName: userInfo?.deptName || '',
  optTime: '',
  businessLicenseId: '', //售达方
  licenseNumber: '', //售达方编号,
  businessLicenseName: '', //售达方名称,
  shopBrandNumber: '', //送达方编号
  authorizedBrandName: '', //送达方名称
  authorizedCityCode: '', //授权城市
  linkOrderNumber: '', //关联撤店单号
  marketCenterCode: '', // 营销中心
  marketCenterName: '', //营销中心
  bigRegionCode: '', // 营销部
  bigRegionName: '', //营销部
  storeOpeningType: '', // 开店模式
  storeOpeningMode: '', // 开店类型
  brands: [], // 新店品牌
  shopOutsideFileList: [],
  storeAroundOppositeBrandFileList: [],
  shopMarketInsideLocationFileList: [],
  shopCADFileList: [],
  shopLeaseContractFileList: [],
  vmdDesignFileList: [],
  secondaryDistributorFileList: [],
  oneShotfilmFileList: [],
  classicBigStoreAreaFileList: [],
  fileRecordList: [],
  remark: '',
  marketShopCount: '',
  shop: {
    decorationArea: '', // 门店装修面积
    ifParentShopNumber: '',
    parentShopNumber: '',
    // oldShopNumber: '',
    marketShopCount: '',
    id: '',
    storeNature: '',
    shopNumber: '',
    independentDoorHeadFlag: '',
    province: '',
    city: '',
    area: '',
    street: '',
    shopType: '',
    shopTypeName: '',
    shopTypeValue: '',
    shopFloor: '',
    shopFloorNumber: '',
    shopPositionLevel: '',
    marketName: '',
    marketType: '',
    regionAreaCode: '',
    longitude: '',
    latitude: '',
    managementModel: '',
    contactPerson: '',
    contactPersonIdcard: '',
    contactPersonPhone: '',
    decorationTime: '',
    renovationLiablePerson: '',
    renovationPersonPhone: '',
    shopAlias: '',
    shopRent: '',
    shopArea: '',
    dianjiangDesigner: '', //点将设计师",
    dianjiangDesignerName: '',
    ifDianjiangDesigner: '', //是否点将设计师",
    shopBrandContentList: [],
    shopAddArea: '',
    oldShopArea: '',
    ifRenovation: 1,
    dianjiangDesignerPrice: '',
    dianjiangDesignerFactor: '',
    marketLevelName: '',
    marketLevelCode: '',
    doorHeaderBeforeArea: '', //调整前门头面积
    doorHeaderRenovationArea: '', //门头装修面积
    doorHeaderArea: '' //门头实用面积(调整后门头面积)
  }
})

// 售达方
const businessMsg = ref({})
// 授权城市
// const authorizedCityList = ref([])
const authorizedCityMsg = ref({})

// 送达方列表
const shopBrandNumberList = ref([])

// 获取售达方数据
const handleChangeBusiness = async data => {
  if (!data) return
  businessMsg.value = data
  formData.value.bossId = data.bossId
  formData.value.businessLicenseName = data.businessLicenseName
  formData.value.licenseNumber = data.licenseNumber
  formData.value.bossName = data.bossName
  formData.value.bossNumber = data.bossNumber
  // authorizedCityMsg.value = {}
  // authorizedCityList.value = []
}

// 根据售达方获取授权城市
const getAuthorizedCityByBusinessLicense = async params => {
  const r = await getAuthorizedCityData({
    businessLicenseId: params.getDataListParams
  })
  // authorizedCityList.value = r.data
  return r
}

let count = 0
const handleChangeShopBrand = data => {
  authorizedCityGetMarketCenterData(data)
  if (count === 0) {
    MessageBox.alert(
      '门店品牌将会影响到您摆场样品下单，请再次确认门店授权系列！',
      '提示'
    )
    count++
  }
}

// 获取流程列表
const flowTotalCount = ref(0)
const getFlowList = async params => {
  const res = await getShopListPage({
    currPage: params.page,
    orderNumber: params.searchText || undefined,
    processCode: 'JXS007',
    status: 40
  })
  flowTotalCount.value = res.totalCount
  return res.data
}
const onOptionsListNotFound = async (
  { value, getDataListParams },
  callback
) => {
  const res = await getShopListPage({
    orderNumber: value,
    pageSize: 10,
    processCode: 'JXS007',
    status: 40
  })
  callback(res.data)
}

// 营销中心
// 根据授权城市获取大区信息
const authorizedCityGetMarketCenterData = async list => {
  // const form = formData.value

  if (list.length === 0) return

  const bigBrandId = bigBrandMap.value.get('慕思经典')?.id
  const index = list.findIndex(v => v.bigBrandId === bigBrandId)

  if (index > -1) {
    const { provinceCode, cityCode, areaCode } = authorizedCityMsg.value
    const { data } = await requestGetSizeBarListByDept({
      provinceCode,
      cityCode,
      areaCode
    })

    formData.value.marketCenterCode = data.smallCode
    formData.value.marketCenterName = data.smallName
    formData.value.bigRegionName = data.administrativeName
    formData.value.bigRegionCode = data.administrativeCode
  }
}

// 新店品牌确认
const newShopBrandGet = data => {
  brandData.value = data || []
}

// 获取点将设计师列表
// const getDesignerList = async params => {
//   console.log(params)
//   const reg = /^(?:[\u4e00-\u9fa5·]{1,16})$/
//   const res = await accountListPage({
//     userRole: 1,
//     realName: reg.test(params.searchText) ? params.searchText : null,
//     code: !reg.test(params.searchText) ? params.searchText : null,
//     currPage: params.page
//   })
//   return res
// }

/**
 * @description: 文件预览
 * @param {*} url
 * @return {*}
 */
const handlePreview = ({ url }) => {
  window.open(url)
}
const handleUpload = (fileList, fileName) => {
  formData.value[fileName] = fileList.map(item => {
    return {
      ...item.response,
      functionAttribution: `${fileName}`
    }
  })
}

/**
 * @description: 保存/提交
 * @param {*}
 */
const handleSave = async code => {
  isLoading.value = true
  const { shop } = formData.value
  // 提交才进行验证
  if (shop.shopNumber && code == 5) {
    if (
      !shop.shopPositionLevel &&
      (shop.shopType === 'SJ009' ||
        ['重点卖场', '一般卖场', '强势卖场', '非集中卖场'].includes(
          shop.marketType
        ))
    ) {
      isLoading.value = false
      return Message.warning('门店数据缺少门店等级')
    }
  }
  if (
    shop.shopType !== 'SJ007' &&
    formData.value.brands.includes('1390119725050277896')
  ) {
    isLoading.value = false
    return Message.warning(
      '渠道性质不是新渠道店，新店品牌不允许选择“慕思经典-新渠道”'
    )
  }
  // console.log('formData', formData.value)
  const formDataOptions = deepCopy(formData.value)
  formDataOptions.status = code
  formDataOptions.optTime = moment().format('YYYY-MM-DD HH:mm:ss')
  formDataOptions.title = flowTitle.value
  formDataOptions.fileRecordList = [
    ...formDataOptions.shopOutsideFileList,
    ...formDataOptions.shopMarketInsideLocationFileList,
    ...formDataOptions.storeAroundOppositeBrandFileList,
    ...formDataOptions.shopCADFileList,
    ...formDataOptions.shopLeaseContractFileList,
    ...formDataOptions.vmdDesignFileList,
    ...formDataOptions.secondaryDistributorFileList,
    ...formDataOptions.oneShotfilmFileList,
    ...formDataOptions.classicBigStoreAreaFileList
  ]
  formDataOptions.shop.shopBrandContentList = formDataOptions.brands.map(
    item => {
      return {
        brandId: item
      }
    }
  )
  formDataOptions.shop.ifRenovation = 1
  formDataOptions.brands = formDataOptions.brands.join(',')
  //areaCount
  // this.storeOpeningMode === '原店扩大'
  //               ? Number(data.shopAddArea) + Number(data.oldShopArea)
  //               : data.shopArea
  if (formDataOptions.storeOpeningMode === '原店扩大') {
    formDataOptions.shop = {
      ...formDataOptions.shop,
      shopArea: (
        Number(formDataOptions.shop.shopAddArea || 0) +
        Number(formDataOptions.shop.oldShopArea || 0)
      ).toFixed(2)
    }
  }
  // 定义需要装修的面积
  if (['原店扩大', '部分装修'].includes(formDataOptions.storeOpeningMode)) {
    formDataOptions.shop.decorationArea = formDataOptions.shop.shopAddArea
  } else {
    formDataOptions.shop.decorationArea = formDataOptions.shop.shopArea
  }
  if (formDataOptions.storeOpeningType === '全新开店') {
    formDataOptions.shop.doorHeaderRenovationArea =
      formDataOptions.shop.doorHeaderBeforeArea
  }

  // 判断授权城市为全国
  if (formDataOptions.shop.province === '全国') {
    formDataOptions.shop.province = formDataOptions.shop.regionProvince
    formDataOptions.shop.city = formDataOptions.shop.regionCity
  }
  try {
    if (code) {
      ruleForm.value.validate(async valid => {
        if (valid) {
          isCache.value = false
          await requestSaveFlow(formDataOptions)
          MessageBox.alert('提交成功！', '提示', {
            type: 'success'
          }).then(() => {
            router.push({
              name: 'myApply'
            })
          })
        } else {
          Message.warning('必填项不能为空')
        }
      })
    } else {
      await requestSaveFlow(formDataOptions)
      MessageBox.alert('保存成功！', '提示', {
        type: 'success'
      }).then(() => {
        router.push({
          name: 'draft'
        })
      })
    }
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}

/**
 * @description: 返回到主页
 * @param {*}
 */
const goBack = () => {
  // removeSession('storeManagementFlow')
  isCache.value = false
  router.back(-1)
}

const downloadFile = file => {
  window.location.href = file
}

const getFlowDetail = async id => {
  const res = await requestGetHistoryFlow({
    declarationFormId: id
  })
  const detailData = JSON.parse(res.data[0].historicalData)
  detailData.brands = detailData.brands.split(',')
  detailData.shop.marketShopCount = detailData?.marketShopCount ?? ''
  // Use Object.entries to loop over the key-value pairs
  Object.entries(formData.value).forEach(([key, value]) => {
    if (detailData.hasOwnProperty(key)) {
      formData.value[key] = detailData[key]
    }
  })
  detailData.shop.regionProvince = detailData.shop.province
  detailData.shop.regionCity = detailData.shop.city
  const functionAttributions = [
    'shopOutsideFileList',
    'shopMarketInsideLocationFileList',
    'storeAroundOppositeBrandFileList',
    'shopCADFileList',
    'shopLeaseContractFileList',
    'vmdDesignFileList',
    'secondaryDistributorFileList',
    'oneShotfilmFileList',
    'classicBigStoreAreaFileList'
  ]

  // Use reduce to avoid repeating yourself
  const fileLists = functionAttributions.reduce((acc, attribution) => {
    acc[attribution] = detailData.fileRecordList.filter(
      item => item.functionAttribution === attribution
    )
    return acc
  }, {})

  formData.value = Object.assign(formData.value, fileLists)
}
// 详情
const oaDetail = orderNumber => {
  const url = `${
    process.env.VUE_APP_OA_URL
  }/mobile/plugin/loginForSession.jsp?loginid=MerchantSysAdmin&requestid=${
    formData.value.linkOrderOaRequestId || ''
  }&workflowid=${orderNumber || ''}`
  // console.log(url)
  window.open(url)
}
/**
 * @description: 是否启动缓存机制
 */
const establishCache = () => {
  console.log('establishCache')
  const formOptions = deepCopy(formData.value)
  if (isCache.value && !formOptions.id) {
    setSession('storeManagementFlow', formOptions)
  } else {
    removeSession('storeManagementFlow')
  }
}
window.addEventListener('beforeunload', establishCache)
onMounted(() => {
  const { id } = route.query
  const cacheData = getSession('storeManagementFlow')
  if (id) {
    getFlowDetail(id)
  } else if (cacheData && !id) {
    formData.value = cacheData
  }
})
onUnmounted(() => {
  window.removeEventListener('beforeunload', establishCache)
})

onBeforeRouteLeave((to, from, next) => {
  establishCache()
  next()
})

watchEffect(async () => {
  const authorizedCityCode = authorizedCityMsg.value.authorizedCityCode
  // console.log(authorizedCityMsg.value)
  if (!authorizedCityCode || !businessMsg.value.id) return
  // 获取送达方
  const res = await getMasterDataList({
    businessLicenseId: businessMsg.value.id,
    authorizedCityCode: authorizedCityCode,
    brandId: '1390119720428154881'
  })
  shopBrandNumberList.value = res.data
  const { data } = await requestGetCityLevel({
    authorizedCityCode
  })
  // authorizedCityMsg.value.marketLevelName = data[0].marketLevelName
  // authorizedCityMsg.value.marketLevelCode = data[0].marketLevelCode
  Vue.set(authorizedCityMsg.value, 'marketLevelName', data[0].marketLevelName)
  Vue.set(authorizedCityMsg.value, 'marketLevelCode', data[0].marketLevelCode)
  formData.value.shop.marketLevelName = data[0].marketLevelName
  formData.value.shop.marketLevelCode = data[0].marketLevelCode
})
watchEffect(() => {
  if (!formData.value.shop.shopType) return
  uploadAttachmentColumns.value.forEach(item => {
    if (
      [
        'shopMarketInsideLocationFileList',
        'shopLeaseContractFileList'
      ].includes(item.name)
    ) {
      item.required = formData.value.shop.shopType !== 'SJ010'
    }
  })
})

// 送达方
watchEffect(() => {
  if (brandData.value.length === 0) return
  const list = brandData.value.map(item => item.parentId || item.bigBrandId)
  let arr = shopBrandNumberList.value
    .filter(i => list.includes(i.brandId))
    .map(v => v)
  // arr = [...new Set(arr.map(v => v.shopBrandNumber))]
  const shopBrandNumber = [...new Set(arr.map(v => v.shopBrandNumber))]
  const authorizedName = [...new Set(arr.map(v => v.authorizedName))]
  formData.value.shopBrandNumber = shopBrandNumber.join(',') || ''
  formData.value.authorizedBrandName = authorizedName.join(',') || ''
})

const isShowMessageBox = ref(true)
const saveBrandList = ref('')
watch(
  () => brandSelect?.value?.saveBrandList,
  value => {
    saveBrandList.value = value.map(item => item.name).join(',') || ''
    if (
      isArray(value) &&
      authorizedCityMsg.value.authorizedCityCode &&
      value.length === 0
    ) {
      if (isShowMessageBox.value) {
        isShowMessageBox.value = false
        MessageBox.alert(
          '您未代理慕思经典任一系列，请先取得慕思经典授权。',
          '提示'
        ).then(() => {
          isShowMessageBox.value = true
        })
      }
    }
  }
)
</script>

<style scoped lang="scss">
@import '../../styles/colors.scss';
.d-footer {
  // height: 45px;
  padding: 16px;
  position: fixed;
  bottom: 15px;
  background: #fff;
  box-shadow: -3px -3px 8px rgba($color: #ccc, $alpha: 0.7);
  z-index: 1;
}
.tip {
  color: $danger-dark-color;
}
.example {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .link {
    width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 8px;
    color: #333;
  }
}
.requiredFormItem {
  ::v-deep {
    .el-form-item__label,
    .el-form-item__content,
    .el-input__inner {
      font-size: 18px;
      font-weight: 600;
      color: #f56c6c !important;
    }
  }
}
</style>
