<template>
  <div class="app-container">
    <TablePro
      ref="tableProRef"
      :request="getTableList"
      :columns="columns"
      :showOverflowTooltip="true"
      v-if="brandList.length">
      <template #actionArea>
        <PopupForm
          ref="popupFormRef"
          popup-type="el-dialog"
          :columns="formColumns"
          :on-submit="onSubmit">
        </PopupForm>
      </template>
    </TablePro>
  </div>
</template>

<script>
import TablePro, { render, renderFormItem } from '@/components/TablePro'
import PopupForm from '@/components/FormPro/PopupForm'
import { pick } from 'lodash'
import {
  authorizedCityAndLevel,
  authorizedcitylevelAdd,
  authorizedcitylevelUpate,
  getUserAuthorizedBrand
} from '@/api/flowSetting/cityInfoManage.js'
import { getStorage } from '@/utils/storage'
export default {
  components: {
    TablePro,
    PopupForm
  },
  data() {
    return {
      //大品牌列表
      brandList: [],
      defaultSelectedBrand: '',
      // 表格项配置
      columns: [
        {
          title: '地域类型',
          name: 'overseasFlag',
          search: true,
          valueType: 'select',
          defaultValue: '0',
          valueEnum: [
            { label: '国内', value: '0' },
            { label: '海外', value: '1' }
          ]
        },

        {
          title: '大品牌',
          name: 'brandId',
          search: true,
          valueType: 'select',
          // hideInTable: true,
          valueEnum: []
        },
        {
          title: '国家编码',
          name: 'countryCode'
        },
        {
          title: '城市',
          name: 'city'
        },
        {
          title: '区域',
          name: 'area'
        },
        {
          title: '镇',
          name: 'town'
        },
        {
          title: '授权城市编号',
          name: 'authorizedCityCode',
          search: true
        },
        {
          title: '授权城市名称',
          name: 'authorizedCityName',
          search: true
        },
        {
          title: '城市级别',
          name: 'marketLevelName',
          render: render((h, context) => {
            return (
              <div class="table-city">
                <span>{context.data.marketLevelName}</span>
                <div class="table-icon">
                  {context.data.levelId ? (
                    <i
                      class="el-icon-edit"
                      onClick={() => {
                        //修改
                        const {
                          marketLevelCode,
                          marketLevelName,
                          brandId,
                          levelId,
                          authorizedCityCode
                        } = pick(context.data, [
                          'authorizedCityCode',
                          'marketLevelCode',
                          'marketLevelName',
                          'levelId',
                          'brandId'
                        ])

                        this.$refs.popupFormRef.onShow({
                          authorizedCityCode,
                          id: levelId,
                          marketLevelCode,
                          marketLevelName,
                          brandId
                        })
                      }}></i>
                  ) : (
                    <i
                      class="el-icon-plus"
                      onClick={() => {
                        //新增
                        const params = pick(context.data, [
                          'brandId',
                          'authorizedCityCode',
                          'marketLevelCode',
                          'marketLevelName'
                        ])
                        this.$refs.popupFormRef.onShow({ ...params })
                      }}></i>
                  )}
                </div>
              </div>
            )
          })
        },
        {
          title: '授权城市级别编号',
          name: 'marketLevelCode'
        },
        {
          title: '授权城市级别ID',
          name: 'levelId'
        },
        {
          title: '授权城市级别二级分类',
          name: 'marketLevelTypeName'
        },
        {
          title: '状态',
          name: 'ifEnabled',
          valueType: 'select',
          defaultValue: '0',
          search: true,
          valueEnum: [
            { label: '失效', value: '1' },
            { label: '有效', value: '0' }
          ]
        }
      ],
      // 表单项配置
      formColumns: [
        {
          title: '大品牌',
          name: 'brandId',
          valueType: 'select',
          fieldProps: {
            required: true
            // disabled: true
          }
        },
        {
          title: '授权城市编号',
          name: 'authorizedCityCode',
          valueType: 'input',
          fieldProps: {
            required: true
          }
        },
        {
          title: '等级编号',
          name: 'marketLevelCode',
          valueType: 'input',
          fieldProps: {
            required: true
          }
        },
        {
          title: '等级名称',
          name: 'marketLevelName',
          valueType: 'input',
          fieldProps: {
            required: true
          }
        }
      ]
    }
  },
  created() {
    const userInfo = getStorage('userInfo') || {}
    getUserAuthorizedBrand({ userId: userInfo.accountId || '' }).then(res => {
      if (res.data && res.data.length === 0) {
        this.$message.error('您没有权限，请联系管理员')
        return
      }
      const brandList = res.data.map(item => ({
        label: item.name,
        value: item.id
      }))
      this.columns.forEach(item => {
        if (item.name === 'brandId') {
          item.defaultValue = brandList[0].value
          item.valueEnum = brandList
        }
      })
      this.formColumns.forEach(item => {
        if (item.name === 'brandId') {
          item.valueEnum = brandList
        }
      })
      this.brandList = brandList
    })
  },
  methods: {
    // 获取列表数据
    async getTableList(params) {
      return authorizedCityAndLevel({
        overseasFlag: 0,
        ...params
      }).then(res => {
        res.data = res.data.map(item => ({
          ...item,
          brandId: params.brandId
        }))
        return res
      })
    },
    // 表单提交,提交成功后返回Boolean(resolve)为true会自动关闭弹框
    onSubmit({
      id,
      authorizedCityCode,
      marketLevelCode,
      marketLevelName,
      brandId
    }) {
      const request = id ? authorizedcitylevelUpate : authorizedcitylevelAdd
      const data = id
        ? { id, marketLevelCode, marketLevelName }
        : { authorizedCityCode, marketLevelCode, marketLevelName, brandId }
      return request(data).then(res => {
        this.$message.success(id ? '修改成功' : '新增成功')
        this.$refs.tableProRef.onRefreshTableList()
        return true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.table-city) {
  display: flex;
  justify-content: space-between;
  .table-icon {
    color: #409eff;
    font-size: 16px;
  }
}
</style>
