<template>
  <flow-form
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="经销商店面重装申请表"
    :tips="[
      '1、经销商店面重装申请表：经销商旗下门店到了装修要求时间需要重新装修',
      '2、重装流程只能在原门店重新装修，不调整门店系列，需要调整系列请提交“门店系列管理流程”',
      '3、换位重装不适应于此流程，如是换位重装，请走“老经销商增加新店申请表”+“ 经销商撤店申请流程”。重装流程仅针对原门店原地址的门店做重新装修'
    ]"
  >
    <div>
      <required-form-item-input
        v-for="(value, key) in { title: '标题', orderNumber: '单号' }"
        :key="key"
        class="formItem"
        :value="key === 'title' ? (form[key] = title) : form[key]"
        :label="value"
        disabled
        :required="false"
        placeholder="自动生成"
      />
      <!-- 招商单号 -->
      <RequiredFormItemInput
        v-model="form.zsOrderNumber"
        label="招商单号"
        class="formItem"
        prop="zsOrderNumber"
        placeholder="归档后生成"
        disabled
      />
    </div>
    <HeaderTitle title="经销商个人基础信息" />
    <required-form-item-input
      v-for="(value, key) in {
        optJobNumber: '提单人工号',
        optUserName: '提单人',
        optDeptName: '提单部门'
      }"
      :key="key"
      v-model="form[key]"
      class="formItem"
      :prop="key"
      :label="value"
      disabled
    />
    <required-form-item-input
      label="提单日期"
      class="formItem"
      :value="form.optTime"
      placeholder="自动生成"
      disabled
    />

    <required-form-item-select
      ref="businessRef"
      v-model="form.businessLicenseId"
      class="formItem"
      prop="businessLicenseId"
      label="售达方"
      label-key="licenseNumber"
      value-key="id"
      :select-props="{
        filterable: true,
        remote: true,
        'popper-class': 'custom-business-select'
      }"
      :select-listeners="{
        change: () => {
          form.authorizedCityCode = ''
          form.shopId = ''
        }
      }"
      :total-count="businessCountTotal"
      :get-data-list="getBusinessLicense"
      :get-next-list="getBusinessLicense"
      @onOptionsListNotFound="onNotFoundBusinessLicense"
      @changeSelectedItem="
        e => {
          businessSelectedItem = e
          bossId = e ? e.bossId : ''
        }
      "
    >
      <template v-slot="{ scope }">
        <span>{{ scope.row.licenseNumber }}</span>
        <small>{{ scope.row.businessLicenseName }}</small>
      </template>
    </required-form-item-select>

    <required-form-item-input
      v-for="(value, key) in {
        businessLicenseName: '售达方名称',
        realName: '经销商（实际控股人）',
        contactInfo: '经销商（实际控股人）联系方式',
        email: 'QQ/电邮',
        businessLicenseName: '营业执照名称',
        creditCode: '社会统一信用代码'
      }"
      :key="value"
      class="formItem"
      :label="value"
      placeholder="请先选择售达方"
      disabled
      :value="businessSelectedItem ? businessSelectedItem[key] : ''"
    />

    <required-form-item-select
      ref="shopRef"
      v-model="form.authorizedCityCode"
      class="formItem"
      prop="authorizedCityCode"
      label="授权城市"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      :get-data-list="getCitylist"
      :get-next-list="getCitylist"
      :get-data-list-params="businessSelectedItem"
      :total-count="authCityCountTotal"
      :select-listeners="{
        change: () => {
          form.shopId = ''
        }
      }"
      :select-props="{
        disabled: !form.businessLicenseId
      }"
      @onOptionsListNotFound="onNotFoundCityList"
      @changeSelectedItem="
        e => {
          authorizedSelectedItem = e
        }
      "
    />
    <required-form-item-input
      v-for="(value, key) in {
        province: '省份',
        city: '地级市',
        area: '区/县'
      }"
      :key="key"
      class="formItem"
      :label="value"
      placeholder="请先选择授权城市"
      :value="authorizedSelectedItem ? authorizedSelectedItem[key] : ''"
      disabled
    />
    <!-- 营销中心 -->
    <RequiredFormItemInput
      v-if="isClassic"
      v-model="form.marketCenter"
      label="营销中心"
      class="formItem"
      prop="marketCenter"
      disabled
    />
    <required-form-item-input
      class="formItem"
      label="已代理品牌"
      :value="brandName"
      disabled
      placeholder="请先选择授权城市"
    />

    <required-form-item-input
      v-for="(value, key) in {
        renovationLiablePerson: '装修对接人',
        renovationPersonPhone: '装修对接人电话'
      }"
      :key="key"
      v-model="form[key]"
      class="formItem"
      :label="value"
      :prop="key"
      :validations="validations[key]"
    />

    <HeaderTitle title="申请重装终端门店信息" />
    <div style="margin-bottom: 10px">
      <el-table :data="[shopDetailSelectItem]" border>
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column label="门店编号" min-width="170">
          <template slot-scope="{}">
            <required-form-item-select
              ref="shopSelectRef"
              v-model="form.shopId"
              prop="shopId"
              label="门店编号"
              :show-label="false"
              label-key="shopNumber"
              value-key="id"
              :get-data-list="getShopDetail"
              :get-data-list-params="`${bossId || ''},${
                form.authorizedCityCode || ''
              }`"
              :select-props="{
                disabled: !bossId || !form.authorizedCityCode
              }"
              @change="shopDetailChange"
              @changeSelectedItem="
                e => {
                  shopDetailSelectItem = e
                }
              "
            />
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="门店性质">
          <template slot-scope="scope">
            {{
              scope.row &&
                (scope.row.shopModelList.length > 1 ? '综合店' : '品牌店')
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="partnership" label="合作关系" width="160">
          <template v-if="scope.row" slot-scope="scope">
            <request-module-select
              v-model="scope.row.partnership"
              type="partnership"
              placeholder=""
              disabled
              :text-display="true"
            />
          </template> -->
        <!-- <template
            slot-scope="scope"
            v-if="$refs.partnershipModuleSelectRef && scope.row"
          >
            {{
              $refs.partnershipModuleSelectRef.$selectRef.optionList
                .filter(
                  item => String(item.code) === String(scope.row.partnership)
                )
                .map(item => item.content)
                .join(',')
            }}
          </template> -->
        <!-- </el-table-column> -->
        <el-table-column prop="shopName" label="门店名称" min-width="180" />
        <el-table-column prop="province" label="省份" />
        <el-table-column prop="city" label="地级市" />
        <el-table-column prop="area" label="区/县" />
        <el-table-column prop="street" label="道路/街道" min-width="100" />
        <el-table-column prop="shopType" label="渠道性质" min-width="150">
          <template v-if="scope.row" slot-scope="scope">
            <request-module-select
              ref="shopTypeModuleSelectRef"
              v-model="scope.row.shopType"
              type="store_type"
              disabled
              :text-display="true"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="shopType"
          label="渠道性质二级分类（商场名称）"
          min-width="220"
        >
          <template v-if="scope.row" slot-scope="scope">
            {{ scope.row.shopTypeValue }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="marketName" label="商场名称"> </el-table-column> -->
        <el-table-column prop="shopFloor" label="门店楼层" />
        <el-table-column prop="shopFloorNumber" label="门店铺号" />
        <el-table-column label="门店地址">
          <template v-if="scope.row" slot-scope="scope">
            {{
              stringJoin(
                scope.row.province,
                scope.row.city,
                scope.row.area,
                scope.row.street,
                scope.row.shopTypeValue,
                scope.row.shopFloor,
                scope.row.shopFloorNumber
              )
            }}
          </template>
        </el-table-column>
        <el-table-column prop="brandName" label="门店品牌" width="120" />
        <el-table-column prop="managementModel" label="经营模式" width="150">
          <template v-if="scope.row" slot-scope="scope">
            <required-module-form-item-select
              v-model="form.managementModel"
              class="formItem"
              prop="managementModel"
              :show-label="false"
              label="经营模式"
              type="management_model"
            />
          </template>
        </el-table-column>
        <template v-if="Number(form.managementModel) === 20">
          <el-table-column
            v-for="(value, key) in {
              contactPerson: '二级分销商',
              contactPersonIdcard: '二级分销商身份证',
              contactPersonPhone: '二级分销商联系方式'
            }"
            :key="key"
            :prop="key"
            :label="value"
            width="160"
          >
            <required-form-item-input
              :key="key"
              v-model="form[key]"
              slot-scope="{}"
              delay-update
              :show-label="false"
              :prop="key"
              :label="value"
              :validations="validations[key] || []"
            />
          </el-table-column>
        </template>
        <el-table-column prop="shopRent" label="门店租金（万/年）" width="160">
          <template v-if="scope.row" slot-scope="scope">
            <required-form-item-input
              v-model="form.shopRent"
              label="门店租金（万/年）"
              delay-update
              class="formItem"
              prop="shopRent"
              :show-label="false"
              :input-props="{ type: 'number' }"
            />
          </template>
        </el-table-column>
        <el-table-column label="门店实用面积" min-width="120">
          <template slot-scope="{}">
            {{
              (form.shopArea = (form.shopBrandList || []).reduce(
                (accumulator, currItem) => {
                  return accumulator + Number(currItem.actualArea || 0)
                },
                0
              ))
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-if="shopDetailSelectItem">
      <HeaderTitle title="生成门店明细数" />
      <div style="margin-bottom: 10px">
        <el-table :data="form.shopBrandList" border>
          <el-table-column type="index" label="序号" width="55" />
          <!-- <el-table-column prop="shopFloorNumber" label="门店编号">
              {{ shopDetailSelectItem.shopFloorNumber }}
            </el-table-column> -->

          <el-table-column prop="shopName" label="门店名称">
            {{ shopDetailSelectItem.shopName }}
          </el-table-column>
          <!-- <el-table-column prop="shopType" label="门店类型">
              {{ shopDetailSelectItem.shopName }}
            </el-table-column> -->
          <el-table-column prop="brandName" label="门店品牌" />
          <el-table-column prop="actualArea" label="品牌面积">
            <template
              v-if="shopDetailSelectItem.shopModelList[scope.$index]"
              slot-scope="scope"
            >
              {{ shopDetailSelectItem.shopModelList[scope.$index].brandArea }}
            </template>
          </el-table-column>
          <el-table-column prop="actualArea" label="样品套数">
            <template
              v-if="shopDetailSelectItem.shopModelList[scope.$index]"
              slot-scope="scope"
            >
              {{ shopDetailSelectItem.shopModelList[scope.$index].sampleSets }}
            </template>
          </el-table-column>
          <el-table-column prop="actualArea" label="实际品牌面积">
            <template slot-scope="scope">
              <required-form-item-input
                v-model="scope.row.actualArea"
                class="formItem"
                style="width: 100%"
                :show-label="false"
                label="实际品牌面积"
                :prop="`shopBrandList.${scope.$index}.actualArea`"
                :validations="['number']"
                :input-props="{
                  maxLength: 6
                }"
              />
            </template>
          </el-table-column>
          <el-table-column prop="actualSampleQuantity" label="实际样品套数">
            <template slot-scope="scope">
              <required-form-item-input
                v-model="scope.row.actualSampleQuantity"
                class="formItem"
                style="width: 100%"
                :show-label="false"
                label="实际样品套数"
                :prop="`shopBrandList.${scope.$index}.actualSampleQuantity`"
                :validations="['number']"
                :input-props="{
                  maxLength: 4
                }"
              />
            </template>
          </el-table-column>
          <el-table-column prop="sampleSets" label="是否装修">
            <template slot-scope="scope">
              <required-module-form-item-select
                :prop="`shopBrandList.${scope.$index}.ifDecorate`"
                :value="
                  (scope.row.ifDecorate =
                    String(scope.row.ifDecorate) === '0' ? 0 : 1)
                "
                type="shop_if_decorate"
                :show-label="false"
                @changeValue="ifDecorate => (scope.row.ifDecorate = ifDecorate)"
              />
            </template>
          </el-table-column>
          <!-- <el-table-column prop="updateReason" label="系列变更原因">
            <template slot-scope="scope">
              <required-module-form-item-select
                v-model="scope.row.updateReason"
                :prop="`shopBrandList.${scope.$index}.updateReason`"
                type="shop_change"
                :show-label="false"
                :required="checkBrandIsDerucciClassic(scope.row.brandName)"
                :filter-data-list="
                  list => list.filter(item => item.status === 0)
                "
                label="系列变更原因"
              />
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </template>
    <required-formitem-upload-file
      v-for="(value, key) in formLayout.uploadList"
      :key="key"
      v-model="form[key]"
      prop="fileList"
      class="formItem"
      label="上传附件"
      limit-size="400"
      :on-preview="onPreview"
      :required="value.required"
    >
      <div slot="describe">
        {{ value.label }}
      </div>
      <div slot="tip">单个文件不能超过400M</div>
    </required-formitem-upload-file>
    <br>
    <required-form-item-select
      v-model="form.ifSubsidy"
      class="formItem"
      label="是否补贴"
      :required="false"
      label-tip="V6填写字段，非V6招商可忽略。V6家居所提供的经销商新店装修补贴支持，只补贴净增长部分面积，如若当该授权区域的门店在一年内（按自然日计算）发生了撤店行为，导致门店面积未实现净增长，所获得的装修补贴金额V6家居将全额扣回，且按照所享受补贴金额的10%进行追加处罚"
      :data-list="[
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ]"
      label-key="label"
      value-key="value"
    />
    <required-form-item
      v-if="form.ifSubsidy"
      prop="subsidyAmount"
      class="formItem"
      label="补贴金额（万）"
    >
      <el-input-number
        v-model="form.subsidyAmount"
        :max="9999"
        :min="0"
        :controls="false"
      />
    </required-form-item>

    <remark-item
      v-model="form.remark"
      style="margin-top: 40px"
      :file-list="form.remarkfileList"
      @onUpload="
        file => {
          form.remarkfileList = file.map(item => item.response)
        }
      "
    />
  </flow-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import HeaderTitle from './components/headerTitle'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormitemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect.vue'
import FlowForm from './components/flowForm'
import RemarkItem from './components/remarkItem'
import { checkBrandIsDerucciClassic } from './data/checkBrandIsDerucciClassic'

import * as shopDecorateApi from '@/api/attractPlatform/shopDecorateApi'
import * as BusinessApi from '@/api/attractPlatform/business'
import { requestGetSizeBarListByDeptMerge } from '@/api/common/'
import { MessageBox } from 'element-ui'

const validations = {
  renovationLiablePerson: ['name'],
  renovationPersonPhone: ['phone'],
  contactPerson: ['name'],
  contactPersonIdcard: ['user_id'],
  contactPersonPhone: ['phone']
}

export default {
  components: {
    HeaderTitle,
    RequiredFormItem,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormitemUploadFile,
    RequiredModuleFormItemSelect,
    RequestModuleSelect,
    FlowForm,
    RemarkItem
  },
  data() {
    return {
      isClassic: false,
      brandName: '',
      bossId: '',
      form: {
        title: '经销商店面重装申请表',

        marketCenter: '', // 营销中心
        marketCenterCode: '', // 营销中心
        brandRegionCode: '', //品牌区域编码

        businessLicenseId: '', // 售达方id
        authorizedCityCode: '', // 授权城市
        shopId: '', // 门店id

        id: '', // 表单id（修改时使用）
        // processCode: "8868", //报单ID
        orderNumber: '', // 单号
        optUserId: '', // 提单人ID
        optJobNumber: '', // 提单人工号
        optUserName: '', // 提单人姓名
        optDeptId: '', // 提单部门ID
        optDeptName: '', // 提单部门名称
        optTime: '', // 提单日期
        status: 0, // 0草稿  10提交发布

        urgentLevel: 0, // 0正常1紧急
        urgentExplain: '', // 紧急说明

        renovationLiablePerson: '', // 装修对接人
        renovationPersonPhone: '', // 装修对接人电话
        managementModel: '', // 经营模式
        contactPerson: '', // 联系人
        contactPersonIdcard: '', // 联系人身份证
        contactPersonPhone: '', // 联系人电话
        shopRent: '', // 门店租金

        fileList: [], // 店铺含外围照片不低于5张或视频
        planeList: [], // 商场平面图标注慕思位置及人流动向、独立店地图定位
        cadList: [], // 店铺CAD图纸
        leaseContractList: [], // 店铺租赁合同
        commitmentList: [], // 开店承诺书
        vmdList: [], // VMD设计需求表

        shopBrandList: [],

        ifSubsidy: 0,
        subsidyAmount: '',

        remark: '', // 备注文本
        remarkfileList: [] // 备注文件
      },
      // 选中的售达方
      businessSelectedItem: null,
      // 授权城市
      authorizedSelectedItem: null,
      // 选中的门店
      shopDetailSelectItem: null,
      // 售达方总条数
      businessCountTotal: null,
      // 授权城市总条数
      authCityCountTotal: null,
      // 验证规则
      validations,

      formLayout: {
        uploadList: {
          fileList: {
            label: '店铺含外围照片不低于5张或视频',
            required: false
          },
          planeList: {
            label: '商场平面图标注慕思位置及人流动向、独立店地图定位',
            required: false
          },
          cadList: {
            label: '店铺CAD图纸',
            required: false
          },
          leaseContractList: {
            label: '店铺租赁合同',
            required: false
          },
          commitmentList: {
            label: '开店承诺书',
            required: false
          },
          vmdList: {
            label: 'VMD设计需求表',
            required: false
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      business: state => state.user.business,
      isOA: state => state.dealershipTransfer.isOA
    }),
    ...mapGetters(['userInfo']),
    ...mapGetters('commonConfig', ['bigBrandMap', 'seriesMap']),
    title({
      businessSelectedItem,
      authorizedSelectedItem,
      shopDetailSelectItem
    }) {
      let authCity = ''
      if (authorizedSelectedItem) {
        authCity = authorizedSelectedItem.authorizedCityName
      }
      const realName = businessSelectedItem ? businessSelectedItem.realName : ''
      let brandStr = ''
      if (shopDetailSelectItem) {
        brandStr = shopDetailSelectItem.shopModelList
          .map(item => item.brandName)
          .join(',')
      }
      const list = []
      if (authCity) {
        list.push(authCity)
      }
      if (realName) {
        list.push(realName)
      }
      if (brandStr) {
        list.push(`门店品牌：${brandStr}`)
      }
      let str = '经销商店面重装申请表'
      if (list.length) {
        str += `（${list.join('，')}）`
      }
      return str
      // 门店系列管理申请表（授权城市，经销商，调整门店：XX）
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(title) {
        this.form.title = title
      }
    },
    shopDetailSelectItem(shopDetailSelectItem) {
      const findTargetId = this.bigBrandMap.get('慕思经典')?.id
      let isClassic = false
      this.seriesMap.forEach(value => {
        const isCurrSeries = shopDetailSelectItem.shopModelList.some(item => item.brandId === value.id)
        if (isCurrSeries) {
          if (value.parentId === findTargetId) {
            isClassic = true
          }
        }
      })
      this.isClassic = isClassic
    },
    // 获取授权城市下的品牌
    async authorizedSelectedItem(authorizedSelectedItem) {
      const { businessLicenseId, authorizedCityCode } = this.form
      if (!businessLicenseId || !authorizedCityCode) return
      const { data } = await BusinessApi.brandlist({
        businessLicenseId,
        authorizedCityCode,
        pageSize: 100
      })

      const brandList = data
        .filter(item => item)
      this.brandName = brandList.map(item => item.name).join(',')
      const brandIds = brandList.map(item => item.id).join(',')
      this.getGetSizeBarListByDept(brandIds)
    }
  },
  methods: {
    checkBrandIsDerucciClassic,
    getGetSizeBarListByDept(brandIds) {
      if (!this.authorizedSelectedItem || brandIds === '') {
        this.form.marketCenter = ''
        this.form.marketCenterCode = ''
        this.form.brandRegionCode = ''
      } else {
        return requestGetSizeBarListByDeptMerge({
          provinceCode: this.authorizedSelectedItem.provinceCode,
          cityCode: this.authorizedSelectedItem.cityCode,
          areaCode: this.authorizedSelectedItem.areaCode,
          townCode: this.authorizedSelectedItem.townCode,
          brandIds,
          deptId: ''
        }).then(res => {
          this.form.marketCenter = res.data.smallName
          this.form.marketCenterCode = res.data.smallCode
          this.form.brandRegionCode = res.data.brandRegionCode
        })
      }
    },
    shopDetailChange(e) {
      // 因为 change 事件触发比较早,此时 shopDetailSelectItem 尚未赋值
      this.$nextTick(() => {
        const selectedItem = this.shopDetailSelectItem
        const shopBrandList = (
          selectedItem ? selectedItem.shopModelList : []
        ).map((item, index) => {
          const originalItem = (this.form.shopBrandList || []).find(
            brandItem => brandItem.brandId === item.brandId
          )
          return {
            actualArea: originalItem ? originalItem.actualArea : item.brandArea,
            actualSampleQuantity: originalItem
              ? originalItem.actualSampleQuantity
              : item.sampleSets,
            brandId: item.brandId,
            brandName: item.brandName
            // shopId: item.shopId,
            // managementModel: originalItem
            //   ? originalItem.managementModel
            //   : selectedItem.managementModel
          }
        })
        const syncKeys = [
          'renovationLiablePerson',
          'renovationPersonPhone',
          'managementModel',
          'contactPerson',
          'contactPersonIdcard',
          'contactPersonPhone',
          'shopRent'
        ]
        syncKeys.forEach(key => {
          if (!this.form[key]) {
            console.log(selectedItem)
            this.form[key] = selectedItem[key]
          }
        })
        this.form.shopBrandList = shopBrandList
      })
    },
    stringJoin(...rest) {
      return rest.filter(item => !!item).join('')
    },
    onPreview(file) {
      window.open(file.url)
    },
    submit() {
      shopDecorateApi.submit(this.form)
    },
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    getCitylist({ page: currPage, getDataListParams: businessSelectedItem }) {
      if (!businessSelectedItem) return []
      return BusinessApi.shoplist({
        currPage,
        pageSize: 100,
        businessLicenseId: businessSelectedItem.id,
        bossId: businessSelectedItem.bossId
      }).then(res => {
        this.authCityCountTotal = res.totalCount
        return res.data
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundCityList(
      { value: authorizedCityCode, getDataListParams: businessSelectedItem },
      callback
    ) {
      if (!businessSelectedItem) {
        return
      }
      callback(
        await BusinessApi.shoplist({
          authorizedCityCode,
          businessLicenseId: (businessSelectedItem || {}).id,
          bossId: (businessSelectedItem || {}).bossId
        }).then(res => res.data)
      )
    },
    async getShopDetail({ getDataListParams: params }) {
      const [bossId, authorizedCityCode] = params.split(',')
      if (!bossId || !authorizedCityCode) return []
      return shopDecorateApi
        .shopBrand({
          bossId,
          ifDisabled: Number(
            this.$refs.flowFormRef.pageInfo.type === 'detail' && this.isOA
          ),
          authorizedCityCode,
          pageSize: 100,
          currPage: 1
        })
        .then(res => res.data)
    },
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await shopDecorateApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }
      if (initFormData) {
        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const {
          optUserId,
          optJobNumber,
          optUserName,
          optDeptId,
          optDeptName,
          ...rest
        } = this.form

        this.form = {
          ...rest,
          optUserId: optUserId || this.userInfo.accountId,
          optJobNumber: optJobNumber || this.userInfo.code,
          optUserName: optUserName || this.userInfo.realName,
          optDeptId: optDeptId || this.userInfo.deptId,
          optDeptName: optDeptName || this.userInfo.deptName
        }
      }
    },
    async onSubmit(type) {
      if (type === 'submit') {
        if (this.isClassic) {
          MessageBox.alert('请走慕思经典门店管理流程')
          return Promise.reject('请走慕思经典门店管理流程')
        }
        this.form.status = 10
        return shopDecorateApi.submit(this.form)
      } else {
        this.form.status = 0
        return shopDecorateApi.submit(this.form)
      }
    }
  }
}
</script>
