export default {
  shopTypeTipsText: `  
  1. KA商场店：在全国连锁的家居、家具城或建材卖场内开的专卖店，如居然之家、月星家居、红星美凯龙、欧亚达、百佳联盟等商场（详见KA商场明细）；

2.当地商场店：除KA商场以外的家居建材商场；

3.独立店：独立于商场、新渠道的沿街开门的门店或在建材城内的门店；

4. 新渠道店：家装/整装公司、商超、百货、家电卖场、购物中心等非常规渠道的门店或者网点；在全国百货卖场和shopping mall开的门店（如：百盛、新世界、王府井、万达、银泰等）；

5. 展示店：以品牌宣传、引流为主要目的，以销售为辅的门店，如：机场、高铁、汽车4S店、KA中厅展示位等。
  `,
  storeNatureTipsText: `  
1. 寝具综合店：一个门店包含大于等于2个寝具系列（在一个空间里，无隔断）

2.沙发综合店：一个门店包含大于等于2个沙发系列（在一个空间里，无隔断）

3.客餐卧综合店：一个门店包含大于等于2个系列，至少含一个寝具系列、一个沙发系列

4. 寝具单系列店：一个门店一个寝具系列（芯睡眠、助眠系列为通配系列，不在单系列计算范围内）

5.沙发单系列店：一个门店一个沙发系列（芯睡眠、助眠系列为通配系列，不在单系列计算范围内）

6.整装店：包含电器、衣柜、橱柜、卫浴等全屋定制产品
  `,
  streetTipsText:`请填写门店所在街道以及所在道路`
}
