<template>
  <div>
    <CommonSelect
      :prop="'directCompany'"
      class="formItem"
      label="直营公司"
      v-model="assessmentfFiling.directCompany"
      type="direct_company"
    ></CommonSelect>
    <DateSelect
      :prop="`closeDate`"
      class="formItem"
      label="预约关店时间"
      v-model="assessmentfFiling.closeDate"
    />
    <CommonSelect
      :prop="`fixedAssets`"
      class="formItem"
      label="固定资产处置情况"
      v-model="assessmentfFiling.fixedAssets"
      type="asset_disposal"
      @change="
        data => {
          if (data !== '0') {
            assessmentfFiling.fixedAssetsDate = ''
          }
        }
      "
    ></CommonSelect>
    <RequiredFormItemInput
      v-show="assessmentfFiling.fixedAssets === '0'"
      delay-update
      class="formItem"
      v-model="assessmentfFiling.fixedAssetsDate"
      label="固定资产截止处置时间"
      :prop="`fixedAssetsDate`"
      :required="assessmentfFiling.fixedAssets === '0' ? true : false"
    />
    <CommonSelect
      :prop="`storeDeposit`"
      class="formItem"
      label="门店押金收回与否"
      v-model="assessmentfFiling.storeDeposit"
      type="flow_common_select_ifEnable"
       @change="
        data => {
          if (data === '0') {
            assessmentfFiling.storeDepositFileList = []
          } else {
            assessmentfFiling.storeDepositDate = ''
          }
        }
      "
    ></CommonSelect>
    <RequiredFormItemInput
      v-if="assessmentfFiling.storeDeposit === '0'"
      delay-update
      class="formItem"
      v-model="assessmentfFiling.storeDepositDate"
      label="门店押金收回截止处置时间"
      :prop="`storeDepositDate`"
      :required="assessmentfFiling.storeDeposit === '0' ? true : false"
    />
    <UploadFile
      v-else-if="assessmentfFiling.storeDeposit === '1'"
      class="formItem"
      label="上传门店押金收回附件"
      v-model="assessmentfFiling.storeDepositFileList"
      :required="assessmentfFiling.storeDeposit === '1' ? true : false"
      @onUploaded="file => handleUpload(file)"
      :prop="`storeDepositFileList`
      "
      :disabled="false"
    >
      <div slot="tip">单文件不能超过400M</div>
    </UploadFile>
    <RequiredFormItemInput
      delay-update
      class="formItem"
      v-model="assessmentfFiling.orderProcessing"
      label="未完结订单处理安排"
      :prop="`orderProcessing`"
      :required="true"
    />
    <CommonSelect
      :prop=" `personPlacement`"
      class="formItem"
      label="人员安置情况"
      v-model="assessmentfFiling.personPlacement"
      type="person_disposal"
    ></CommonSelect>
    <RequiredFormItemInput
      delay-update
      class="formItem"
      v-model="assessmentfFiling.stock"
      label="存货（含饰品）处理情况"
      :prop="`stock`"
      :required="true"
    />
    <RequiredFormItemInput
      delay-update
      class="formItem"
      v-model="assessmentfFiling.centralCode"
      label="关店利润中心编码"
      :prop="`centralCode`"
      :required="true"
    />
    <CommonSelect
      :prop="`businessLicenseCancel`"
      class="formItem"
      label="营业执照注销与否"
      v-model="assessmentfFiling.businessLicenseCancel"
      type="flow_common_select_ifEnable"
      @change="
        data => {
          if (data === '0') {
            assessmentfFiling.businessLicenseFileList = []
          } else {
            assessmentfFiling.businessLicenseDate = ''
          }
        }
      "
    ></CommonSelect>
    <RequiredFormItemInput
      v-if="assessmentfFiling.businessLicenseCancel === '0'"
      delay-update
      class="formItem"
      v-model="assessmentfFiling.businessLicenseDate"
      label="营业执照注销截止处置时间"
      :prop="`businessLicenseDate`"
      :required="assessmentfFiling.businessLicenseCancel === '0' ? true : false"
    />
    <UploadFile
      v-else-if="assessmentfFiling.businessLicenseCancel === '1'"
      class="formItem"
      label="上传营业执照注销附件"
      v-model="assessmentfFiling.businessLicenseFileList"
      :required="assessmentfFiling.businessLicenseCancel === '1' ? true : false"
      @onUploaded="file => handleUpload(file)"
      :prop="`businessLicenseFileList`"
      :disabled="false"
    >
      <div slot="tip">单文件不能超过400M</div>
    </UploadFile>
    <RequiredFormItemInput
      delay-update
      class="formItem"
      v-model="assessmentfFiling.totalLoss"
      label="一次性损失（装修未摊完、人员遣散、存货等）"
      :prop="`totalLoss`"
      :required="true"
    />
    <CommonSelect
      :prop="`storeSettlement`"
      class="formItem"
      label="商场代收款结清与否"
      v-model="assessmentfFiling.storeSettlement"
      type="flow_common_select_ifEnable"
       @change="
        data => {
          if (data !== '0') {
            assessmentfFiling.storeSettlementFileList = []
          }
        }
      "
    ></CommonSelect>
    <UploadFile
      v-show="assessmentfFiling.storeSettlement === '0'"
      class="formItem"
      label="上传商场代收款附件"
      v-model="assessmentfFiling.storeSettlementFileList"
      :required="assessmentfFiling.storeSettlement === '0' ? true : false"
      @onUploaded="file => handleUpload(file)"
      :prop="`storeSettlementFileList`"
      :disabled="false"
    >
      <div slot="tip">单文件不能超过400M</div>
    </UploadFile>
    <RequiredFormItemInput
      delay-update
      class="formItem"
      v-model="assessmentfFiling.situation"
      label="关店理由与情况介绍"
      :prop="`situation`"
      :required="true"
      :inputProps="{
        type: 'textarea',
        resize: 'none',
        rows: 6,
        maxlength: '500',
        showWordLimit: true
      }"
      style="width:100%"
    />
  </div>
</template>

<script>
import CommonSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import DateSelect from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
export default {
  name: 'DirectFlow',
  model: {
    prop: 'value',
    event: 'updateValue'
  },
  props: {
    value: {
      type: Object,
      defeult: {}
    }
  },
  components: {
    CommonSelect,
    DateSelect,
    RequiredFormItemInput,
    UploadFile
  },
  watch: {
    assessmentfFiling: {
      handler(value) {
        this.$emit('updateValue', value)
      },
      deep: true,
      immediate: true
    },
    value: {
      handler(value) {
        this.assessmentfFiling = value
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  data() {
    return {
      /**
       * 注意: 字段名前端自定义 固定不允许改动
       * 新增可添加字段 保持驼峰命名
       */
      assessmentfFiling: {
        // 直营公司
        directCompany: '',
        // 预计关店时间
        closeDate: '',
        // 资产
        fixedAssets: '',
        // 截止时间
        fixedAssetsDate: '',
        // 门店押金是否回收
        storeDeposit: '',
        // 门店押金回收附件
        storeDepositFileList: [],
        // 押金回收时间
        storeDepositDate: '',
        // 押金回收附件
        imageList: [],
        // 订单未处理安排
        orderProcessing: '',
        // 人员情况
        personPlacement: '',
        // 存货处理情况
        stock: '',
        // 关店利润中心编码
        centralCode: '',
        // 营业执照
        businessLicenseCancel: '',
        // 营业执照注销时间
        businessLicenseDate: '',
        // 营业执照注销附件
        businessLicenseFileList: [],
        // 一次性损失
        totalLoss: '',
        // 商城代结清
        storeSettlement: '',
        // 未结清附件
        storeSettlementFileList: [],
        // 关店理由
        situation: ''
      }
    }
  },
  mounted() {},
  methods: {
    handleUpload(file) {

    }
  }
}
</script>

<style scoped lang="scss"></style>
