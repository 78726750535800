<template>
  <div>
    <el-form :model="form" ref="form" v-if="transferShopStatus">
      <header-title title="原经销商门店信息" />
      <el-table
        :data="form.list"
        style="width: 100%; margin-bottom: 22px"
        border
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="shopNumber" label="门店编号"> </el-table-column>
        <el-table-column prop="shopName" label="门店名称"> </el-table-column>
        <el-table-column prop="" label="门店性质">
          <template slot-scope="scope">
            <required-module-form-item-select
              :disabled="true"
              class="formItem"
              v-model="scope.row.storeNature"
              :showLabel="false"
              label="门店性质"
              type="shop_nature_code"
            />
          </template>
        </el-table-column>
        <el-table-column prop="shopType" label="门店类型"> </el-table-column>
        <el-table-column prop="" label="门店地址">
          <template slot-scope="scope">{{
            scope.row | addressFilter
          }}</template>
        </el-table-column>
        <el-table-column prop="shopArea" label="门店面积"> </el-table-column>
        <el-table-column prop="" label="门店代理品牌">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.shopBrandDetailsList"
              :key="index"
            >
              {{ item.brandName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="订单专员">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.shopBrandDetailsList"
              :key="index"
            >
              {{ item.brandName }}:{{ item.orderSpeciaList | brandFilters }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopChange" label="是否转让" min-width="200">
          <template slot-scope="scope">
            <el-form-item
              :prop="`list.${scope.$index}.shopChange`"
              :rules="rules.shopChange"
            >
              <el-select
                placeholder="请选择"
                v-model="scope.row.shopChange"
                @change="changeStoreStatus($event, scope.row.id)"
                :disabled="isOA"
              >
                <el-option
                  v-for="(item, index) in type"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="" label="新门店联系人信息" min-width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.shopChange">
              <required-module-form-item-select
                :disabled="isOA"
                class="formItem"
                v-model="scope.row.managementModel"
                label="经营模式"
                type="management_model"
                :prop="`list.${scope.$index}.managementModel`"
                :rules="rules.managementModel"
              />
              <el-form-item
                v-show="scope.row.managementModel === '20'"
                label="二级分销商联系人姓名"
                :prop="`list.${scope.$index}.contactPerson`"
                :rules="scope.row.managementModel === '20'?rules.contactPerson:[]"
              >
                <el-input
                  v-model="scope.row.contactPerson"
                  placeholder="请输入姓名"
                  :disabled="isOA"
                />
              </el-form-item>
              <el-form-item
                v-show="scope.row.managementModel === '20'"
                label="二级分销商联系人身份证号码"
                :prop="`list.${scope.$index}.contactPersonIdcard`"
                :rules="scope.row.managementModel === '20'?rules.contactPersonIdcard:[]"
              >
                <el-input
                  v-model="scope.row.contactPersonIdcard"
                  placeholder="请输入联系人身份证"
                  :disabled="isOA"
                />
              </el-form-item>
              <el-form-item
                v-show="scope.row.managementModel === '20'"
                label="二级分销商联系人手机号码"
                :prop="`list.${scope.$index}.contactPersonPhone`"
                :rules="scope.row.managementModel === '20'?rules.contactPersonPhone:[]"
              >
                <el-input
                  v-model="scope.row.contactPersonPhone"
                  placeholder="请输入联系方式"
                  :disabled="isOA"
                />
              </el-form-item>
              <el-form-item
                label="门店联系方式"
                :prop="`list.${scope.$index}.shopTel`"
                :rules="rules.shopTel"
              >
                <el-input
                  v-model="scope.row.shopTel"
                  placeholder="请输入门店联系方式"
                  :disabled="isOA"
                />
              </el-form-item>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import * as CommonApi from '@/api/common'
import HeaderTitle from './headerTitle.vue'
import {
  validPhone,
  validName,
  requiredRule,
  validUserId
} from '@/utils/useFormValidator'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
export default {
  components: {
    HeaderTitle,
    RequiredModuleFormItemSelect
  },
  data() {
    return {
      form: {
        list: []
      },
      rules: {
        shopChange: [requiredRule],
        managementModel: [requiredRule],
        contactPerson: [requiredRule,{ validator: validName, trigger: 'blur' }],
        contactPersonIdcard: [requiredRule,{ validator: validUserId, trigger: 'blur' }],
        contactPersonPhone: [requiredRule,{ validator: validPhone, trigger: 'blur' }],
        shopTel: [requiredRule,{ validator: validPhone, trigger: 'blur' }]
      }
    }
  },
  filters: {
    brandFilters(arr) {
      let str = ''
      // console.log('过滤',arr)
      if (arr && arr.length > 0) {
        str = arr
          .map(item => {
            return item.orderSpecialist
          })
          .join('、')
      }
      return str
    },
    addressFilter(obj) {
      let {
        province = '',
        city = '',
        area = '',
        street = '',
        shopTypeValue = '',
        shopFloor = '',
        shopFloorNumber = ''
      } = obj
      return (
        province +
        city +
        area +
        street +
        shopTypeValue +
        shopFloor +
        shopFloorNumber
      )
    }
  },
  watch: {
    shopList: {
      handler(shopList) {
        let list = []
        shopList.map(item => {
          if (!list.includes(item.shopType)) {
            list.push(item.shopType)
          }
        })
        Promise.all(
          list.map(async item => {
            let { code, data } = await CommonApi.getAnyTypeList({
              module: 'distributor',
              type: 'store_type',
              code: item,
              if_delete:-1
            })
            let shopType = ''
            if (code === 0 && data.length) {
              shopType = data[0].content
            }
            return {
              [item]: shopType
            }
          })
        ).then(res => {
          // console.log(res)
          this.form.list = this.shopList.map(item => {
            // console.log('code',item.shopType)
            let i = list.indexOf(item.shopType)
            if (i > -1 && res[i][item.shopType]) {
              // console.log(res[i][item.shopType])
              return {
                ...item,
                shopType: res[i][item.shopType]
              }
            }
          })
          // console.log('最后',this.form.list)
        })
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('data', ['type']),
    ...mapState('dealershipTransfer', [
      'isOA',
      'historicalData',
      'shopList',
      'transferShopStatus'
    ])
  },
  methods: {
    changeStoreStatus(val, id) {
      this.form.list.map((item, index) => {
        if (item.id === id) {
          this.form.list[index].shopChange = val
          this.$set(this.form.list, index, this.form.list[index])
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>