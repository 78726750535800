<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      class="flow-form"
      label-position="top"
      :inline="true">
      <header-title title="目前团队结构" />
      <RequiredFormItemInput
        class="formItem"
        prop="storePersonnelNumber"
        label="门店销售及管理人员（）人"
        type="number"
        :rules="rules.storePersonnelNumber"
        v-model="form.storePersonnelNumber"
        :min="1"
        :disabled="isOA"
        maxlength="4" />
      <RequiredFormItemInput
        class="formItem"
        prop="marketPersonnelNumber"
        label="市场策划及推广（）人"
        type="number"
        :rules="rules.marketPersonnelNumber"
        v-model="form.marketPersonnelNumber"
        :min="1"
        :disabled="isOA"
        maxlength="4" />
      <RequiredFormItemInput
        class="formItem"
        prop="servicePersonnelNumber"
        label="售后服务（）人"
        type="number"
        :rules="rules.servicePersonnelNumber"
        v-model="form.servicePersonnelNumber"
        :min="1"
        :disabled="isOA"
        maxlength="4" />
      <RequiredFormItemInput
        class="formItem"
        prop="logisticsPersonnelNumber"
        label="仓库及物流管理（）人"
        type="number"
        :rules="rules.logisticsPersonnelNumber"
        v-model="form.logisticsPersonnelNumber"
        :min="1"
        :disabled="isOA"
        maxlength="4" />
    </el-form>
  </div>
</template>
<script>
import { RequiredFormItemInput } from '@/components/CustomFormItem'
import { mapState } from 'vuex'
import { checkNumber } from '@/utils/validate'
import headerTitle from './headerTitle.vue'
export default {
  components: { headerTitle, RequiredFormItemInput },
  data() {
    return {
      form: {
        storePersonnelNumber: '', //门店销售及管理人员
        marketPersonnelNumber: '', //市场策划及推广
        servicePersonnelNumber: '', //售后服务
        logisticsPersonnelNumber: '' //仓库及物流管理
      },
      rules: {
        storePersonnelNumber: [{ validator: checkNumber, trigger: ['blur'] }], //门店销售及管理人员
        marketPersonnelNumber: [{ validator: checkNumber, trigger: ['blur'] }], //市场策划及推广
        servicePersonnelNumber: [{ validator: checkNumber, trigger: ['blur'] }], //售后服务
        logisticsPersonnelNumber: [
          { validator: checkNumber, trigger: ['blur'] }
        ] //仓库及物流管理
      }
    }
  },
  computed: {
    ...mapState('dealershipTransfer', ['isOA', 'historicalData'])
  },
  watch: {
    historicalData: {
      handler(historicalData) {
        const dealerTeamStructure =
          historicalData.bossJoinModel.dealerTeamStructure
        for (const key in this.form) {
          if (dealerTeamStructure[key]) {
            this.form[key] = dealerTeamStructure[key]
          }
        }
      },
      deep: true
    }
  }
}
</script>
