<!-- 慕思经典经销权转让申请表 -->
<template>
  <FlowForm
    title="慕思经典经销权转让申请表"
    ref="formRef"
    :model="form"
    :tips="[
      '1、此流程仅适用于慕思经典品牌授权及门店转让',
      '2、如有新渠道授权需一起转让'
    ]"
    :get-detail="initializeForm"
    :on-submit="saveForm">
    <div>
      <RequiredFormItemInput
        class="formItem"
        label="标题"
        :value="form.declarationForm.title"
        disabled
        :required="false" />
      <RequiredFormItemInput
        class="formItem"
        label="单号"
        :value="form.declarationForm.orderNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="招商单号"
        :value="form.declarationForm.zsOrderNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单日期"
        :value="form.declarationForm.optTime"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单人工号"
        :value="form.declarationForm.optJobNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单人"
        :value="form.declarationForm.optUserName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="提单部门"
        :value="form.declarationForm.optDeptName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="售达方编号"
        :value="
          form.declarationForm.status === 40
            ? form.bossJoinModel.dealerBusinessLicense.licenseNumber
            : ''
        "
        disabled
        :required="false"
        placeholder="归档后生成" />
      <RequiredFormItemInput
        class="formItem"
        label="经销商平台账号"
        :value="
          form.declarationForm.status === 40
            ? form.bossJoinModel.dealerBoss.bossNumber
            : ''
        "
        disabled
        :required="false"
        placeholder="归档后生成" />
      <RequiredFormItemInput
        class="formItem"
        label="OA账号"
        :value="
          form.declarationForm.status === 40
            ? form.bossJoinModel.dealerBoss.bossNumber
            : ''
        "
        disabled
        :required="false"
        placeholder="归档后生成" />
      <RequiredFormItemInput
        class="formItem"
        label="最终送达方编号"
        :value="
          form.declarationForm.status === 40
            ? newBusinessLicenseAuthorizedCitySenders
                .map(({ shopBrandNumber }) => shopBrandNumber)
                .join(',')
            : ''
        "
        disabled
        :required="false"
        placeholder="归档后生成" />
    </div>
    <div>
      <HeaderTitle title="转让方信息" />
      <RequiredFormItemSelect
        class="formItem"
        label="售达方"
        v-model="form.oldBusinessLicenseId"
        prop="oldBusinessLicenseId"
        labelKey="licenseNumber"
        valueKey="id"
        :disabled="isOA"
        :selectProps="{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        }"
        :totalCount="totalOldBusinessLicenses"
        :getDataList="fetchOldBusinessLicenses"
        :getNextList="fetchOldBusinessLicenses"
        @onOptionsListNotFound="onOldBusinessLicensesNotFound"
        @change="onOldBusinessLicenseChange">
        <template #default="{ scope }">
          <span>{{ scope.row.licenseNumber }}</span>
          <small>{{ scope.row.businessLicenseName }}</small>
        </template>
      </RequiredFormItemSelect>
      <RequiredFormItemInput
        class="formItem"
        label="原售达方名称"
        :value="selectedOldBusinessLicense?.businessLicenseName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="法人姓名"
        :value="selectedOldBusinessLicense?.realName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="法人联系方式"
        :value="selectedOldBusinessLicense?.contactInfo"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="目前物理店数"
        :value="form.oldLicenseShopNum"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemSelect
        class="formItem"
        label="转让城市"
        v-model="form.authorizedCityCode"
        prop="authorizedCityCode"
        labelKey="authorizedCityName"
        valueKey="authorizedCityCode"
        :disabled="!form.oldBusinessLicenseId || isOA"
        :dataList="oldBusinessLicenseAuthorizedCities"
        @change="onOldBusinessLicenseAuthorizedCityChange" />
      <RequiredFormItemInput
        class="formItem"
        label="市场级别判定"
        :value="form.marketLevelName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="送达方"
        :value="
          oldBusinessLicenseAuthorizedCitySenders
            .map(({ authorizedName }) => authorizedName)
            .join(',')
        "
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="省份"
        :value="form.authorizedCity.province"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="地级市"
        :value="form.authorizedCity.city"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="区/县"
        :value="form.authorizedCity.area"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="代理品牌"
        :value="
          isOA
            ? form.brandSeriesList.map(({ name }) => name).join(',')
            : agencyBrands.map(item => item.name).join(',')
        "
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        label="转让类型"
        v-model="form.changeType"
        prop="changeType"
        labelKey="content"
        valueKey="code"
        :dataList="[
          {
            code: '全部转让',
            content: '全部转让'
          },
          {
            code: '部分转让',
            content: '部分转让'
          }
        ]"
        :disabled="isOA"
        @change="() => (form.brandIdList = agencyBrands.map(v => v.brandId))" />
      <RequiredFormItemInput
        class="formItem"
        v-if="form.changeType === '全部转让'"
        label="转让品牌"
        :value="
          isOA
            ? form.brandSeriesList.map(({ name }) => name).join(',')
            : agencyBrands.map(item => item.name).join(',')
        "
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        v-if="form.changeType === '部分转让'"
        label="转让品牌"
        multiple
        :value="form.brandIdList"
        prop="brandIdList"
        labelKey="name"
        :valueKey="isOA ? 'id' : 'brandId'"
        :dataList="isOA ? form.brandSeriesList : agencyBrands"
        :disabled="isOA"
        @change="
          ids => {
            form.brandIdList = ids
            form.shopList = form.shopList.map(shop => {
              const updateShopBrandList = shop.updateShopBrandList.filter(
                brand =>
                  selectedTransferSeries.findIndex(
                    v => v.id === brand.brandId
                  ) > -1
              )
              return {
                ...shop,
                updateShopBrandList
              }
            })
          }
        " />
      <RequiredFormItemInput
        class="formItem"
        label="营销中心"
        :value="form.marketCenterName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="实控人"
        :value="selectedOldBusinessLicenseBoss?.realName"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="实控人编号"
        :value="selectedOldBusinessLicenseBoss?.bossNumber"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="实控人联系方式"
        :value="selectedOldBusinessLicenseBoss?.contactInfo"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemInput
        class="formItem"
        label="实控人身份证"
        :value="selectedOldBusinessLicenseBoss?.idCard"
        disabled
        :required="false"
        placeholder="自动生成" />
      <RequiredFormItemSelect
        class="formItem"
        label="转让原因"
        v-model="form.changeReason"
        prop="changeReason"
        labelKey="content"
        valueKey="code"
        :dataList="[
          {
            code: '自主放弃',
            content: '自主放弃'
          },
          {
            code: '清理转让',
            content: '清理转让'
          }
        ]"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerJoinCity.permanentPopulationQuantity"
        label="人口（万）"
        v-model="form.bossJoinModel.dealerJoinCity.permanentPopulationQuantity"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerJoinCity.localGdp"
        label="GDP（亿元）"
        v-model="form.bossJoinModel.dealerJoinCity.localGdp"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerJoinCity.perCapitaGdp"
        label="人均GDP（万）"
        v-model="form.bossJoinModel.dealerJoinCity.perCapitaGdp"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerJoinCity.houseAveragePrice"
        label="房价（万/平方米）"
        v-model="form.bossJoinModel.dealerJoinCity.houseAveragePrice"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="reserved.competitor"
        label="主要竞品"
        v-model="form.reserved.competitor"
        :disabled="isOA" />
    </div>
    <div>
      <HeaderTitle title="近三年提货额及完成率" />
      <el-table :data="[{}]" border>
        <el-table-column
          v-for="(year, idx) in recentYears"
          :key="year"
          :label="`${year}年`">
          <template>
            <div class="flex flex-items-center">
              <span style="color: #ff4949; margin-right: 4px">*</span>
              <span>提货额：</span>
              <RequiredFormItemInput
                class="flex-1"
                style="padding: 4px 0"
                :prop="`reserved.recentYearPickupAmount[${idx}]`"
                :label="`${year}年提货额`"
                :show-label="false"
                v-model="form.reserved.recentYearPickupAmount[idx]"
                :disabled="isOA" />
              <el-divider />
            </div>
            <div class="flex flex-items-center">
              <span style="color: #ff4949; margin-right: 4px">*</span>
              <span>完成率：</span>
              <RequiredFormItemInput
                class="flex-1"
                style="padding: 4px 0"
                :prop="`reserved.recentYearCompletionRate[${idx}]`"
                :label="`${year}年完成率`"
                :show-label="false"
                v-model="form.reserved.recentYearCompletionRate[idx]"
                :disabled="isOA" />
              <el-divider />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <HeaderTitle title="承接方营业执照信息" />
      <RequiredFormItemInput
        class="formItem"
        delay-update
        prop="bossJoinModel.dealerBusinessLicense.creditCode"
        :validations="['credit_code']"
        label="统一社会信用代码"
        strong
        :value="form.bossJoinModel.dealerBusinessLicense.creditCode"
        @blur="evt => onReceiverCreditCodeBlur(evt.target.value)"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessLicenseName"
        label="营业执照名称"
        v-model="form.bossJoinModel.dealerBusinessLicense.businessLicenseName"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessLicenseType"
        label="营业执照类型"
        v-model="form.bossJoinModel.dealerBusinessLicense.businessLicenseType"
        labelKey="content"
        valueKey="code"
        :dataList="moduleData.business_license_type"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.registeredCapital"
        label="注册资本（万元）"
        v-model="form.bossJoinModel.dealerBusinessLicense.registeredCapital"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.legalPersonName"
        label="法人"
        v-model="form.bossJoinModel.dealerBusinessLicense.legalPersonName"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.legalPersonContact"
        label="法人联系方式"
        v-model="form.bossJoinModel.dealerBusinessLicense.legalPersonContact"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        :validations="['phone']" />
      <requiredFormItemDatePicker
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.registerDate"
        label="注册日期"
        v-model="form.bossJoinModel.dealerBusinessLicense.registerDate"
        :datePickerProps="{ type: 'datetime' }"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessTerm"
        label="营业期限"
        v-model="form.bossJoinModel.dealerBusinessLicense.businessTerm"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessLicenseAddress"
        label="营业执照地址（住所）"
        v-model="
          form.bossJoinModel.dealerBusinessLicense.businessLicenseAddress
        "
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessScope"
        label="经营范围"
        v-model="form.bossJoinModel.dealerBusinessLicense.businessScope"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.registrationAuthority"
        label="登记机关"
        v-model="form.bossJoinModel.dealerBusinessLicense.registrationAuthority"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.certificatesRecAddress"
        label="函证接收地址"
        v-model="
          form.bossJoinModel.dealerBusinessLicense.certificatesRecAddress
        "
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <div>
        <RequiredFormItemUploadFile
          class="formItem"
          prop="bossJoinModel.businessLicenseFile"
          label="上传附件"
          v-model="form.bossJoinModel.businessLicenseFile"
          :limit="1"
          limit-size="400"
          :disabled="isOA"
          @changeValue="onBusinessLicenseFileChange">
          <div slot="describe">
            <p style="color: red">先上传营业执照可智能识别出营业执照信息。</p>
            （营业执照照片/电子档）
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
    </div>
    <div>
      <HeaderTitle title="承接方开票信息" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessLicenseName"
        label="账户名称"
        v-model="form.bossJoinModel.dealerBusinessLicense.businessLicenseName"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.creditCode"
        label="纳税人识别号"
        v-model="form.bossJoinModel.dealerBusinessLicense.creditCode"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.businessLicenseAddress"
        label="营业执照地址"
        v-model="
          form.bossJoinModel.dealerBusinessLicense.businessLicenseAddress
        "
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.taxpayerPhone"
        label="纳税人电话"
        v-model="form.bossJoinModel.dealerBusinessLicense.taxpayerPhone"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredBankFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.bank"
        delay-update
        label="开户行"
        v-model="form.bossJoinModel.dealerBusinessLicense.bank"
        @changeSelectedItem="
          v =>
            (form.bossJoinModel.dealerBusinessLicense.bankCode = v
              ? v.CODE
              : '')
        "
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.bankAccount"
        label="银行账号"
        v-model="form.bossJoinModel.dealerBusinessLicense.bankAccount"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.payTaxesType"
        label="纳税类型"
        v-model="form.bossJoinModel.dealerBusinessLicense.payTaxesType"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        labelKey="content"
        valueKey="code"
        :dataList="moduleData.pay_taxes_type" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.billType"
        label="发票类型"
        v-model="form.bossJoinModel.dealerBusinessLicense.billType"
        module="public"
        type="invoice_type"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.personLiableName"
        label="税务负责人"
        v-model="form.bossJoinModel.dealerBusinessLicense.personLiableName"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.personLiablePhone"
        label="税务负责人电话"
        v-model="form.bossJoinModel.dealerBusinessLicense.personLiablePhone"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        :validations="['phone']" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBusinessLicense.eMail"
        label="接收电子发票邮箱"
        :validations="['email']"
        v-model="form.bossJoinModel.dealerBusinessLicense.eMail"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <div>
        <RequiredFormItemUploadFile
          style="width: inherit"
          class="formItem"
          prop="bossJoinModel.taxpayerProveFile"
          label="上传附件"
          v-model="form.bossJoinModel.taxpayerProveFile"
          limit-size="400"
          :limit="1"
          :disabled="isOA">
          <div slot="describe">
            (1：一般纳税人登记证 或2：税务登记事项通知书或
            3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          style="width: inherit"
          class="formItem"
          prop="bossJoinModel.bankFile"
          label="上传附件"
          v-model="form.bossJoinModel.bankFile"
          limit-size="400"
          :limit="1">
          <div slot="describe">开户证明或者网银截图(能显示开户银行全称）</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
    </div>
    <div>
      <HeaderTitle title="承接方信息" />
      <RequiredFormItemInput
        class="formItem"
        delay-update
        prop="bossJoinModel.dealerBoss.idCard"
        label="身份证"
        v-model="form.bossJoinModel.dealerBoss.idCard"
        :validations="['user_id']"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        @blur="evt => onDealerBossIdCardBlur(evt.target.value)" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBoss.realName"
        label="实际控股人"
        v-model="form.bossJoinModel.dealerBoss.realName"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBoss.contactInfo"
        label="实际控股人联系方式"
        v-model="form.bossJoinModel.dealerBoss.contactInfo"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBoss.education"
        label="学历"
        v-model="form.bossJoinModel.dealerBoss.education"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        labelKey="content"
        valueKey="code"
        :dataList="moduleData.education" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBoss.age"
        label="年龄"
        v-model="form.bossJoinModel.dealerBoss.age"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBoss.maritalStatus"
        label="婚姻状况"
        v-model="form.bossJoinModel.dealerBoss.maritalStatus"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        labelKey="content"
        valueKey="code"
        :dataList="[
          { code: 1, content: '已婚' },
          { code: 2, content: '未婚' }
        ]" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.dealerBoss.nativePlace"
        label="籍贯"
        v-model="form.bossJoinModel.dealerBoss.nativePlace"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        " />
      <RequiredFormItemSelect
        class="formItem"
        prop="bossJoinModel.dealerBoss.sex"
        label="性别"
        v-model="form.bossJoinModel.dealerBoss.sex"
        :disabled="
          !isReceiverBusinessLicenseValid || !!receiverBusinessLicense || isOA
        "
        labelKey="content"
        valueKey="code"
        :dataList="[
          { code: 1, content: '男' },
          { code: 2, content: '女' }
        ]" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="bossJoinModel.channelType"
        label="经销商类型"
        v-model="form.bossJoinModel.channelType"
        :disabled="!isReceiverBusinessLicenseValid || isOA"
        type="dealer_type"
        network />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="bossJoinModel.secondaryChannelType"
        label="经销商类型二级分类"
        v-model="form.bossJoinModel.secondaryChannelType"
        type="dealer_sub_type"
        :disabled="!isReceiverBusinessLicenseValid || isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.contractorShopQuantity"
        label="物理店数"
        v-model="form.bossJoinModel.contractorShopQuantity"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        v-model="form.bossJoinModel.joinNature"
        class="formItem"
        prop="bossJoinModel.joinNature"
        label="经销商性质"
        type="join_nature"
        :disabled="isOA" />
      <RequiredFormItemSelect
        class="formItem"
        label="是否新增系列"
        v-model="form.ifAddBrandSeries"
        prop="ifAddBrandSeries"
        labelKey="content"
        valueKey="code"
        :dataList="[
          {
            code: '是',
            content: '是'
          },
          {
            code: '否',
            content: '否'
          }
        ]"
        @change="
          () => {
            form.newAddBrandIdList = []
            form.shopList = form.shopList.map(shop => {
              const updateShopBrandList = shop.updateShopBrandList.filter(
                brand =>
                  selectedTransferSeries.findIndex(
                    v => v.id === brand.brandId
                  ) > -1
              )
              return {
                ...shop,
                updateShopBrandList
              }
            })
          }
        "
        :disabled="isOA" />
      <RequiredFormItemSelect
        v-if="form.ifAddBrandSeries === '是'"
        class="formItem"
        label="新增系列选择"
        v-model="form.newAddBrandIdList"
        multiple
        prop="newAddBrandIdList"
        labelKey="name"
        valueKey="id"
        :dataList="addableBrandSeries"
        :disabled="isOA"
        @change="
          ids => {
            form.shopList = form.shopList.map(shop => {
              const updateShopBrandList = shop.updateShopBrandList.filter(
                brand => ids.findIndex(id => id === brand.brandId) > -1
              )
              return {
                ...shop,
                updateShopBrandList
              }
            })
          }
        " />
      <RequiredFormItemInput
        class="formItem"
        label="转接系列"
        :value="selectedTransferSeries.map(({ name }) => name).join(',')"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.contractorPlanShop"
        label="计划做慕思门店地址"
        v-model="form.bossJoinModel.contractorPlanShop"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.contractorSalesSituation"
        label="现经营品牌年销售额（万）"
        v-model="form.bossJoinModel.contractorSalesSituation"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.contractorBankStatement"
        label="银行流水/余额（万）"
        v-model="form.bossJoinModel.contractorBankStatement"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="bossJoinModel.contractorTeamQuantity"
        label="团队人数"
        v-model="form.bossJoinModel.contractorTeamQuantity"
        :disabled="isOA" />
      <RequiredFormItemSelect
        class="formItem"
        prop="bossJoinModel.agentTypeName"
        label="代理模式"
        v-model="form.bossJoinModel.agentTypeName"
        labelKey="content"
        valueKey="code"
        :dataList="[
          {
            code: '独资代理',
            content: '独资代理'
          },
          {
            code: '合作代理',
            content: '合作代理'
          }
        ]"
        :disabled="isOA"
        @change="
          v => {
            if (v === '独资代理') {
              form.bossJoinModel.shareholdingRatio = ''
              form.bossJoinModel.partnerNumber = ''
            }
          }
        " />
      <RequiredFormItemInput
        class="formItem"
        v-if="form.bossJoinModel.agentTypeName === '合作代理'"
        prop="bossJoinModel.shareholdingRatio"
        label="持股比例"
        v-model="form.bossJoinModel.shareholdingRatio"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        v-if="form.bossJoinModel.agentTypeName === '合作代理'"
        prop="bossJoinModel.partnerNumber"
        label="合作人数"
        v-model="form.bossJoinModel.partnerNumber"
        :disabled="isOA" />
    </div>
    <div>
      <HeaderTitle title="货物及快递收货信息" />
      <RequiredFormItemInput
        class="formItem"
        prop="warehouse.warehouseUser"
        label="仓库收货人"
        v-model="form.warehouse.warehouseUser"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="warehouse.warehousePhone"
        label="联系方式"
        v-model="form.warehouse.warehousePhone"
        :disabled="isOA"
        :validations="['phone']" />
      <RequiredFormItemInput
        class="formItem"
        prop="warehouse.warehouseAddress"
        label="仓库收货地址"
        v-model="form.warehouse.warehouseAddress"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="warehouse.deliveryAddress"
        label="运输目的地"
        v-model="form.warehouse.deliveryAddress"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="warehouse.deliveryType"
        label="运输方式"
        v-model="form.warehouse.deliveryType"
        type="delivery_type"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="warehouse.transportType"
        label="运输条件"
        v-model="form.warehouse.transportType"
        type="transport_type"
        :disabled="isOA" />
    </div>
    <div>
      <HeaderTitle title="品牌保证金收取" />
      <el-table :data="[{}]" border>
        <el-table-column label="类型">
          <template #default>
            <RequiredModuleFormItemSelect
              class="formItem"
              :show-label="false"
              label="类型"
              v-model="form.bossJoinModel.dealerMarginRecordList[0].type"
              disabled
              type="payment_type_code" />
          </template>
        </el-table-column>
        <el-table-column label="金额（万）">
          <template #default>
            <RequiredFormItemInput
              class="formItem"
              label="金额（万）"
              :value="
                form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount
              "
              :min="0"
              type="number"
              :show-label="false"
              disabled
              :required="false" />
          </template>
        </el-table-column>
        <el-table-column label="汇款日期">
          <template #default>
            <requiredFormItemDatePicker
              class="formItem"
              :showLabel="false"
              prop="bossJoinModel.dealerMarginRecordList[0].remittanceDate"
              label="汇款日期"
              v-model="
                form.bossJoinModel.dealerMarginRecordList[0].remittanceDate
              "
              :disabled="
                form.bossJoinModel.dealerMarginRecordList[0]
                  .remittanceAmount === 0 || isOA
              " />
          </template>
        </el-table-column>
        <el-table-column label="汇款账号">
          <RequiredFormItemInput
            prop="bossJoinModel.dealerMarginRecordList[0].accountName"
            :showLabel="false"
            label="开户名"
            v-model="form.bossJoinModel.dealerMarginRecordList[0].accountName"
            :disabled="
              form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount ===
                0 || isOA
            " />
          <el-divider />
          <RequiredFormItemInput
            prop="bossJoinModel.dealerMarginRecordList[0].account"
            :show-label="false"
            label="账号"
            v-model="form.bossJoinModel.dealerMarginRecordList[0].account"
            :disabled="
              form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount ===
                0 || isOA
            " />
          <el-divider />
          <RequiredBankFormItemSelect
            prop="bossJoinModel.dealerMarginRecordList[0].bank"
            :show-label="false"
            label="开户行"
            v-model="form.bossJoinModel.dealerMarginRecordList[0].bank"
            :disabled="
              form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount ===
                0 || isOA
            " />
        </el-table-column>
        <el-table-column label="款条">
          <RequiredFormItemUploadFile
            v-if="
              form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount > 0
            "
            class="formItem"
            prop="bossJoinModel.dealerMarginRecordList[0].fileRecordList"
            limit-size="400"
            v-model="
              form.bossJoinModel.dealerMarginRecordList[0].fileRecordList
            "
            auto-upload
            :disabled="isOA">
            <div slot="tip">单个文件不能超过400M</div>
          </RequiredFormItemUploadFile>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <HeaderTitle title="转让事项确认" />
      <el-table style="width: 100%" :data="[{}]" border>
        <el-table-column label="未出订单接收人">
          <template #default>
            <RequiredModuleFormItemSelect
              prop="orderRecipient"
              label="未出订单接收人"
              v-model="form.orderRecipient"
              :show-label="false"
              type="dealer_type"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="售后问题处理">
          <template #default>
            <RequiredModuleFormItemSelect
              prop="afterSales"
              label="未出订单接收人"
              :show-label="false"
              type="dealer_type"
              v-model="form.afterSales"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="之前转单信息">
          <template #default>
            <RequiredModuleFormItemSelect
              prop="previousTransferInformation"
              label="之前转单信息"
              :show-label="false"
              type="dealer_type"
              v-model="form.previousTransferInformation"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="门店是否转让（新经销商是否接老店）">
          <template #default>
            <RequiredFormItemSelect
              prop="shopChange"
              label="门店转让"
              :show-label="false"
              v-model="form.shopChange"
              @change="
                status => {
                  if (!status) {
                    form.shopList = []
                    form.oldShopList = []
                    transferShops = []
                    return
                  }
                  fetchTransferShops(
                    form.oldBusinessLicenseId,
                    form.authorizedCityCode
                  )
                }
              "
              labelKey="label"
              valueKey="value"
              :dataList="[
                {
                  label: '是',
                  value: true
                },
                {
                  label: '否',
                  value: false
                }
              ]"
              :disabled="
                isOA || !form.oldBusinessLicenseId || !form.authorizedCityCode
              " />
          </template>
        </el-table-column>
        <el-table-column label="公司账务负责人">
          <template #default>
            <RequiredModuleFormItemSelect
              prop="bookkeeper"
              label="公司账务负责人"
              :show-label="false"
              type="dealer_type"
              v-model="form.bookkeeper"
              :disabled="isOA" />
          </template>
        </el-table-column>
      </el-table>
      <el-table
        style="width: 100%; margin: 12px 0 32px"
        :data="isOA ? form.oldShopList : transferShops"
        border>
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="shopNumber" label="门店编号" />
        <el-table-column prop="shopName" label="门店名称" />
        <el-table-column
          prop="storeNature"
          label="门店性质"
          :formatter="
            row => transferShopProperties.get(String(row.storeNature))
          " />
        <el-table-column
          prop="shopType"
          label="门店类型"
          :formatter="row => transferShopTypes.get(row.shopType)" />
        <el-table-column
          label="门店地址"
          :formatter="
            row =>
              [
                row.province,
                row.city,
                row.area,
                row.street,
                row.shopTypeValue,
                row.shopFloor,
                row.shopFloorNumber
              ]
                .filter(Boolean)
                .join('')
          " />
        <el-table-column prop="shopArea" label="门店面积" />
        <el-table-column
          label="门店代理品牌"
          :formatter="
            ({ oldShopBrandList, shopBrandDetailsList }) =>
              isOA
                ? oldShopBrandList?.map(v => v.name)?.join('，')
                : shopBrandDetailsList?.map(v => v.brandName)?.join('，')
          " />
        <el-table-column
          label="订单专员"
          v-if="!isOA"
          :formatter="
            ({ shopBrandDetailsList }) =>
              shopBrandDetailsList
                ?.map(v =>
                  v.orderSpeciaList.length > 0
                    ? `${v.brandName}:${v.orderSpeciaList
                        .map(v => v.orderSpecialist)
                        .join('、')}`
                    : v.brandName
                )
                ?.join('，')
          " />
        <el-table-column label="是否转让" v-if="!isOA" width="80">
          <template #default="{ row }">
            <el-switch
              :value="form.shopList.findIndex(v => v.id === row.id) > -1"
              @change="
                status => {
                  if (status) {
                    form.shopList.push({ ...row, updateShopBrandList: [] })
                    form.oldShopList.push(row)
                  } else {
                    form.shopList = form.shopList.filter(v => v.id !== row.id)
                    form.oldShopList = form.oldShopList.filter(
                      v => v.id !== row.id
                    )
                  }
                }
              "
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="确认门店系列" width="200">
          <template #default="{ row }">
            <RequiredFormItemSelect
              class="formItem"
              v-if="form.shopList.findIndex(v => v.id === row.id) > -1"
              :value="
                form.shopList
                  .find(v => v.id === row.id)
                  .updateShopBrandList.map(v => v.brandId)
              "
              multiple
              labelKey="name"
              valueKey="id"
              :dataList="shopSeries"
              :disabled="isOA"
              @change="
                ids => {
                  const series = ids.map(id => {
                    const brand = allBrandSeries.find(v => v.id === id)
                    return {
                      brandArea: 0,
                      sampleSets: 0,
                      brandId: brand.id,
                      name: brand.name,
                      code: brand.code,
                      ifEnabled: 0,
                      orderSpeciaList: []
                    }
                  })
                  form.shopList.find(v => v.id === row.id).updateShopBrandList =
                    series
                }
              " />
          </template>
        </el-table-column>
        <el-table-column label="新门店联系人信息" min-width="220">
          <template #default="{ row, $index }">
            <div v-if="form.shopList.findIndex(v => v.id === row.id) > -1">
              <RequiredModuleFormItemSelect
                label="经营模式"
                v-model="
                  form.shopList.find(v => v.id === row.id).managementModel
                "
                type="management_model"
                :disabled="isOA" />
              <template
                v-if="
                  form.shopList.find(v => v.id === row.id).managementModel ===
                  '20'
                ">
                <RequiredFormItemInput
                  label="二级分销商联系人姓名"
                  v-model="
                    form.shopList.find(v => v.id === row.id).contactPerson
                  "
                  :disabled="isOA"
                  :validations="['name']" />
                <RequiredFormItemInput
                  label="二级分销商联系人身份证号码"
                  v-model="
                    form.shopList.find(v => v.id === row.id).contactPersonIdcard
                  "
                  :disabled="isOA"
                  :validations="['user_id']" />
                <RequiredFormItemInput
                  label="二级分销商联系人手机号码"
                  v-model="
                    form.shopList.find(v => v.id === row.id).contactPersonPhone
                  "
                  :disabled="isOA"
                  :validations="['phone']" />
              </template>
              <RequiredFormItemInput
                label="门店联系方式"
                v-model="form.shopList.find(v => v.id === row.id).shopTel"
                :disabled="isOA"
                :validations="['phone']" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-bottom: 10px">
      <RequiredFormItemUploadFile
        class="formItem"
        v-for="field in uploadFileFields"
        :prop="field.value"
        :key="field.label"
        limit-size="400"
        :label="field.label"
        v-model="form[field.value]"
        :required="field.required"
        :disabled="isOA">
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </div>
    <RequiredFormItemInput
      class="formItem"
      style="width: 100%"
      :required="false"
      v-model="form.declarationForm.remark"
      label="备注"
      type="textarea"
      :rows="6"
      :disabled="isOA" />
  </FlowForm>
</template>

<script setup>
import { computed, reactive, ref, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import moment from 'moment'
import store from '@/store'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import requiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import FlowForm from './components/flowForm.vue'
import HeaderTitle from './components/headerTitle.vue'
import RemarkItem from './components/remarkItem.vue'
import * as businessApi from '@/api/attractPlatform/business'
import * as commonApi from '@/api/common'
import {
  getMasterDataList,
  getBusinessDataList,
  getBrandseriesList
} from '@/api/businessOos'
import moduleData from '@/components/CustomFormItem/data/module.data'
import { creditCodeReg } from '@/utils/useFormValidator'
import {
  getClassicDealershipTransfer,
  saveClassicDealershipTransfer,
  getBusinessLicenseShopCount
} from '@/api/flow/classicDealershipTransfer'
import { getBossByIdCard } from '@/api/attractPlatform/classicDealerJoinApi'
import { ocrBizlicense } from '@/api/flow/index'
import { Message } from 'element-ui'

const route = useRoute()

const CLASSIC_BRAND_ID = '1390119720428154881'
const CLASSIC_BRAND_CODE = 'C'

const user = computed(() => store.state.user.userInfo)
const isOA = computed(() => store.state.dealershipTransfer.isOA)

const recentYears = (() => {
  const out = []
  const date = new Date()
  const year = date.getFullYear()
  out.push(year - 3, year - 2, year - 1)
  return out
})()

const formRef = ref()
const form = reactive({
  declarationForm: {
    id: '',
    zsOrderNumber: '',
    /**流程编号 */
    processCode: '',
    title: '经销权转让申请表（授权城市，经销商）',
    /**单号 */
    orderNumber: '',
    optUserId: '',
    optUserName: '',
    optJobNumber: '',
    optDeptId: '',
    optDeptName: '',
    optTime: '',
    status: null,
    remark: ''
  },
  oldBusinessLicenseId: '',
  /**旧售达方门店数量 */
  oldLicenseShopNum: '',
  newBusinessLicenseId: '',
  /**未出订单接收人 */
  orderRecipient: '',
  /**售后问题处理 */
  afterSales: '',
  /**之前转单信息 */
  previousTransferInformation: '',
  /**是否转让 */
  shopChange: false,
  /**公司账务负责人 */
  bookkeeper: '',
  /**门店类型 */
  storeType: '',
  /**城市级别 */
  marketLevelCode: '',
  marketLevelName: '',
  /**城市级别类型 */
  marketLevelTypeCode: '',
  marketLevelTypeName: '',
  /**服务中心 */
  marketCenterCode: '',
  marketCenterName: '',
  /**原售达方授权城市编号 */
  authorizedCityCode: '',
  /**原售达方授权城市 */
  authorizedCity: {
    authorizedCityCode: '',
    authorizedCityName: '',
    countryCode: '',
    province: '',
    provinceCode: '',
    city: '',
    cityCode: '',
    area: '',
    areaCode: ''
  },
  /**转让品牌列表 */
  brandIdList: [],
  brandSeriesList: [],
  /**转让门店列表 */
  shopList: [],
  oldShopList: [],
  reserved: {
    /**主要竞品 */
    competitor: '',
    /**近年提货额 */
    recentYearPickupAmount: ['', '', ''],
    /**近年完成率 */
    recentYearCompletionRate: ['', '', '']
  },
  /**转让原因 */
  changeReason: '',
  /**转让类型 */
  changeType: '全部转让',
  /**是否新增系列 */
  ifAddBrandSeries: '否',
  /**新增系列列表 */
  newAddBrandIdList: [],
  /**承接方 */
  bossJoinModel: {
    declarationFormId: '',
    /**经销商分类 */
    channelType: '',
    /**经销商二级分类 */
    secondaryChannelType: '',
    /**经营性质 */
    joinNature: '',
    /**代理模式 */
    agentTypeName: '',
    /**持股比例 */
    shareholdingRatio: '',
    /**合作人数 */
    partnerNumber: '',
    /**门店数 */
    contractorShopQuantity: '',
    /**门店地址 */
    contractorPlanShop: '',
    /**年销售额 */
    contractorSalesSituation: '',
    /**银行流水 */
    contractorBankStatement: '',
    /**团队人数 */
    contractorTeamQuantity: '',
    /**营业执照 */
    dealerBusinessLicense: {
      creditCode: '',
      licenseNumber: '',
      businessLicenseName: '',
      businessLicenseType: '',
      /**注册资本 */
      registeredCapital: '',
      /**法人 */
      legalPersonName: '',
      /**法人联系方式 */
      legalPersonContact: '',
      registerDate: '',
      /**营业期限 */
      businessTerm: '',
      businessLicenseAddress: '',
      /**经营范围 */
      businessScope: '',
      /**登记机关 */
      registrationAuthority: '',
      /**函证接收地址 */
      certificatesRecAddress: '',
      /**纳税人电话 */
      taxpayerPhone: '',
      bank: '',
      bankCode: '',
      bankAccount: '',
      /**纳税类型 */
      payTaxesType: '',
      /**发票类型 */
      billType: '',
      /**税务负责人 */
      personLiableName: '',
      /**税务负责人电话 */
      personLiablePhone: '',
      eMail: ''
    },
    dealerBoss: {
      bossNumber: '',
      idCard: '',
      realName: '',
      contactInfo: '',
      education: '',
      age: '',
      maritalStatus: '',
      /**籍贯 */
      nativePlace: '',
      sex: ''
    },
    dealerJoinCity: {
      /**人口 */
      permanentPopulationQuantity: '',
      /** GDP */
      localGdp: '',
      /**人均GDP */
      perCapitaGdp: '',
      /**房价 */
      houseAveragePrice: ''
    },
    /**品牌保证金 */
    dealerMarginRecordList: [
      {
        /**保证金类型 */
        type: '2',
        remittanceAmount: 0,
        remittanceDate: '',
        accountName: '',
        account: '',
        bank: '',
        fileRecordList: []
      }
    ],
    /**营业执照文件 */
    businessLicenseFile: [],
    /**一般纳税人登记证 */
    taxpayerProveFile: [],
    /** 开户证明*/
    bankFile: []
  },
  /**收货信息 */
  warehouse: {
    /**仓库收货人 */
    warehouseUser: '',
    /**仓库联系方式 */
    warehousePhone: '',
    /**仓库收货地址 */
    warehouseAddress: '',
    /**运输目的地 */
    deliveryAddress: '',
    /**运输方式 */
    deliveryType: '',
    /**运输条件 */
    transportType: ''
  },
  file01List: [],
  file02List: [],
  file03List: [],
  file04List: [],
  file05List: [],
  file06List: [],
  file07List: [],
  file08List: [],
  file09List: [],
  file10List: [],
  file11List: [],
  file12List: [],
  file13List: [],
  file14List: [],
  file15List: [],
  file16List: [],
  file17List: [],
  file18List: [],
  file19List: [],
  file20List: []
})
async function initializeForm() {
  if (!route.query.id) {
    form.declarationForm.optUserId = user.value.accountId
    form.declarationForm.optUserName = user.value.realName
    form.declarationForm.optJobNumber = user.value.code
    form.declarationForm.optDeptId = user.value.deptId
    form.declarationForm.optDeptName = user.value.deptName
    return
  }
  const res = await getClassicDealershipTransfer(route.query.id)
  Object.keys(form.declarationForm).forEach(
    key => (form.declarationForm[key] = res[key])
  )
  const data = JSON.parse(res.historicalData)
  data.reserved = JSON.parse(data.reserved)
  data.bossJoinModel.businessLicenseFile = [
    data.bossJoinModel.businessLicenseFile
  ].filter(Boolean)
  data.bossJoinModel.taxpayerProveFile = [
    data.bossJoinModel.taxpayerProveFile
  ].filter(Boolean)
  data.bossJoinModel.bankFile = [data.bossJoinModel.bankFile].filter(Boolean)
  if (isOA.value) {
    const codes = [
      ...new Set(data.shopList.map(({ shopType }) => shopType))
    ].filter(code => !transferShopTypes.has(code))
    await Promise.all([
      fetchTransferShopTypes(codes),
      fetchTransferShopProperties()
    ])
  } else if (
    data.oldBusinessLicenseId &&
    data.authorizedCityCode &&
    data.shopChange
  ) {
    await fetchTransferShops(data.oldBusinessLicenseId, data.authorizedCityCode)
  }
  Object.keys(data).forEach(key => {
    if (key === 'declarationForm') return
    form[key] = data[key]
  })
  if (form.oldBusinessLicenseId && form.authorizedCityCode) {
    await fetchAgencyBrands(form.oldBusinessLicenseId, form.authorizedCityCode)
  }
  if (form.brandIdList.length === 0) {
    form.brandIdList = agencyBrands.value.map(({ brandId }) => brandId)
  }
  if (form.declarationForm.status === 40) {
    const isBossEmpty =
      !form.bossJoinModel.dealerBusinessLicense.licenseNumber ||
      !form.bossJoinModel.dealerBoss.bossNumber
    if (isBossEmpty) {
      const {
        data: [dealer]
      } = await getBusinessDataList({
        creditCode: form.bossJoinModel.dealerBusinessLicense.creditCode
      })
      if (dealer) {
        form.bossJoinModel.dealerBusinessLicense.licenseNumber =
          dealer.licenseNumber
        form.bossJoinModel.dealerBoss.bossNumber = dealer.bossNumber
      }
    }
    const { data: senders } = await getMasterDataList({
      businessLicenseId: form.newBusinessLicenseId,
      authorizedCityCode: form.authorizedCityCode,
      brandId: CLASSIC_BRAND_ID
    })
    newBusinessLicenseAuthorizedCitySenders.value = senders
  }
}
async function saveForm(type) {
  const status = {
    save: 0,
    submit: 10
  }[type]
  const now = moment().format('YYYY-MM-DD HH:mm:ss')
  const title = (() => {
    const authorizedCity = [
      form.authorizedCity.province,
      form.authorizedCity.city,
      form.authorizedCity.area
    ]
      .filter(Boolean)
      .join('')
    const OldBusinessLicenseRealName =
      selectedOldBusinessLicense.value?.realName
    const transferBrand = selectedTransferSeries.value
      .map(({ name }) => name)
      .join(',')
    const content = [
      authorizedCity,
      OldBusinessLicenseRealName,
      transferBrand ? `转让品牌：${transferBrand}` : ''
    ]
      .filter(Boolean)
      .join('，')
    return ['慕思经典经销权转让申请表（', content, '）'].join('')
  })()
  const payload = {
    ...form,
    declarationForm: {
      ...form.declarationForm,
      title,
      status,
      optTime: now
    },
    reserved: JSON.stringify(form.reserved),
    bossJoinModel: {
      ...form.bossJoinModel,
      businessLicenseFile: form.bossJoinModel.businessLicenseFile[0],
      taxpayerProveFile: form.bossJoinModel.taxpayerProveFile[0],
      bankFile: form.bossJoinModel.bankFile[0]
    },
    shopList: form.shopList
      .filter(v => transferShops.value.findIndex(({ id }) => id === v.id) > -1)
      .map(v => {
        const { shopBrandDetailsList } = transferShops.value.find(
          ({ id }) => id === v.id
        )
        return {
          ...v,
          oldShopBrandList: shopBrandDetailsList
        }
      })
  }
  return saveClassicDealershipTransfer(payload)
}

const {
  oldBusinessLicenses,
  totalOldBusinessLicenses,
  fetchOldBusinessLicenses
} = useOldBusinessLicenses()
function onOldBusinessLicenseChange(v) {
  form.authorizedCityCode = ''
  Object.keys(form.authorizedCity).forEach(
    key => (form.authorizedCity[key] = '')
  )
  oldBusinessLicenseAuthorizedCities.value = []
  agencyBrands.value = []
  const item = oldBusinessLicenses.value.find(item => item.id === v)
  if (item) {
    selectedOldBusinessLicense.value = item
  }
  form.shopList = []
  form.shopChange = false
  fetchOldBusinessLicenseShopCount(v)
}
async function onOldBusinessLicensesNotFound({ value }, callback) {
  const res = await fetchOldBusinessLicenses({
    businessLicenseId: value
  })
  if (res.length === 0) return
  selectedOldBusinessLicense.value = res[0]
  callback(res)
}
async function fetchOldBusinessLicenseShopCount(businessLicenseId) {
  const res = await getBusinessLicenseShopCount({
    businessLicenseId
  })
  form.oldLicenseShopNum = res
}
function useOldBusinessLicenses() {
  const oldBusinessLicenses = ref([])
  const totalOldBusinessLicenses = ref(0)
  async function fetchOldBusinessLicenses(params) {
    const res = await businessApi.license({
      currPage: params.page,
      key: params.searchText,
      businessLicenseId: params.businessLicenseId
    })
    if (params.page === 1) {
      oldBusinessLicenses.value = res.data
    } else {
      oldBusinessLicenses.value.push(...res.data)
    }
    totalOldBusinessLicenses.value = res.totalCount
    if (form.oldBusinessLicenseId) {
      const item = oldBusinessLicenses.value.find(
        item => item.id === form.oldBusinessLicenseId
      )
      if (item) {
        selectedOldBusinessLicense.value = item
      }
    }
    return res.data
  }
  return {
    oldBusinessLicenses,
    totalOldBusinessLicenses,
    fetchOldBusinessLicenses
  }
}

const { selectedOldBusinessLicense, selectedOldBusinessLicenseBoss } =
  useSelectedOldBusinessLicense()
function useSelectedOldBusinessLicense() {
  const selectedOldBusinessLicense = ref()
  const selectedOldBusinessLicenseBoss = ref()
  watch(() => form.oldBusinessLicenseId, fetchBoss)
  async function fetchBoss(businessLicenseId) {
    const { data } = await commonApi.getBossByBusinessLicenseId({
      businessLicenseId
    })
    selectedOldBusinessLicenseBoss.value = data
  }
  return {
    selectedOldBusinessLicense,
    selectedOldBusinessLicenseBoss,
    fetchBoss
  }
}

const newBusinessLicenseAuthorizedCitySenders = ref([])
const {
  oldBusinessLicenseAuthorizedCities,
  oldBusinessLicenseAuthorizedCitySenders
} = useOldBusinessLicenseAuthorized()
async function onOldBusinessLicenseAuthorizedCityChange(code) {
  const item = oldBusinessLicenseAuthorizedCities.value.find(
    item => item.authorizedCityCode === code
  )
  if (!item) {
    Object.keys(form.authorizedCity).forEach(
      key => (form.authorizedCity[key] = '')
    )
    agencyBrands.value = []
  } else {
    Object.keys(form.authorizedCity).forEach(
      key => (form.authorizedCity[key] = item[key])
    )
    await fetchAgencyBrands(form.oldBusinessLicenseId, form.authorizedCityCode)
    form.brandIdList = agencyBrands.value.map(({ brandId }) => brandId)
  }

  if (form.shopChange) {
    fetchTransferShops(form.oldBusinessLicenseId, code)
  }
}
function useOldBusinessLicenseAuthorized() {
  const oldBusinessLicenseAuthorizedCities = ref([])
  watch(
    () => form.oldBusinessLicenseId,
    fetchOldBusinessLicenseAuthorizedCities
  )
  const oldBusinessLicenseAuthorizedCitySenders = ref([])
  watch(
    () => form.authorizedCityCode,
    () => {
      if (!form.authorizedCityCode) {
        oldBusinessLicenseAuthorizedCitySenders.value = []
        form.marketCenterCode = ''
        form.marketCenterName = ''
        form.marketLevelCode = ''
        form.marketLevelName = ''
        form.marketLevelTypeCode = ''
        form.marketLevelTypeName = ''
      } else {
        fetchOldBusinessLicenseAuthorizedCitySenders(
          form.oldBusinessLicenseId,
          form.authorizedCityCode
        )
        fetchOldBusinessLicenseAuthorizedMarketLevel(form.authorizedCityCode)
        fetchOldBusinessLicenseAuthorizedMarketCenter(
          form.authorizedCity.provinceCode,
          form.authorizedCity.cityCode,
          form.authorizedCity.areaCode
        )
      }
    }
  )
  async function fetchOldBusinessLicenseAuthorizedCities(businessLicenseId) {
    const res = await commonApi.getAuthorizedCityData({
      currPage: 1,
      pageSize: 100,
      businessLicenseId: businessLicenseId,
      // 这里后端特殊处理，-1时不判断生效/失效
      partyEnabled: isOA.value ? -1 : 0
    })
    oldBusinessLicenseAuthorizedCities.value = res.data
  }
  async function fetchOldBusinessLicenseAuthorizedCitySenders(
    businessLicenseId,
    authorizedCityCode
  ) {
    const res = await getMasterDataList({
      businessLicenseId,
      authorizedCityCode,
      brandId: CLASSIC_BRAND_ID
    })
    oldBusinessLicenseAuthorizedCitySenders.value = res.data
  }
  async function fetchOldBusinessLicenseAuthorizedMarketLevel(
    authorizedCityCode
  ) {
    const res = await commonApi.requestGetCityLevel({
      authorizedCityCode,
      brandId: CLASSIC_BRAND_ID
    })
    const [item] = res.data
    form.marketLevelCode = item?.marketLevelCode
    form.marketLevelName = item?.marketLevelName
    form.marketLevelTypeCode = item?.marketLevelType
    form.marketLevelTypeName = item?.marketLevelTypeName
  }
  async function fetchOldBusinessLicenseAuthorizedMarketCenter(
    provinceCode,
    cityCode,
    areaCode
  ) {
    const { data } = await commonApi.requestGetSizeBarListByDept({
      deptId: '1390103620495798273',
      provinceCode,
      cityCode,
      areaCode
    })
    form.marketCenterCode = data?.smallCode
    form.marketCenterName = data?.smallName
  }
  return {
    oldBusinessLicenseAuthorizedCities,
    oldBusinessLicenseAuthorizedCitySenders
  }
}

const { agencyBrands, fetchAgencyBrands } = useAgencyBrands()
watch([agencyBrands, () => form.marketLevelName], () => {
  if (agencyBrands.length === 0 || !form.marketLevelName) {
    form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount = 0
    form.bossJoinModel.dealerMarginRecordList[0].remittanceDate = ''
    form.bossJoinModel.dealerMarginRecordList[0].account = ''
    form.bossJoinModel.dealerMarginRecordList[0].accountName = ''
    form.bossJoinModel.dealerMarginRecordList[0].bank = ''
    form.bossJoinModel.dealerMarginRecordList[0].fileRecordList = []
    return
  }
  const brand = agencyBrands.value.map(({ name }) => name).join(',')
  if (brand === '慕思经典-新渠道') {
    form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount = 0
    form.bossJoinModel.dealerMarginRecordList[0].remittanceDate = ''
    form.bossJoinModel.dealerMarginRecordList[0].account = ''
    form.bossJoinModel.dealerMarginRecordList[0].accountName = ''
    form.bossJoinModel.dealerMarginRecordList[0].bank = ''
    form.bossJoinModel.dealerMarginRecordList[0].fileRecordList = []
    return
  }
  const { marketLevelName } = form
  if (marketLevelName.includes('A')) {
    form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount = 5
    return
  }
  if (marketLevelName.includes('B')) {
    form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount = 3
    return
  }
  if (marketLevelName.includes('C')) {
    form.bossJoinModel.dealerMarginRecordList[0].remittanceAmount = 1
    return
  }
})
function useAgencyBrands() {
  const agencyBrands = ref([])
  async function fetchAgencyBrands(businessLicenseId, authorizedCityCode) {
    const res = await commonApi.getShopBrandData({
      businessLicenseId,
      authorizedCityCode,
      bigBrandCode: CLASSIC_BRAND_CODE
    })
    agencyBrands.value = res.data
  }
  return {
    agencyBrands,
    fetchAgencyBrands
  }
}

const receiverBusinessLicense = ref()
const isReceiverBusinessLicenseValid = computed(() =>
  creditCodeReg.test(form.bossJoinModel.dealerBusinessLicense.creditCode)
)
async function onBusinessLicenseFileChange(files) {
  if (isOA.value) return
  const [file] = files
  if (!file) return
  const { data } = await ocrBizlicense({
    appId: 'wx27748066d2817941',
    imgUrl: file.url
  })
  const errcode = Number(data.errcode)
  if (errcode === 101003) return
  if (errcode !== 0) {
    Message.warning(
      '您上传的营业执照未能识别成功，请检查营业执照图片是否清晰或手工填写信息。'
    )
    return
  }
  form.bossJoinModel.dealerBusinessLicense.creditCode =data.reg_num // 统一社会信用代码
  form.bossJoinModel.dealerBusinessLicense.businessLicenseName =
    data.enterprise_name // 营业执照名称
  form.bossJoinModel.dealerBusinessLicense.businessLicenseType =
    data.type_of_enterprise.includes('个体工商户')
      ? '0'
      : '1' // 营业执类型
  // this.form.dealerBusinessLicense.registeredCapital =data.registered_capital//注册资本
  form.bossJoinModel.dealerBusinessLicense.legalPersonName =
    data.legal_representative // 法人姓名

  const registerDate = moment(
    data.registered_date.replace(/日|\s/g, '').replace(/年|月/g, '-')
  ).format('YYYY-MM-DD HH:mm:ss')
  form.bossJoinModel.dealerBusinessLicense.registerDate =
    registerDate.toLocaleLowerCase() === 'invalid date' ? '' : registerDate // 注册日期
  form.bossJoinModel.dealerBusinessLicense.businessTerm = data.valid_period // 营业期限
  form.bossJoinModel.dealerBusinessLicense.businessLicenseAddress = data.address // 营业执照地址
  form.bossJoinModel.dealerBusinessLicense.businessScope = data.business_scope // 经营范围
  const res = await getBusinessDataList({
    creditCode:form.bossJoinModel.dealerBusinessLicense.creditCode
  })
  if (res.data.length === 0) {
    return
  }
  const [item] = res.data
  receiverBusinessLicense.value = item
  Object.keys(form.bossJoinModel.dealerBusinessLicense).forEach(key => {
    if (key === 'creditCode') return
    form.bossJoinModel.dealerBusinessLicense[key] = item[key]
  })
  Object.keys(form.bossJoinModel.dealerBoss).forEach(key => {
    form.bossJoinModel.dealerBoss[key] = item[key]
  })
  form.newBusinessLicenseId = item.id
}
async function onReceiverCreditCodeBlur(value) {
  const code = value.trim()
  form.bossJoinModel.dealerBusinessLicense.creditCode = code
  const clear = () => {
    receiverBusinessLicense.value = undefined
    Object.keys(form.bossJoinModel.dealerBusinessLicense).forEach(key => {
      if (key === 'creditCode') return
      form.bossJoinModel.dealerBusinessLicense[key] = ''
    })
    Object.keys(form.bossJoinModel.dealerBoss).forEach(key => {
      form.bossJoinModel.dealerBoss[key] = ''
    })
    form.newBusinessLicenseId = ''
  }
  if (code === '') {
    clear()
    return
  }
  const res = await getBusinessDataList({
    creditCode: code
  })
  if (res.data.length === 0) {
    clear()
    return
  }
  const [item] = res.data
  receiverBusinessLicense.value = item
  Object.keys(form.bossJoinModel.dealerBusinessLicense).forEach(key => {
    if (key === 'creditCode') return
    form.bossJoinModel.dealerBusinessLicense[key] = item[key]
  })
  Object.keys(form.bossJoinModel.dealerBoss).forEach(key => {
    form.bossJoinModel.dealerBoss[key] = item[key]
  })
  form.newBusinessLicenseId = item.id
}

const dealerBoss = ref()
async function onDealerBossIdCardBlur(v) {
  const value = v.trim()
  if (value === '') {
    Object.keys(form.bossJoinModel.dealerBoss).forEach(
      key => (form.bossJoinModel.dealerBoss[key] = '')
    )
    return
  }
  form.bossJoinModel.dealerBoss.idCard = value
  const res = await getBossByIdCard({
    idCard: value
  })
  if (!res.data) {
    dealerBoss.value = undefined
    Object.keys(form.bossJoinModel.dealerBoss).forEach(key => {
      if (key === 'idCard') return
      form.bossJoinModel.dealerBoss[key] = ''
    })
  } else {
    dealerBoss.value = res.data
    Object.keys(form.bossJoinModel.dealerBoss).forEach(key => {
      form.bossJoinModel.dealerBoss[key] = res.data[key]
    })
  }
}

const {
  transferShops,
  transferShopTypes,
  transferShopProperties,
  fetchTransferShops,
  fetchTransferShopTypes,
  fetchTransferShopProperties
} = useTransferShops()
function useTransferShops() {
  const transferShops = ref([])
  const transferShopTypes = new Map()
  const transferShopProperties = new Map()
  async function fetchTransferShops(businessLicenseId, authorizedCityCode) {
    const res = await commonApi.getShopList({
      businessLicenseId,
      authorizedCityCode,
      parentBrandId: CLASSIC_BRAND_ID,
      statusExplain: 40
    })
    const requests = (() => {
      const shopTypeCodes = [
        ...new Set(res.data.map(({ shopType }) => shopType))
      ].filter(code => !transferShopTypes.has(code))
      const requests = [fetchTransferShopTypes(shopTypeCodes)]
      if (transferShopProperties.size === 0) {
        requests.push(fetchTransferShopProperties())
      }
      return requests
    })()
    await Promise.all(requests)
    transferShops.value = res.data
  }
  async function fetchTransferShopTypes(codes) {
    const requests = codes.map(code =>
      commonApi.getAnyTypeList({
        module: 'distributor',
        type: 'store_type',
        code
      })
    )
    const responses = await Promise.all(requests)
    responses.forEach(res => {
      const [item] = res.data
      if (!item) return
      transferShopTypes.set(item.code, item?.content ?? item.code)
    })
  }
  async function fetchTransferShopProperties() {
    const res = await commonApi.getAnyTypeList({
      type: 'shop_nature_code',
      module: 'distributor'
    })
    res.data.forEach(item => {
      transferShopProperties.set(item.code, item.content)
    })
  }
  return {
    transferShops,
    transferShopTypes,
    transferShopProperties,
    fetchTransferShops,
    fetchTransferShopTypes,
    fetchTransferShopProperties
  }
}

const { uploadFileFields, setUploadFileFieldRequired } = useUploadFileFields()

function useUploadFileFields() {
  const uploadFileFields = ref([
    {
      label: '《市场资产评估表》',
      value: 'file02List',
      required: false
    },
    {
      label: '《XX市场资产转让协议》',
      value: 'file03List',
      required: true
    },
    {
      label: '《订单确认样式》',
      value: 'file04List',
      required: false
    },
    {
      label: '《市场转让财务清算表》',
      value: 'file05List',
      required: false
    },
    {
      label: '《承诺书》',
      value: 'file06List',
      required: true
    },
    {
      label: '《慕思经典转让加盟承诺书》',
      value: 'file07List',
      required: true
    },
    {
      label: '《慕思经典加盟申请人评估表》',
      value: 'file08List',
      required: true
    },
    {
      label: '《慕思经典经销商经营计划书》',
      value: 'file09List',
      required: true
    },
    {
      label: '《慕思经典经销商放弃竞品经销权承诺书》',
      value: 'file10List',
      required: false
    },
    {
      label: '《当地主要竞品经营概况及应对策略》',
      value: 'file11List',
      required: false
    },
    {
      label: '《慕思经典意向加盟人公平光明承诺书》',
      value: 'file12List',
      required: true
    },
    {
      label: '《慕思经典经销商保证书》',
      value: 'file13List',
      required: true
    },
    {
      label: '转让申请表',
      value: 'file14List',
      required: true
    },
    {
      label: '实际控制人联合声明',
      value: 'file15List',
      required: true
    },
    {
      label: '近3个月银行流水（余额）',
      value: 'file16List',
      required: true
    },
    {
      label: '接手方身份证（正面、反面）',
      value: 'file17List',
      required: true
    },
    {
      label: '合股协议书（合伙经营需提供）',
      value: 'file18List',
      required: false
    },
    {
      label: '合股人身份证（正面和反面，合伙经营需提供）',
      value: 'file19List',
      required: false
    },
    {
      label: '其他',
      value: 'file20List',
      required: false
    }
  ])
  watch(
    () => form.bossJoinModel.agentTypeName,
    () => {
      const status = form.bossJoinModel.agentTypeName === '合作代理'
      setUploadFileFieldRequired('file18List', status)
      setUploadFileFieldRequired('file19List', status)
    }
  )
  watch(
    () => form.changeReason,
    () => {
      const file = {
        label: '《经销商主动放弃经销权申请书》',
        value: 'file01List',
        required: true
      }
      if (form.changeReason === '清理转让') {
        uploadFileFields.value = uploadFileFields.value.filter(
          v => v.value !== file.value
        )
      } else {
        uploadFileFields.value.unshift(file)
      }
    }
  )
  function setUploadFileFieldRequired(key, bool) {
    const field = uploadFileFields.value.find(item => item.value === key)
    field.required = bool
  }
  return {
    uploadFileFields,
    setUploadFileFieldRequired
  }
}

const { allBrandSeries, fetchAllBrandSeries } = useAllBrandSeries()
function useAllBrandSeries() {
  const allBrandSeries = ref([])
  fetchAllBrandSeries()
  async function fetchAllBrandSeries() {
    const res = await getBrandseriesList({
      parentId: CLASSIC_BRAND_ID
    })
    allBrandSeries.value = res.data
  }
  return {
    allBrandSeries,
    fetchAllBrandSeries
  }
}

const selectedTransferSeries = computed(() => {
  const result = allBrandSeries.value.filter(
    item =>
      [...form.brandIdList, ...form.newAddBrandIdList].findIndex(
        id => id === item.id
      ) > -1
  )
  return result
})

const addableBrandSeries = computed(() =>
  allBrandSeries.value.filter(
    item =>
      agencyBrands.value.findIndex(({ brandId }) => brandId === item.id) === -1
  )
)

const { shopSeries } = useShopSeries()
function useShopSeries() {
  const shopSeries = ref([])
  watch([() => form.newBusinessLicenseId, selectedTransferSeries], async () => {
    shopSeries.value = []
    if (form.newBusinessLicenseId) {
      const res = await commonApi.getShopBrandData({
        businessLicenseId: form.newBusinessLicenseId,
        authorizedCityCode: form.authorizedCityCode,
        bigBrandCode: CLASSIC_BRAND_CODE
      })
      shopSeries.value = res.data.map(v => ({ id: v.brandId, name: v.name }))
    }
    selectedTransferSeries.value.forEach(item => {
      const exists = shopSeries.value.findIndex(v => v.id === item.id) > -1
      if (!exists) {
        shopSeries.value.push({
          id: item.id,
          name: item.name
        })
      }
    })
  })

  return {
    shopSeries
  }
}
</script>
