<!--
 * @Author: wangmq
 * @Date: 2023-06-14 11:04:39
 * @LastEditors: wangmq
 * @LastEditTime: 2023-06-25 09:22:48
-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      v-bind="$attrs"
      class="dialog"
      v-on="$listeners"
    >
      <div class="case">
        <div class="case_carousel">
          <el-carousel ref="carousel" :interval="4000" :autoplay="false">
            <el-carousel-item
              v-for="item in caseData.operateFileRecordList"
              :key="item.id"
              class="case_carousel_item"
              :name="item.id"
            >
              <el-image
                :src="item.requestDomainName + item.requestPath + item.fileName"
                class="case-image"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="case_detail">
          <div class="wrapper">
            <ul style="padding: 0">
              <li>案例名称：{{ caseData.caseName }}</li>
            </ul>
            <p>{{ caseData.caseDetails }}</p>

            <div class="image_wrapper">
              <el-image
                v-for="item in caseData.operateFileRecordList"
                :key="item.id"
                :src="item.requestDomainName + item.requestPath + item.fileName"
                class="image_item"
                @click="getImage(item.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    caseData: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(newVal) {
        this.$emit('update:visible', newVal)
      }
    }
  },
  methods: {
    getImage(id) {
      this.$refs.carousel.setActiveItem(id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/colors.scss';
:deep(.el-dialog__header),
:deep(.el-dialog__body) {
  padding: 0;
}
:deep(.el-carousel__indicators--horizontal) {
  display: none;
}
.case {
  max-width: 1000px;
  height: 540px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .image {
    width: 100%;
    height: 100%;
  }
  .case_carousel {
    align-self: center;
    width: 540px;
    height: 540px;
    box-sizing: border-box;
    :deep(.el-carousel__container) {
      height: 540px;
    }
    :deep(.el-image) {
      display: block;
      width: 100%;
      height: 100%;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image__inner {
        width: initial;
        height: initial;
        max-width: 100%;
        max-height: 100%;
      }
    }
    :deep(.el-carousel__arrow) {
      background-color: rgba(31, 45, 61, 0.11);
      color: #fff;
      width: 36px;
      height: 36px;
      font-size: 12px;
      font-weight: bold;
    }
    :deep(.el-carousel__arrow--left) {
      left: 10px;
    }
    :deep(.el-carousel__arrow--right) {
      right: 10px;
    }
  }
  .case_detail {
    flex: 0 0 460px;
    font-size: 14px;
    color: #333;
    line-height: 2;

    height: 100%;

    overflow-x: hidden;
    .wrapper {
      width: 480px;
      height: 100%;
      overflow-y: auto;
      padding: 20px 40px 20px 20px;
      .image_wrapper {
        display: flex;
        flex-wrap: wrap;
        .image_item {
          margin: 6px 6px 0 6px;
          width: 120px;
          height: 150px;
          background: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.3);
          }
          :deep(.el-image__inner:before) {
            content: ' ';
            vertical-align: middle;
          }
          :deep(.el-image__inner) {
            width: initial;
            height: initial;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}
ul,
ol {
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
</style>
