import { render, staticRenderFns } from "./dealerInterview.vue?vue&type=template&id=4387a623&scoped=true"
import script from "./dealerInterview.vue?vue&type=script&lang=js"
export * from "./dealerInterview.vue?vue&type=script&lang=js"
import style0 from "./dealerInterview.vue?vue&type=style&index=0&id=4387a623&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4387a623",
  null
  
)

export default component.exports