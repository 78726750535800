<!-- 新渠道经销商合作方信息报备流程 -->
<template>
  <FlowForm
    ref="flowFormRef"
    title="新渠道经销商合作方信息报备流程"
    :model="form"
    :getDetail="getDetail"
    :onSubmit="onSubmit"
  >
    <div>
      <RequiredFormItemInput
        delayUpdate
        label="标题"
        class="formItem"
        :value="form.dealerDeclarationForm.title"
        disabled
        :required="false"
      />
      <RequiredFormItemInput
        delayUpdate
        label="单号"
        class="formItem"
        :value="form.dealerDeclarationForm.orderNumber"
        placeholder="自动生成"
        disabled
        :required="false"
      />
      <RequiredFormItemInput
        class="formItem"
        label="提单人工号"
        :value="form.dealerDeclarationForm.optJobNumber"
        placeholder="自动生成"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="提单人"
        :value="form.dealerDeclarationForm.optUserName"
        placeholder="自动生成"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="提单部门"
        :value="form.dealerDeclarationForm.optDeptName"
        placeholder="自动生成"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="提单日期"
        :value="form.dealerDeclarationForm.optTime"
        placeholder="自动生成"
        disabled
      />
    </div>
    <HeaderTitle style="margin-top: 20px" title="售达方信息" />
    <div>
      <RequiredFormItemSelect
        class="formItem"
        ref="businessRef"
        prop="licenseNumber"
        v-model="form.licenseNumber"
        label="售达方编号"
        labelKey="licenseNumber"
        valueKey="licenseNumber"
        :selectProps="{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        }"
        :total-count="businessLicensesCount"
        :getDataList="getBusinessLicenses"
        :getNextList="getBusinessLicenses"
        @onOptionsListNotFound="onBusinessLicensesNotFound"
        @change="onBusinessLicenseChange"
        @changeSelectedItem="onBusinessLicenseSelect"
        :disabled="isOA"
      >
        <template v-slot="{ scope }">
          <span>{{ scope.row.licenseNumber }}</span>
          <small>{{ scope.row.businessLicenseName }}</small>
        </template>
      </RequiredFormItemSelect>
      <RequiredFormItemInput
        class="formItem"
        label="售达方名称"
        :value="form.businessLicenseName"
        placeholder="自动生成"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="经销商（实际控股人）"
        :value="form.realName"
        placeholder="自动生成"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="经销商（实际控股人）联系方式"
        :value="form.contactInfo"
        placeholder="自动生成"
        disabled
      />

      <RequiredFormItemInput
        v-if="isOA"
        class="formItem"
        label="授权城市"
        :value="form.authorizedCityName"
        disabled
      />
      <RequiredFormItemSelect
        v-else
        v-model="form.authorizedCityCode"
        class="formItem"
        prop="authorizedCityCode"
        label="授权城市"
        labelKey="authorizedCityName"
        valueKey="authorizedCityCode"
        emptyDisabled
        :getDataList="getCities"
        :getNextList="getCities"
        :getDataListParams="
          form.businessLicenseId
            ? `${form.businessLicenseId},${form.bossId}`
            : ''
        "
        @changeSelectedItem="
          e => (form.authorizedCityName = e ? e.authorizedCityName : '')
        "
        :disabled="isOA"
      />
      <RequiredFormItemSelect
        v-model="form.senderNumber"
        class="formItem"
        prop="senderId"
        label="送达方编号"
        placeholder="请选择售达方下对应送达方"
        labelKey="shopBrandNumber"
        valueKey="shopBrandNumber"
        emptyDisabled
        :disabled="!form.businessLicenseId || isOA"
        :getDataList="getSenders"
        :nextDataList="getSenders"
        :getDataListParams="form.businessLicenseId"
        @onOptionsListNotFound="onSendersNotFound"
        @changeSelectedItem="
          e => {
            form.senderId = e ? e.id : ''
            form.senderName = e ? e.authorizedName : ''
            form.senderNumber = e ? e.shopBrandNumber : ''
          }
        "
        @change="
          () => {
            form.partnerCreditCode = ''
            clearPartner()
          }
        "
      />
      <RequiredFormItemInput
        v-model="form.senderName"
        class="formItem"
        label="送达方名称"
        placeholder="系统自动带出"
        disabled
      />
      <RequiredFormItemSelect
        label="申请类型"
        v-model="form.applyType"
        class="formItem"
        prop="applyType"
        :dataList="applicationTypes"
        label-key="label"
        value-key="value"
        :disabled="isOA"
        @change="onApplicationTypeChange"
      />
    </div>
    <HeaderTitle style="margin-top: 20px" title="合作方信息" />
    <div>
      <RequiredFormItemInput
        class="formItem"
        label="合作方执照代码（编号）"
        strong
        delayUpdate
        prop="partnerCreditCode"
        :value="form.partnerCreditCode"
        @blur="evt => onPartnerCreditCodeBlur(evt.target.value)"
        :validations="['credit_code']"
        :disabled="isOA || !form.senderId"
      />
      <RequiredFormItemInput
        class="formItem"
        label="合作方营业执照名称"
        v-model="form.partnerBusinessLicenseName"
        prop="partnerBusinessLicenseName"
        delayUpdate
        :disabled="isOA || !form.senderId || partnerExisted"
      />
      <RequiredFormItemInput
        class="formItem"
        label="合作方联系人（名称）"
        v-model="form.partnerContactPersonName"
        prop="partnerContactPersonName"
        delayUpdate
        :disabled="isOA || !form.senderId || partnerExisted"
      />
      <RequiredFormItemInput
        class="formItem"
        label="合作方联系人联系方式"
        v-model="form.partnerContactPersonPhone"
        prop="partnerContactPersonPhone"
        :validations="['phone', 'check_boss_number']"
        delayUpdate
        :disabled="isOA || !form.senderId || partnerExisted"
      />
      <RequiredFormItemInput
        class="formItem"
        label="合作方收货人（名称）"
        v-model="form.partnerConsigneeName"
        prop="partnerConsigneeName"
        delayUpdate
        :disabled="isOA || !form.senderId || partnerExisted"
      />
      <RequiredFormItemInput
        class="formItem"
        label="合作方收货人联系方式"
        v-model="form.partnerConsigneeContact"
        prop="partnerConsigneeContact"
        :validations="['phone', 'check_boss_number']"
        delayUpdate
        :disabled="isOA || !form.senderId || partnerExisted"
      />
      <RequiredFormItemInput
        class="formItem"
        label="合作方收货地址"
        v-model="form.partnerConsigneeAddress"
        prop="partnerConsigneeAddress"
        delayUpdate
        :disabled="isOA || !form.senderId || partnerExisted"
      />
      <RequiredFormItemInput
        class="formItem"
        label="渠道类型"
        placeholder="同步渠道二级分类"
        :value="form.secondChannelTypeName"
        disabled
      />
      <RequiredFormItemDatePicker
        class="formItem"
        :datePickerProps="{
          type: 'datetime',
          'value-format': 'yyyy-MM-dd HH:mm:ss'
        }"
        label="合同有效起始日期"
        v-model="form.contractLifeStartTime"
        prop="contractLifeStartTime"
        delayUpdate
        :disabled="
          isOA ||
          form.applyType === 2 ||
          !form.senderId ||
          (form.applyType === 3 && partnerEnabled)
        "
      />
      <RequiredFormItemDatePicker
        class="formItem"
        :datePickerProps="{
          type: 'datetime',
          'value-format': 'yyyy-MM-dd HH:mm:ss',
          'default-time': '23:59:59'
        }"
        label="合同有效截止日期"
        v-model="form.contractLifeEndTime"
        prop="contractLifeEndTime"
        delayUpdate
        :disabled="isOA || form.applyType === 2 || !form.senderId"
      />
    </div>
    <HeaderTitle style="margin-top: 20px" title="渠道性质" />
    <div>
      <RequiredFormItemInput
        class="formItem"
        label="渠道性质三级分类（大客户名称）编码"
        placeholder="自动生成"
        :value="form.thirdChannelTypeCode"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="渠道性质三级分类（大客户名称）描述"
        placeholder="自动生成"
        :value="form.thirdChannelTypeName"
        prop="thirdChannelTypeName"
        disabled
      />
      <RequiredFormItemInput
        class="formItem"
        label="渠道性质一级分类"
        :value="form.firstChannelTypeName"
        prop="firstChannelTypeId"
        disabled
      />
      <RequiredFormItemSelect
        class="formItem"
        label="渠道性质二级分类"
        v-model="form.secondChannelTypeId"
        :getDataList="getSecondChannelTypes"
        label-key="content"
        value-key="id"
        prop="secondChannelTypeId"
        :getDataListParams="form.firstChannelTypeId"
        @changeSelectedItem="
          e => {
            if (!e) {
              form.secondChannelTypeCode = ''
              form.secondChannelTypeName = ''
            } else {
              form.secondChannelTypeCode = e.code
              form.secondChannelTypeName = e.content
            }
          }
        "
        :disabled="isOA || !form.senderId || partnerExisted"
      />
    </div>
    <RemarkItem
      v-model="form.remark"
      :file-list="form.contractFileList"
      @onUpload="
        file => {
          form.contractFileList = file.map(item => item.response)
        }
      "
      :disabled="isOA"
    />
  </FlowForm>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted
} from 'vue'
import { Message } from 'element-ui'
import dateFormat from 'dateformat'
import FlowForm from './components/flowForm.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RemarkItem from './components/remarkItem.vue'
import HeaderTitle from './components/headerTitle.vue'
import store from '@/store'
import { getAuthorizedCityData } from '@/api/common'
import * as BusinessApi from '@/api/attractPlatform/business'
import { getMasterDataList } from '@/api/businessOos/index'
import { getStoreType } from '@/api/processBasicConfiguration'
import {
  requestDealerPartner,
  requestDealerPartnerFlowDraft,
  requestDealerPartnerFlowSave,
  requestDealerPartnerFlow
} from '@/api/flow/dealerPartner'
import { creditCodeReg } from '@/utils/useFormValidator'
import { useRoute, useRouter } from '@/utils/router'
export default defineComponent({
  components: {
    FlowForm,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    HeaderTitle,
    RemarkItem
  },
  setup(props, ctx) {
    const $route = useRoute()
    const routeQuery = $route.query
    console.log(routeQuery);
    const user = store.state.user.userInfo
    const isOA = computed(() => store.state.dealershipTransfer.isOA)
    const form = reactive({
      dealerDeclarationForm: {
        /**表单id */
        id: undefined,
        /**标题 */
        title: '新渠道经销商合作方信息报备流程',
        /**单号 */
        orderNumber: undefined,
        /**提单人工号 */
        optJobNumber: '',
        /**提单人id */
        optUserId: '',
        /**提单人 */
        optUserName: '',
        /**提单部门id */
        optDeptId: '',
        /**提单部门 */
        optDeptName: '',
        /**提单日期 */
        optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'),
        /**表单状态(0:草稿) */
        status: 0
      },
      /**申请类型 */
      applyType: '',
      /**售达方 id */
      businessLicenseId: '',
      /**老板 id */
      bossId: '',
      /**售达方编号 */
      licenseNumber: '',
      /**售达方名称 */
      businessLicenseName: '',
      /**经销商 */
      realName: '',
      /**经销商联系方式 */
      contactInfo: '',
      /**授权城市 code */
      authorizedCityCode: '',
      /**授权城市名称 */
      authorizedCityName: '',
      /**送达方 id */
      senderId: '',
      /**送达方编号 */
      senderNumber: '',
      /**送达方名称 */
      senderName: '',
      /**合作方营业执照编号 */
      partnerCreditCode: '',
      /**合作方营业执照名称 */
      partnerBusinessLicenseName: '',
      /**合作方联系人（名称） */
      partnerContactPersonName: '',
      /**合作方联系人联系方式 */
      partnerContactPersonPhone: '',
      /**合作方收货人（名称） */
      partnerConsigneeName: '',
      /**合作方收货人联系方式 */
      partnerConsigneeContact: '',
      /**合作方收货地址 */
      partnerConsigneeAddress: '',
      /**渠道类型编码 */
      channelTypeCode: '',
      /**渠道类型名称 */
      channelTypeName: '',
      /**合同有效起始日期 */
      contractLifeStartTime: '',
      /**合同有效截止日期 */
      contractLifeEndTime: '',
      /**渠道性质三级分类（大客户名称）编码 */
      thirdChannelTypeCode: '',
      /**渠道性质三级分类（大客户名称）描述 */
      thirdChannelTypeName: '',
      /**渠道性质一级分类 id */
      firstChannelTypeId: '',
      /**渠道性质一级分类编码 */
      firstChannelTypeCode: '',
      /**渠道性质一级描述描述 */
      firstChannelTypeName: '',
      /**渠道性质二级分类id */
      secondChannelTypeId: '',
      /**渠道性质二级分类编码 */
      secondChannelTypeCode: '',
      /**渠道性质二级分类描述 */
      secondChannelTypeName: '',
      /**备注 */
      remark: '',
      /**附件列表 */
      contractFileList: []
    })
    const partnerExisted = ref(false)
    const partnerEnabled = ref(false)

    const applicationTypes = [
      {
        label: '新增',
        value: 1
      },
      {
        label: '合同续约',
        value: 3
      },
      {
        label: '直接失效',
        value: 2
      }
    ]
    function onApplicationTypeChange(val) {
      onPartnerCreditCodeBlur(form.partnerCreditCode)
    }

    const secondChannelTypes = ref([])
    async function getSecondChannelTypes({ getDataListParams }) {
      if (!getDataListParams) {
        return []
      }
      const res = await getChannelTypes(
        'secondary_store_type',
        getDataListParams
      )
      return res.data
    }
    async function getChannelTypes(type, parentId) {
      const res = await getStoreType({
        module: 'distributor',
        type,
        parent_id: parentId
      })
      return res
    }

    async function getCities({
      getDataListParams,
      page: currPage,
      searchText: authorizedCityName
    }) {
      if (!getDataListParams) {
        return []
      }

      const [businessLicenseId, bossId] = getDataListParams.split(',')
      const res = await BusinessApi.shoplist({
        businessLicenseId,
        bossId,
        currPage,
        authorizedCityName,
        pageSize: 10
      })
      return res
    }

    const businessLicensesCount = ref(0)
    const selectedBusinessLicenseItem = ref()
    async function getBusinessLicenses({
      page,
      searchText,
      businessLicenseId
    }) {
      const res = await BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      })
      businessLicensesCount.value = res.totalCount
      const item = res.data.find(
        item => item.licenseNumber === form.licenseNumber
      )
      if (item) {
        form.businessLicenseId = item.id
        form.businessLicenseName = item.businessLicenseName
        form.bossId = item.bossId
        form.realName = item.realName
        form.contactInfo = item.contactInfo
      }
      return res.data
    }
    async function onBusinessLicenseChange(businessLicenseId) {
      form.authorizedCityCode = ''
      form.authorizedCityName = ''
      form.senderId = ''
      form.senderNumber = ''
      form.senderName = ''
      form.partnerCreditCode = ''
      clearPartner()
    }
    function onBusinessLicenseSelect(item) {
      selectedBusinessLicenseItem.value = item
      form.businessLicenseId = item.id
      form.businessLicenseName = item.businessLicenseName
      form.bossId = item.bossId
      form.realName = item.realName
      form.contactInfo = item.contactInfo
    }
    async function onBusinessLicensesNotFound(
      { value: businessLicenseId },
      callback
    ) {
      const res = await BusinessApi.license({
        businessLicenseId
      })
      callback(res.data)
    }

    async function getSenders({
      getDataListParams: businessLicenseId,
      searchText
    }) {
      if (!businessLicenseId) {
        return []
      }
      const res = await getMasterDataList({
        businessLicenseId,
        shopBrandNumber: searchText
      })
      return res
    }
    async function onSendersNotFound(e, callback) {
      const res = await getMasterDataList({
        shopBrandNumber: form.senderNumber
      })
      callback(res.data)
    }

    async function onPartnerCreditCodeBlur(value) {
      form.partnerCreditCode = value.trim()
      if (form.partnerCreditCode === '') return
      if (!creditCodeReg.test(form.partnerCreditCode)) return
      const res = await requestDealerPartner(
        form.partnerCreditCode,
        form.senderNumber
      )
      if (res.partnerCreditCode) {
        partnerExisted.value = true
        partnerEnabled.value = res.thirdChannelTypeIfDelete === 0
        form.partnerBusinessLicenseName = res.partnerBusinessLicenseName
        form.partnerContactPersonName = res.partnerContactPersonName
        form.partnerContactPersonPhone = res.partnerContactPersonPhone
        form.partnerConsigneeName = res.partnerConsigneeName
        form.partnerConsigneeContact = res.partnerConsigneeContact
        form.partnerConsigneeAddress = res.partnerConsigneeAddress
        form.channelTypeCode = res.channelTypeCode
        form.channelTypeName = res.channelTypeName
        form.contractLifeStartTime = res.contractLifeStartTime || ''
        form.contractLifeEndTime = res.contractLifeEndTime || ''
        form.thirdChannelTypeCode = res.thirdChannelTypeCode
        form.thirdChannelTypeName = res.thirdChannelTypeName
        form.secondChannelTypeId = res.secondChannelTypeId
        form.secondChannelTypeCode = res.secondChannelTypeCode
        form.secondChannelTypeName = res.secondChannelTypeName
      } else {
        clearPartner()
      }
    }
    function clearPartner() {
      partnerExisted.value = false
      partnerEnabled.value = false
      form.partnerBusinessLicenseName = ''
      form.partnerContactPersonName = ''
      form.partnerContactPersonPhone = ''
      form.partnerConsigneeName = ''
      form.partnerConsigneeContact = ''
      form.partnerConsigneeAddress = ''
      form.channelTypeCode = ''
      form.channelTypeName = ''
      form.contractLifeStartTime = ''
      form.contractLifeEndTime = ''
      form.thirdChannelTypeCode = ''
      form.thirdChannelTypeName = ''
      form.secondChannelTypeId = ''
      form.secondChannelTypeCode = ''
      form.secondChannelTypeName = ''
    }

    async function getDetail(type, cachedForm) {
      if (type === 'detail') {
        // 编辑
        const res = await requestDealerPartnerFlow(routeQuery.id)
        Object.keys(form).forEach(key => {
          if (key === 'dealerDeclarationForm') {
            Object.keys(form.dealerDeclarationForm).forEach(item => {
              form.dealerDeclarationForm[item] = res.dealerDeclarationForm[item]
            })
          }
          form[key] = res[key]
        })
        if (!isOA.value) {
          onPartnerCreditCodeBlur(form.partnerCreditCode)
        }
      }
      if (user && !isOA.value) {
        form.dealerDeclarationForm.optUserId = user.accountId
        form.dealerDeclarationForm.optJobNumber = user.code
        form.dealerDeclarationForm.optUserName = user.realName
        form.dealerDeclarationForm.optDeptId = user.deptId
        form.dealerDeclarationForm.optDeptName = user.deptName
      }
    }

    async function onSubmit(type) {
      form.channelTypeCode = form.secondChannelTypeCode
      form.channelTypeName = form.secondChannelTypeName
      const res = await requestDealerPartnerFlowDraft(form)
      form.dealerDeclarationForm.id = res.id
      if (type === 'submit') {
        // 提交
        if (form.applyType === 1 && partnerExisted.value) {
          Message.warning('合作方已存在，请修改申请类型为续约或失效')
          throw new Error('合作方已存在，请修改申请类型为续约或失效')
        }
        await requestDealerPartnerFlowSave(form.dealerDeclarationForm.id)
      }
    }
    onMounted(async () => {
      try {
        const res = await getStoreType({
          module: 'distributor',
          type: 'store_type'
        })
        const item = res.data.find(item => item.content === '新渠道店')
        if (item) {
          form.firstChannelTypeId = item.id
          form.firstChannelTypeCode = item.code
          form.firstChannelTypeName = item.content
        }
      } catch (err) {}
    })
    return {
      isOA,
      form,
      partnerExisted,
      partnerEnabled,
      applicationTypes,
      onApplicationTypeChange,
      getChannelTypes,
      secondChannelTypes,
      getSecondChannelTypes,
      getCities,
      businessLicensesCount,
      selectedBusinessLicenseItem,
      getBusinessLicenses,
      onBusinessLicenseChange,
      onBusinessLicenseSelect,
      onBusinessLicensesNotFound,
      getSenders,
      onSendersNotFound,
      clearPartner,
      onPartnerCreditCodeBlur,
      getDetail,
      onSubmit
    }
  }
})
</script>
