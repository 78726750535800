<template>
  <div :class="{ oa: isOA }">
    <div class="flow-title">
      <h3>专卖店设计及装修流程</h3>
    </div>

    <el-divider></el-divider>
    <el-form
      class="flow-form el-form--inline"
      label-position="top"
      ref="form"
      :model="form">
      <required-form-item-input
        v-for="(formValue, formKey) in keys"
        :key="formKey"
        disabled
        delay-update
        class="formItem"
        :prop="formKey"
        :value="form[formKey]"
        :label="formValue"
        placeholder="/"
        :required="false"
        :inputProps="{ readonly: true }" />
      <required-form-item-input
        v-if="isClassical"
        disabled
        delay-update
        class="formItem"
        prop="partyBrandNameList"
        :value="form.partyBrandNameList"
        label="已代理品牌"
        placeholder="/"
        :required="false"
        :inputProps="{ readonly: true }" />

      <HeaderTitle style="margin: 20px 0" title="终端门店信息" />

      <el-divider></el-divider>
      <el-table fit border :data="tableData" style="width: 100%">
        <el-table-column min-width="120" label="序号" type="index" />
        <el-table-column
          v-if="isClassical"
          min-width="120"
          prop="parentShopNumber"
          label="物理门店编号" />
        <el-table-column min-width="120" prop="shopNumber" label="门店编号" />
        <el-table-column min-width="200" prop="shopName" label="门店名称" />
        <el-table-column
          min-width="120"
          prop="storeNature"
          :label="isClassical ? '店态性质' : '门店性质'">
          <template v-slot="{ row }">
            <required-module-form-item-select
              disabled
              class="formItem"
              v-model="row.storeNature"
              :showLabel="false"
              type="shop_nature_code" />
          </template>
        </el-table-column>
        <!--经典品牌流程显示-->
        <template v-if="isClassical">
          <template
            v-for="(classicalValue, classicalKey) in {
              storeOpeningType: '开店类型',
              renovationLiablePerson: '装修对接人',
              renovationPersonPhone: '装修对接人电话',
              shopAddArea: '装修面积',
              dianjiangDesigner: '点将设计师',
              ifDianjiangDesigner: '是否点将设计',
              dianjiangDesignerPrice: '点将参考费用'
            }">
            <el-table-column
              min-width="120"
              :prop="classicalKey"
              :label="classicalValue" />
          </template>
          <el-table-column
            min-width="180"
            prop="independentDoorHeadFlag"
            label="独立外立面门头">
            <template v-slot="{ row }">
              <required-module-form-item-select
                v-model="row.independentDoorHeadFlag"
                disabled
                class="formItem"
                :show-label="false"
                label="独立外立面门头"
                type="independent_door_head_flag_boolean" />
            </template>
          </el-table-column>
          <el-table-column min-width="180" prop="shopType" label="渠道性质">
            <template v-slot="{ row }">
              <required-module-form-item-select
                v-model="row.shopType"
                class="formItem"
                type="store_type"
                :show-label="false"
                disabled />
            </template>
          </el-table-column>
          <el-table-column
            min-width="240"
            prop="shopTypeValue"
            label="渠道性质二级分类">
            <template v-slot="{ row }">
              <required-module-form-item-select
                v-if="['1', '4'].includes(row.shopType)"
                v-model="row.shopTypeValue"
                class="formItem"
                :show-label="false"
                type="secondary_store_type"
                value-key="content"
                :parent-code="row.shopType"
                :getDataListParams="{ parentType: 'store_type' }"
                disabled />
              <required-form-item-input
                v-else
                v-model.trim="row.shopTypeValue"
                class="formItem"
                :show-label="false"
                disabled />
            </template>
          </el-table-column>
          <el-table-column min-width="180" prop="marketName" label="卖场名称">
            <template v-slot="{ row }">
              <required-module-form-item-select
                v-model="row.marketName"
                class="formItem"
                label="卖场名称"
                type="dealer_shop.market_name"
                :show-label="false"
                disabled />
            </template>
          </el-table-column>
          <el-table-column min-width="120" prop="marketType" label="卖场类型" />
        </template>

        <el-table-column
          min-width="180"
          prop="shopPositionLevel"
          label="门店位置等级">
          <template v-slot="{ row }">
            <required-module-form-item-select
              class="formItem"
              disabled
              v-model="row.shopPositionLevel"
              label="门店位置等级"
              type="shop_level"
              :showLabel="false" />
          </template>
        </el-table-column>
        <el-table-column label="经营模式" width="180">
          <template v-slot="{ row }">
            <required-module-form-item-select
              class="formItem"
              disabled
              v-model="row.managementModel"
              :showLabel="false"
              label="经营模式"
              type="management_model" />
          </template>
        </el-table-column>
        <template
          v-for="(shopLabel, shopProp) in {
            displayShopType: '是否展示店',
            province: '省份',
            city: '地级市',
            area: '区/县',
            street: '道路/街道',
            shopFloor: '门店楼层',
            shopFloorNumber: '门店铺号',
            shopAddress: '门店地址',
            brandName: '门店授权系列',
            shopArea: isClassical ? '调整后门店面积' : '门店实用面积',
            openingTime: '开业时间',
            decorationTime: '最新装修时间'
          }">
          <el-table-column min-width="120" :prop="shopProp" :label="shopLabel">
          </el-table-column>
        </template>
      </el-table>

      <div style="height: 30px"></div>
      <required-form-item-input
        disabled
        delay-update
        class="formItem"
        prop="childrenSupportingAreaFlag"
        label="是否儿童配套专区"
        placeholder="/"
        :value="form.childrenSupportingAreaFlag ? `是` : '否'"
        :required="false"
        :inputProps="{ readonly: true }" />
      <required-form-item-input
        disabled
        delay-update
        class="formItem"
        prop="childrenSupportingArea"
        label="儿童配套专区面积"
        placeholder="/"
        :value="form.childrenSupportingArea"
        :required="false"
        :inputProps="{ readonly: true }" />

      <HeaderTitle style="margin: 20px 0" title="关联附件" />

      <required-form-item-upload-file
        v-if="files.taxpayerProveFileList.length > 0"
        v-model="files.taxpayerProveFileList"
        class="formItem"
        label="上传附件"
        limit-size="400"
        disabled
        :required="false">
        <div slot="describe">
          1：一般纳税人登记证 或 <br />
          2：税务登记事项通知书或 <br />
          3：网站“全国企业一般纳税人资格查询截图或 <br />
          4：其他途径截图/照片
        </div>
      </required-form-item-upload-file>

      <template
        v-for="(fileLabel, fileKey) in {
          fileList: '店铺含外围照片不低于5张或视频',
          bankFileList: '开户证明或者网银截图(能显示开户银行全称)',
          drawingAndReportAndTalkFileList: '图纸，考察报告，考察谈话录音',
          promiseAndStoreDrawingFileList: '承诺说明书，商场店面图纸',
          fileRecordList: '老经销商附件',
          businessLicenseFileList: '营业执照照片/电子档',
          shopOutsideFileList: '店铺含外围照片不低于5张或视频',
          shopMarketInsideLocationFileList: '商场平面图标注慕思店位置',
          shopCADFileList: '人流动向店面CAD图纸',
          shopLeaseContractFileList: '店铺租赁合同',
          integrityCommitmentFileList: '廉洁承诺书',
          bankStatementFileList: '银行流水',
          teamPhotoFileList: '团队照片',
          independentShopDoorHeaderFileList: '独立店标注门头位置',
          openShopPromiseFileList: '开店承诺书',
          vmdDesignFileList: 'VMD设计需求表',
          dealerBestsignCertificatePhotoFileList: '经销商上上签认证截图',
          cityDealerAgreementFileList: '一城多商的协议',
          newBusinessContractFileList: '新商合同',
          shopPositionList: '商场平面图标注慕思位置及人流动向、独立店地图定位',
          shopCadList: '店铺CAD图纸',
          shopLeaseContractList: '店铺租赁合同',
          paymentVoucherList: '新商预打款凭证',
          planeList: '商场平面图标注慕思位置及人流动向、独立店地图定位',
          cadList: '店铺CAD图纸',
          leaseContractList: '店铺租赁合同',
          commitmentList: '开店承诺书',
          vmdList: 'VMD设计需求表',
          fileList1: '店铺含外围照片不低于5张或视频',
          fileList2: '商场平面图标注慕思位置及人流动向、独立店地图定位',
          fileList3: '店铺CAD图纸',
          fileList4: '店铺租赁合同',
          fileList5: '新商预打款凭证',
          fileList6: '开店承诺书',
          fileList7: 'VMD设计需求表',
          fileList8: '二级分销商身份证照片、分销协议',
          fileList9: '门店一镜到底视频'
        }">
        <required-form-item-upload-file
          v-if="files[fileKey].length > 0"
          v-model="files[fileKey]"
          class="formItem"
          :label="fileLabel"
          :required="false"
          :key="fileKey"
          disabled
          limit-size="400" />
      </template>
    </el-form>
    <div style="height: 80px"></div>
    <div class="footer" :class="{ oa: isOA }">
      <el-button
        v-if="$route.query.admin"
        :loading="isLoading"
        type="primary"
        @click="onReset">
        重新提交
      </el-button>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { mapGetters, mapState } from 'vuex'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import HeaderTitle from './components/headerTitle.vue'

import * as ShopDeviseApi from '@/api/flow/shopDevise'
import * as CommonApi from '@/api/common/'

export default {
  name: 'ShopDeviseFlow',
  components: {
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequestModuleSelect,
    HeaderTitle
  },
  data() {
    return {
      form: {
        decorationStatus: '1',
        invalid: '',
        isNewDevise: '0',
        flowStatus: '0',
        displayChange: '0',
        supervisorName: '',
        urgentLevelText: ''
      },
      tableData: [],
      keys: {
        title: '标题',
        orderNumber: '单号',
        zsOrderNumber: '招商单号',
        urgentLevelText: '紧急程度',
        linkOrderTitle: '关联流程',
        linkOrderNumber: '关联流程单号',
        optJobNumber: '提单人工号',
        optUserName: '提单人',
        optDeptName: '提单人部门',
        // urgentExplaintext: "紧急说明",
        businessLicenseName: '售达方',
        realName: '经销商（实际控股人）',
        contactInfo: '经销商联系方式',
        bossNumber: '经销商账号',
        authorizedCityName: '授权城市',
        marketLevelName: '城市等级',
        contactPerson: '门店负责人',
        contactPersonPhone: '门店负责人联系方式',
        province: '省份',
        city: '地级市',
        area: '区/县',
        brandNameList: '门店设计品牌'
        // ifClassicalFlow:'是否经典流程', // 1是0否
      },
      isdisplayShopType: '', //是否展示店
      isLoading: false,
      //记录附件
      files: {
        businessLicenseFileList: [], //营业执照照片/电子档
        /* 1：一般纳税人登记证 或2：税务登记事项通知书或 3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片 */
        taxpayerProveFileList: [],
        bankFileList: [] /* 开户证明或者网银截图(能显示开户银行全称) */,
        drawingAndReportAndTalkFileList: [] /* 图纸，考察报告，考察谈话录音 */,
        promiseAndStoreDrawingFileList: [] /* 承诺说明书，商场店面图纸 */,
        // 加盟
        shopOutsideFileList: [], //店铺含外围照片不低于5张或视频
        shopMarketInsideLocationFileList: [], //商场平面图标注慕思店位置
        shopCADFileList: [], //人流动向店面CAD图纸
        shopLeaseContractFileList: [], //店铺租赁合同
        integrityCommitmentFileList: [], //廉洁承诺书
        bankStatementFileList: [], //银行流水
        teamPhotoFileList: [], // 团队照片
        independentShopDoorHeaderFileList: [], //独立店标注门头位置
        openShopPromiseFileList: [], //开店承诺书
        vmdDesignFileList: [], //VMD设计需求表
        dealerBestsignCertificatePhotoFileList: [], //经销商上上签认证截图
        cityDealerAgreementFileList: [], //一城多商的协议
        newBusinessContractFileList: [], //新商合同
        //门店系列管理
        fileRecordList: [], //店铺含外围照片不低于5张或视频（旧名称）
        shopPositionList: [], //商场平面图标注慕思位置及人流动向、独立店地图定位
        shopCadList: [], // 店铺CAD图纸
        shopLeaseContractList: [], //店铺租赁合同
        paymentVoucherList: [], // 新商预打款凭证
        //重装附件
        fileList: [], //默认旧数据，店铺含外围照片不低于5张或视频（必填）
        planeList: [], //商场平面图标注慕思位置及人流动向、独立店地图定位
        cadList: [], //店铺CAD图纸（必填）
        leaseContractList: [], //店铺租赁合同
        commitmentList: [], //开店承诺书
        vmdList: [], //VMD设计需求表
        // 新店
        fileList1: [], //店铺含外围照片不低于5张或视频（必填）
        fileList2: [], //商场平面图标注慕思位置及人流动向、独立店地图定位
        fileList3: [], //店铺CAD图纸（必填）
        fileList4: [], //店铺租赁合同
        fileList5: [], //新商预打款凭证
        fileList6: [], //开店承诺书
        fileList7: [], //VMD设计需求表
        fileList8: [], //二级分销商身份证照片、分销协议
        fileList9: [] //门店一镜到底视频
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('dealershipTransfer', ['isOA']),
    isClassical() {
      return this.form.ifClassicalFlow && this.form.ifClassicalFlow == 1
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.getFlowDetail()
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    setFormData(obj) {
      const formData = new FormData()
      for (const key in obj) {
        formData.append(key, obj[key])
      }
      return formData
    },
    async getFlowDetail() {
      const { id } = this.$route.query
      if (!id) return
      const { code, data } = await ShopDeviseApi.getFlowDetail({
        declarationFormId: id
      })
      if (code != 0) return
      const {
        declarationForm = {},
        shopId,
        historyId,
        shopDecoration,
        shopDecorationBrand,
        childrenSupportingArea,
        childrenSupportingAreaFlag,
        title,
        licenseNumber, // 售达方编号
        brandNameList = [], //品牌数组
        partyBrandNameList = [], //已代理品牌
        cityLevelList = [], //城市等级信息
        ...args
      } = data

      Object.keys(this.files).forEach(key => {
        if (data[key]) this.files = { ...this.files, [key]: data[key] }
      })
      console.log('files', this.files)

      const { urgentLevel = 0 } = declarationForm
      if (!shopId || shopId == '') return
      const shopResult = await ShopDeviseApi.getShopDetail({ shopId })
      if (shopResult.code != 0) {
        this.form = {
          ...this.form,
          ...args,
          ...declarationForm
        }
        return
      }
      const { shop, storeOwnerDetails = {} } = shopResult.data
      const {
        shopModelList,
        shopDrawingsFileList,
        partnership,
        province = '',
        city = '',
        area = '',
        street = '',
        ...shopArgs
      } = shop

      const { realName, contactInfo, businessLicenseName, bossNumber } =
        storeOwnerDetails

      this.form = {
        ...this.form,
        ...args,
        ...declarationForm,
        ...shopArgs,
        province,
        city,
        area,
        realName,
        contactInfo,
        businessLicenseName,
        bossNumber,
        childrenSupportingArea,
        childrenSupportingAreaFlag,
        urgentLevelText: urgentLevel == 0 ? '正常' : '紧急',
        brandNameList: brandNameList.join('、'),
        linkOrderTitle: title,
        partyBrandNameList: partyBrandNameList.join('、'),
        marketLevelName: cityLevelList[0]?.marketLevelName // 城市等级默认显示第一条
      }

      this.tableData = [
        {
          ...args,
          ...shopArgs,
          province,
          city,
          area,
          street,
          partnership,
          shopAddress: `${city || ''}${area || ''}${street || ''}${
            shop.shopTypeValue || ''
          }${shop.shopFloor || ''}${shop.shopFloorNumber || ''}（${
            shop.brandName
          }）专卖店`
        }
      ]
      console.log('tableData', this.tableData)
    },
    async getPresuTypeText(params) {
      try {
        const { code, data } = await CommonApi.getAnyTypeList({
          module: 'distributor',
          ...params
        })
        if (code != 0 || data.length == 0) return ''
        return data[0].content
      } catch (error) {
        console.log(error)
        return ''
      }
    },
    onPreview(file) {
      window.open(file.url)
    },
    async onReset() {
      try {
        this.isLoading = true
        const { id } = this.$route.query
        await CommonApi.onResubmit({ declarationFormId: id })
        this.isLoading = false
        this.$message.success('提交成功')
        this.$router.back()
      } catch (error) {
        this.isLoading = false
        this.$message.error('提交失败')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.oa::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff;
}

.file-col {
  margin-bottom: 16px;
}

.flow-form {
  // .flow-form__item{
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  //   margin-bottom:60px;
  // }
  ::v-deep {
    .el-table__row {
      .el-form-item {
        width: 100%;
        margin: 0;
        &.is-error {
          margin-bottom: 22px;
        }
      }
    }
    @media (max-width: 702px) {
      .formItem {
        width: calc(100% - 10px);
      }
    }

    @media screen and (min-width: 702px) and (max-width: 1260px) {
      .formItem {
        width: calc(33% - 10px);
      }
    }
    @media (min-width: 1260px) {
      .formItem {
        width: calc(25% - 10px);
      }
    }
    @media (min-width: 1500px) {
      .formItem {
        width: calc(20% - 10px);
      }
    }
  }
}

.flow-title {
  margin-bottom: 20px;
}
.row-wrap {
  margin-top: 20px;
}
.row-wrap::v-deep.el-row--flex {
  flex-wrap: wrap;
}
.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background-color: #fff;
  z-index: 99;
}
.formItem::v-deep.el-form-item {
  margin-bottom: 0;
}
.footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 10px;
  &.oa {
    display: none;
  }
}
</style>
