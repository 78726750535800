<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :inline="true"
      label-position="top"
      class="flow-form">
      <header-title title="转让事项确认" />
      <el-table
        :data="form.list"
        style="width: 100%; margin-bottom: 22px"
        border>
        <el-table-column label="未出订单接收人">
          <template slot-scope="scope">
            <RequiredModuleFormItemSelect
              :prop="`list.${scope.$index}.orderRecipient`"
              label="未出订单接收人"
              :show-label="false"
              v-model="scope.row.orderRecipient"
              type="dealer_type"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="售后问题处理">
          <template slot-scope="scope">
            <RequiredModuleFormItemSelect
              :prop="`list.${scope.$index}.afterSales`"
              label="售后问题处理"
              :show-label="false"
              v-model="scope.row.afterSales"
              type="dealer_type"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="之前转单信息">
          <template slot-scope="scope">
            <RequiredModuleFormItemSelect
              :prop="`list.${scope.$index}.previousTransferInformation`"
              label="之前转单信息"
              :show-label="false"
              v-model="scope.row.previousTransferInformation"
              type="dealer_type"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column label="门店是否转让（新经销商是否接老店）">
          <template slot-scope="scope">
            <RequiredFormItemSelect
              :prop="`list.${scope.$index}.shopChange`"
              label="门店是否转让（新经销商是否接老店）"
              :show-label="false"
              v-model="scope.row.shopChange"
              @change="changeShopStatus($event, scope.$index)"
              :disabled="isOA"
              labelKey="label"
              valueKey="value"
              :dataList="type" />
          </template>
        </el-table-column>
        <el-table-column label="公司账务负责人">
          <template slot-scope="scope">
            <RequiredModuleFormItemSelect
              :prop="`list.${scope.$index}.bookkeeper`"
              label="公司账务负责人"
              :show-label="false"
              v-model="scope.row.bookkeeper"
              type="dealer_type"
              :disabled="isOA" />
          </template>
        </el-table-column>
      </el-table>
      <RequiredFormItemUploadFile
        style="width: 100%"
        prop="list.0.idCardFileList"
        :required="false"
        class="formItem"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview"
        v-model="form.list[0].idCardFileList"
        @onUploaded="
          () => {
            $refs['form'].validateField(`list.0.idCardFileList`)
          }
        ">
        <div slot="describe">（接店新经销商和法人身份证）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
      <RequiredFormItemUploadFile
        style="width: 100%"
        prop="list.0.signChangeFileList"
        :required="false"
        class="formItem"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview"
        v-model="form.list[0].signChangeFileList"
        @onUploaded="
          () => {
            $refs['form'].validateField(`list.0.signChangeFileList`)
          }
        ">
        <div slot="describe">（签字版转让申请表）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
      <RequiredFormItemUploadFile
        style="width: 100%"
        prop="list.0.promiseFileList"
        :required="false"
        class="formItem"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview"
        v-model="form.list[0].promiseFileList"
        @onUploaded="
          () => {
            $refs['form'].validateField(`list.0.promiseFileList`)
          }
        ">
        <div slot="describe">（廉洁承诺书）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
      <RequiredFormItemUploadFile
        style="width: 100%"
        prop="list.0.changeFileList"
        :required="false"
        class="formItem"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview"
        v-model="form.list[0].changeFileList"
        @onUploaded="
          () => {
            $refs['form'].validateField(`list.0.changeFileList`)
          }
        ">
        <div slot="describe">（1、转让合同；2、团队照片）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
      <RequiredFormItemUploadFile
        style="width: 100%"
        prop="list.0.bankFlowFileList"
        :required="false"
        class="formItem"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview"
        v-model="form.list[0].bankFlowFileList"
        @onUploaded="
          () => {
            $refs['form'].validateField(`list.0.bankFlowFileList`)
          }
        ">
        <div slot="describe">（新经销商银行流水）</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemSelect,
  RequiredModuleFormItemSelect,
  RequiredFormItemInput,
  RequiredFormItemUploadFile
} from '@/components/CustomFormItem'
import { mapActions, mapMutations, mapState } from 'vuex'
import { filesRules } from '@/utils/useFormValidator'
import HeaderTitle from './headerTitle.vue'
import { getShopList } from '@/api/common'
export default {
  props: {
    formStatus: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    HeaderTitle,
    RequiredFormItemInput,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect
  },
  data() {
    return {
      form: {
        list: [
          {
            orderRecipient: '', //未出订单接收人
            afterSales: '', //售后问题处理
            previousTransferInformation: '', //之前转单信息
            shopChange: '', //门店是否转让（新经销商是否接老店）
            bookkeeper: '', //公司账务负责人
            idCardFileList: [],
            signChangeFileList: [],
            promiseFileList: [],
            changeFileList: [], //转让协议,
            bankFlowFileList: [] //银行流水
          }
        ]
      },
      rules: [
        { required: true, message: '必填项', trigger: ['blur', 'change'] }
      ],
      filesRules: filesRules,
      fieldNameArr: [
        'idCardFileList',
        'signChangeFileList',
        'promiseFileList',
        'changeFileList',
        'bankFlowFileList'
      ]
    }
  },
  watch: {
    historicalData: {
      handler(historicalData) {
        for (const key in this.form.list[0]) {
          if (this.fieldNameArr.indexOf(key) != -1) {
            if (historicalData[key]) {
              this.form.list[0][key] = historicalData[key]
            }
          } else if (key === 'shopChange') {
            this.form.list[0][key] = historicalData[key] ? 1 : 0
          } else {
            this.form.list[0][key] = historicalData[key]
          }
        }
      },
      deep: true
    },
    authorizedCityParams: {
      handler(authorizedCityParams) {
        if (
          this.form.list[0].shopChange &&
          JSON.stringify(authorizedCityParams) != '{}'
        ) {
          console.log('转让')
          if (this.formStatus !== 40) {
            this.getShopList().then(() => {
              if (JSON.stringify(this.historicalData) !== '{}') {
                const { shopList } = this.historicalData
                let shopIdList = shopList.map(item => {
                  return item.id
                })
                let list = []
                if (this.formStatus === 0 || this.formStatus === 20) {
                  list = this.shopList.map(sitem => {
                    let i = shopIdList.indexOf(sitem.id)
                    if (i > -1) {
                      let {
                        contactPerson = '',
                        contactPersonIdcard = '',
                        contactPersonPhone = '',
                        shopTel,
                        managementModel
                      } = shopList[i]
                      let baseObj = {
                        ...sitem,
                        shopChange: 1,
                        contactPerson,
                        contactPersonIdcard,
                        contactPersonPhone,
                        shopTel: shopTel ? shopTel : sitem.shopTel,
                        managementModel: managementModel
                          ? managementModel
                          : sitem.managementModel
                      }
                      return baseObj
                    } else {
                      return sitem
                    }
                  })
                } else {
                  list = this.shopList
                    .filter(sitem => shopIdList.indexOf(sitem.id) > -1)
                    .map(mitem => {
                      let i = shopIdList.indexOf(mitem.id)
                      let {
                        contactPerson = '',
                        contactPersonIdcard = '',
                        contactPersonPhone = '',
                        shopTel,
                        managementModel
                      } = shopList[i]
                      let baseObj = {
                        ...mitem,
                        shopChange: 1,
                        contactPerson,
                        contactPersonIdcard,
                        contactPersonPhone,
                        shopTel: shopTel ? shopTel : mitem.shopTel,
                        managementModel: managementModel
                          ? managementModel
                          : mitem.managementModel
                      }
                      return baseObj
                    })
                }

                this.setShopList(list)
                this.setTransferShopStatus(true)
              }
            })
          } else {
            if (JSON.stringify(this.historicalData) !== '{}') {
              const { shopList, shopIdList } = this.historicalData
              let shopIds = ''
              if (shopIdList) {
                shopIds = shopIdList.join(',')
              } else {
                shopIds = shopList.map(item => item.id).join(',')
              }
              getShopList({ shopIds, shopStatus: -1 }).then(res => {
                if (res.code === 0) {
                  if (res.data.length) {
                    const list = res.data.map(item => {
                      return {
                        ...item,
                        shopChange: 1
                      }
                    })
                    this.setShopList(list)
                  } else {
                    this.setShopList([])
                  }
                  this.setTransferShopStatus(true)
                }
              })
            }
          }
        } else {
          this.setShopList([])
          this.setTransferShopStatus(false)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('data', ['type']),
    ...mapState('dealershipTransfer', [
      'isOA',
      'historicalData',
      'authorizedCityParams',
      'shopList'
    ])
  },
  methods: {
    ...mapMutations('dealershipTransfer', [
      'setTransferShopStatus',
      'setShopList'
    ]),
    ...mapActions('dealershipTransfer', ['getShopList']),
    onPreview(file) {
      window.open(file.url)
    },
    validFiles(rule, val, callback) {
      if (val.length > 0) {
        callback()
      } else {
        callback(new Error('必填项'))
      }
    },
    changeShopStatus(val, index) {
      if (val) {
        this.setTransferShopStatus(true)
        if (JSON.stringify(this.authorizedCityParams) != '{}') {
          this.getShopList()
        }
      } else {
        this.setShopList([])
        this.setTransferShopStatus(false)
      }
    }
  }
}
</script>
