import { render, staticRenderFns } from "./storeManagementFlow.vue?vue&type=template&id=2dc1c15f&scoped=true"
import script from "./storeManagementFlow.vue?vue&type=script&setup=true&lang=js"
export * from "./storeManagementFlow.vue?vue&type=script&setup=true&lang=js"
import style0 from "./storeManagementFlow.vue?vue&type=style&index=0&id=2dc1c15f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc1c15f",
  null
  
)

export default component.exports