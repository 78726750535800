<template>
  <div style="padding-bottom: 90px">
    <div class="tip">
      <div>
        1、{{ form.dealerType === 0 ? '老经销商' : '新经销商' }}增加新店申请表{{
          type ? '(直营)' : ''
        }}：经销商根据业务拓展需求新开门店经营
      </div>

      <div>
        2、如经销商是在原来的门店隔壁拿到新的位置，两个门店隔着墙，这种在门店统计标准为一个店，请走“门店系列管理申请表”
      </div>
    </div>
    <!--
      :title="`老经销商增加新店申请表（${authorizedCityMsg.authorizedCityName||''}，${businessMsg.legalPersonName||''}，门店品牌：${brandData.map(item=>item.name).join(',')}）`" -->
    <BaseMessage
      :value="form.title"
      :title="form.title ? form.title : title"
      :zsOrderNumber="form.zsOrderNumber"
      :number="orderNumber" />
    <!-- <GoBack /> -->
    <el-form
      class="flow-form"
      :model="form"
      :inline="true"
      ref="ruleForm"
      label-position="top"
      @validate="handleValidate">
      <HeaderTitle style="margin-top: 10px" title="经销商个人基础信息" />
      <BaseFormSelect :value="form.optJobNumber" label="提单人工号" readonly />
      <BaseFormSelect :value="form.optUserName" label="提单人" readonly />
      <BaseFormSelect :value="form.optDeptName" label="提单部门" readonly />
      <BaseFormSelect :value="optTime" label="提单日期" readonly />
      <BusinessItemSelect
        v-model="form.businessLicenseId"
        prop="businessLicenseId"
        @onChange="handleChangeBusiness"
        @onChangeItem="
          () => {
            form.shopList = []
            authorizedCityMsg = {}
            form.brands = []
            brandData = []
            marketLevelName = ''
          }
        "
        :initSuccess="handleSuccess" />
      <BaseFormSelect
        :value="businessMsg.businessLicenseName"
        label="售达方名称"
        disabled />
      <BaseFormSelect
        :value="businessMsg.realName"
        label="经销商（实际控股人）"
        disabled />
      <BaseFormSelect
        :value="businessMsg.contactInfo"
        label="经销商（实际控股人）联系方式"
        disabled />
      <BaseFormSelect
        v-if="businessMsg.tencentQq || businessMsg.eMail"
        :value="businessMsg.tencentQq || businessMsg.eMail"
        label="QQ/电邮"
        disabled />
      <CommonSelect
        prop="createShopType"
        class="formItem"
        label="开店类型"
        v-model="form.createShopType"
        type="create_store_type"
        :disabled="!form.businessLicenseId"
        @changeSelectedItem="
          value => {
            handleChangeCreateShopType(value)
          }
        " />
      <BaseFormSelect
        :value="businessMsg.legalPersonName"
        label="法人"
        disabled />
      <!-- <CommonSelect prop="urgentLevel" class="formItem" label="紧急程度" v-model="form.urgentLevel" type="urgent_level" />
      <required-form-item-input delay-update class="formItem" label="紧急说明" prop="urgentExplain"
        :required="Number(form.urgentLevel) === 2" v-model="form.urgentExplain"
        v-show="Number(form.urgentLevel) == 2" /> -->
      <div v-if="form.createShopType != '2'">
        <HeaderTitle style="margin-top: 10px" title="申请开店情况" />
        <required-form-item-select
          prop="authorizedCityCode"
          class="formItem"
          label="授权城市确认"
          :dataList="authorizedCityList"
          v-model="form.authorizedCityCode"
          :labelKey="item => `${item.city}${item.area || ''}${item.town || ''}`"
          valueKey="authorizedCityCode"
          :selectProps="{
            clearable: true
          }"
          @changeSelectedItem="
            value => {
              authorizedCityMsg = value ? value : {}
            }
          "
          @onChangeItem="
            value => {
              form.brands = []
              brandData = []
              marketLevelName = ''
              // handleGetMarketLevel(brandData)
            }
          " />
        <!-- <BaseFormSelect
          v-if="$refs.brandSelect"
          :value="
            $refs.brandSelect.saveBrandList.map(item => item.name).join(',')
          "
          label="已代理品牌"
          disabled /> -->
        <el-form-item label="已代理品牌" class="formItem">
          <div style="min-height: 33px">
            {{
              $refs.brandSelect &&
              $refs.brandSelect.saveBrandList.map(item => item.name).join(',')
            }}
          </div>
        </el-form-item>
        <BrandSelect
          ref="brandSelect"
          filter
          label="新店品牌确认"
          v-model="form.brands"
          :authorizedCityCode="form.authorizedCityCode"
          :businessLicenseId="form.businessLicenseId"
          :status="status"
          prop="brands"
          @onChangeSelectedItem="
            data => {
              newShopBrandGet(data)
            }
          " />
        <BaseFormSelect
          v-if="marketCenterData.length > 0"
          :value="
            marketCenterData
              .filter(item => item.smallName)
              .map(item => item.smallName)
              .join('、')
          "
          label="营销中心"
          disabled />
        <RequiredFormItemInput
          v-model="form.brandRegionCode"
          class="formItem"
          label="品牌区域编码"
          disabled />
        <BaseFormSelect
          v-model="form.marketLevelName"
          label="市场级别判定"
          disabled
          placeholder="请选择新店品牌" />
        <BaseFormSelect
          v-if="marketCenterData.length > 0"
          :value="
            marketCenterData
              .filter(item => item.smallName)
              .map(item => item.smallName)
              .join('、')
          "
          label="营销中心"
          disabled />
        <BaseFormSelect
          :value="authorizedCityMsg.province"
          label="省份"
          disabled />
        <BaseFormSelect
          :value="authorizedCityMsg.city"
          label="地级市"
          disabled />
        <BaseFormSelect
          :value="businessMsg.bankAccount"
          label="银企直连账户"
          disabled />
        <!-- <BaseFormSelect
          :value="businessMsg.licenseNumber"
          label="售达方"
          disabled
        /> -->
        <BaseFormSelect
          :value="businessMsg.bossNumber"
          label="OA账户"
          disabled />

        <BaseFormSelect :value="shopBrandNumber" label="送达方编号" disabled />
        <required-form-item-select
          v-if="!isOA"
          prop="linkOrderNumber"
          class="formItem"
          label="关联撤店单号（若换位重装开新店请填写关联撤店单号）"
          :getDataList="getFlowList"
          :getNextList="getFlowList"
          :totalCount="flowTotalCount"
          v-model="form.linkOrderNumber"
          :selectProps="{
            filterable: true,
            remote: true,
            clearable: true,
            'popper-class': 'custom-business-select'
          }"
          labelKey="orderNumber"
          valueKey="orderNumber"
          @onOptionsListNotFound="onOptionsListNotFound"
          :required="false"
          @changeSelectedItem="
            e => {
              if (!e) return
              oaRequestId = e.oaRequestId
            }
          " />
        <el-form-item v-if="isOA" class="formItem" label="关联撤店单号">
          <a @click="oaDetail(form.linkOrderNumber)" href="#">{{
            form.linkOrderNumber
          }}</a>
        </el-form-item>
        <RequiredFormItemSelect
          v-show="
            (brandData && brandData.find(item => item.name.includes('V6'))) ||
            false
          "
          prop="dealerType"
          class="formItem"
          label="是否为新商"
          v-model="form.dealerType"
          :dataList="[
            {
              label: '是',
              value: 1
            },
            {
              label: '否',
              value: 0
            }
          ]"
          labelKey="label"
          valueKey="value"
          @changeSelectedItem="value => {}"
          :required="false" />
      </div>
      <NewShopTable
        ref="newShopTable"
        :table-layout="isMobile ? 'vertical' : 'horizontal'"
        :businessMsg="businessMsg"
        :authorizedCityMsg="authorizedCityMsg"
        :brandData="brandData"
        :tableShopList.sync="form.shopList"
        :shopTitleBrand.sync="shopTitleBrand"
        :dictionaryList="dictionaryList"
        :authorizedBrandList="
          $refs.brandSelect && $refs.brandSelect.saveBrandList
        " />
      <div v-if="type">
        <HeaderTitle style="margin-top: 10px" id="anchor-bz" title="直营情况" />
        <div>
          <CommonSelect
            :prop="`assessmentfFiling.directCompany`"
            class="formItem"
            label="直营公司"
            v-model="form.assessmentfFiling.directCompany"
            type="direct_company" />
          <CommonSelect
            :prop="`assessmentfFiling.invoiceType`"
            class="formItem"
            label="发票类型"
            v-model="form.assessmentfFiling.invoiceType"
            type="invoice_type"
            module="public" />
          <DateSelect
            :prop="`assessmentfFiling.openingDate`"
            class="formItem"
            label="预约开业时间"
            v-model="form.assessmentfFiling.openingDate" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.shopOwnerCount"
            label="预计店长数量"
            :prop="`assessmentfFiling.shopOwnerCount`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.clerkCount"
            label="预计店员数量"
            :prop="`assessmentfFiling.clerkCount`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.wages"
            label="预计员工工资（月度）(店长+店员月度工资总额)"
            :prop="`assessmentfFiling.wages`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.areaTotal"
            label="门店平方数（签约数量）"
            :prop="`assessmentfFiling.areaTotal`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.managementFee"
            label="租金管理费（月度）(月度实际支付租金管理费）"
            :prop="`assessmentfFiling.managementFee`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.depositFee"
            label="店面押金金额（万）(押金、质保金)"
            :prop="`assessmentfFiling.depositFee`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.renovationFee"
            label="装修投入（万）"
            :prop="`assessmentfFiling.renovationFee`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.goodsAmount"
            label="铺货样品金额（万）"
            :prop="`assessmentfFiling.goodsAmount`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.ornamentsAmount"
            label="铺货饰品金额（万）"
            :prop="`assessmentfFiling.ornamentsAmount`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.yearTotal"
            label="预计年度收入（万）"
            :prop="`assessmentfFiling.yearTotal`"
            type="number"
            :required="true" />
          <BaseFormSelect
            delay-update
            v-model="form.assessmentfFiling.investment"
            label="初始投资总额（万）"
            :prop="`assessmentfFiling.investment`"
            type="number"
            :required="true" />
          <BaseFormSelect
            :value="shouRuBi"
            label="租金收入比年度收入/年度租金"
            readonly />
          <CommonSelect
            class="formItem"
            label="租赁备案证是否办理"
            :prop="`assessmentfFiling.leaseFiling`"
            type="flow_common_select_ifEnable"
            v-model="form.assessmentfFiling.leaseFiling"
            @change="data => handleLease(data)" />
          <BaseFormSelect
            v-show="form.assessmentfFiling.leaseFiling === '0'"
            delay-update
            v-model="form.assessmentfFiling.leaseFilingReason"
            label="租赁备案证未办理原因"
            :prop="`assessmentfFiling.leaseFilingReason`"
            :required="
              form.assessmentfFiling.leaseFiling === '0' ? true : false
            " />
          <UploadFile
            v-show="form.assessmentfFiling.leaseFiling === '1'"
            class="formItem"
            label="上传租赁备案证"
            v-model="form.assessmentfFiling.fileList"
            :required="
              form.assessmentfFiling.leaseFiling === '1' ? true : false
            "
            @onUploaded="file => handleUpload(file)"
            :prop="`assessmentfFiling.fileList`"
            :disabled="false">
            <div slot="tip">单文件不能超过400M</div>
          </UploadFile>
          <RequiredFormItemInput
            delay-update
            class="formItem"
            v-model="form.assessmentfFiling.introduce"
            label="开设该门店的理由与情况介绍"
            :prop="`assessmentfFiling.introduce`"
            :required="true"
            :inputProps="{
              type: 'textarea',
              resize: 'none',
              rows: 6,
              maxlength: '500',
              showWordLimit: true
            }"
            style="width: 100%" />
        </div>
      </div>
      <RemarkItem
        v-model="form.remark"
        :fileList="form.fileRecordList"
        @onUpload="
          file => {
            form.fileRecordList = file.map(item => item.response)
          }
        " />
    </el-form>
    <div class="fix-footer" v-if="!isOA">
      <el-button type="primary" @click="save(10)">提交</el-button>
      <el-button type="info" @click="save(0)">保存</el-button>
      <el-button style="margin-left: 20px" @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import BaseMessage from './components/baseMessage'
import GoBack from '@/components/goBack'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import HeaderTitle from './components/headerTitle'
// import AddressSelect from './components/addressSelect'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import BaseFormSelect from '@/components/BaseSelect/baseFormSelect'
import BusinessItemSelect from '@/components/BaseSelect/businessItemSelect'
import BrandSelect from '@/components/BaseSelect/brandSelect'
import CommonSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import DateSelect from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import NewShopTable from './components/newShopTable'
import RemarkItem from './components/remarkItem'
// import HeaderTitle from './headerTitle'
import moment from 'moment'
import { positionToError, postMessageIframe } from '@/utils/index'
import { mapGetters, mapState } from 'vuex'
import { saveDraftData, getDraftDetial } from '@/api/flow'
import { getShopListPage } from '@/api/business/draft'
import {
  getAuthorizedCityData,
  getShopBrandData,
  datadictionaryList,
  requestGetSizeBarListByDept,
  requestGetCityLevel,
  requestCityAndBrandFindMarket
} from '@/api/common'
import {
  getStorage,
  getSession,
  setSession,
  removeSession
} from '@/utils/storage'
import { getMasterDataList } from '@/api/businessOos'
export default {
  components: {
    BaseMessage,
    GoBack,
    HeaderTitle,
    UploadFile,
    // AddressSelect,
    RequiredFormItemSelect,
    BaseFormSelect,
    BusinessItemSelect,
    RequiredFormItemInput,
    BrandSelect,
    CommonSelect,
    NewShopTable,
    RemarkItem,
    HeaderTitle,
    DateSelect
  },
  data() {
    return {
      flowTotalCount: 0,
      oaRequestId: '',
      marketLevelName: '',
      form: {
        brandRegionCode: '',
        zsOrderNumber: '',
        brands: [],
        shopList: [],
        createShopType: '',
        authorizedCityCode: '',
        storeType: '',
        dealerType: 0,
        channel: '',
        ifOldShopId: '0',
        oldShopId: '',
        linkOrderNumber: '',
        marketCenterCode: '',
        marketCenterName: '',
        marketLevelName: '',
        marketLevelCode: '',
        fileRecordList: [],
        assessmentfFiling: {
          // 直营公司
          directCompany: '',
          // 预约开业时间
          openingDate: '',
          // 预计店长
          shopOwnerCount: '',
          // 预计店员
          clerkCount: '',
          // 预计员工工资
          wages: '',
          // 签约面积
          areaTotal: '',
          // 租金管理费
          managementFee: '',
          // 押金金额
          depositFee: '',
          // 装修投入
          renovationFee: '',
          // 是否有租赁备案
          leaseFiling: 0,
          // 租赁证
          fileList: [],
          // 未备案理由
          leaseFilingReason: '',
          // 货品金额
          goodsAmount: '',
          // 饰品金额
          ornamentsAmount: '',
          // 年度收入
          yearTotal: '',
          // 发票类型
          invoiceType: '',
          // 投资总额
          investment: '',
          // 介绍
          introduce: ''
        }
      },
      formData: {},
      businessMsg: {},
      authorizedCityList: [],
      authorizedCityMsg: {},
      shopTitleBrand: [],
      brandData: [],
      // fullscreenLoading: false,
      // loading: null,
      flagVisable: true, // 表单验证
      optTime: '', // 提单时间
      shopBrandNumberList: [], // 当前送达方列表
      shopBrandNumber: '', // 选取送达方
      orderNumber: '', // 流程单号
      dictionaryList: [],
      status: 0, //表单状态值
      isCache: true, // 是否使用session缓存
      type: '',
      marketCenterData: [] // 营销中心数据
    }
  },
  watch: {
    'form.businessLicenseId'(newVal, oldVal) {
      if (this.id) {
        if (oldVal && newVal != oldVal) {
          this.initForm()
        }
      } else {
        if (!oldVal) {
          this.initForm()
        }
      }
    },
    'form.createShopType': {
      // immediate:true,
      handler(newVal, oldVal) {
        if (newVal == '2') {
          this.form.shopList = []
          this.authorizedCityMsg = {}
          this.form.brands = []
          this.shopTitleBrand = []
        } else if (oldVal == '2') {
          this.form.shopList = []
          this.brandData = []
          this.shopTitleBrand = []
        }
      }
    },
    'form.dealerType': {
      handler(value) {
        if (value === 1) {
          this.form.title.replace('老', '新')
        } else {
          this.form.title.replace('新', '老')
        }
      }
    },
    title: {
      deep: true,
      handler(value) {
        // console.log(value);
      }
    },
    authorizedCityMsg: {
      deep: true,
      async handler(value) {
        if (value.authorizedCityCode) {
          const res = await getMasterDataList({
            businessLicenseId: this.businessMsg.id,
            authorizedCityCode: value.authorizedCityCode
          })
          this.shopBrandNumberList = res.data
        }
      }
    },
    brandAreaCode: {
      deep: true,
      async handler({ brandData, authorizedCityMsg }) {
        if (this.isOA) {
          return this.form.brandRegionCode
        }
        if (brandData.length > 0) {
          const brandIds = [...new Set(brandData.map(item => item.bigBrandId))]
          const res = await requestCityAndBrandFindMarket({
            brandIds: brandIds.join(','),
            provinceCode: authorizedCityMsg.provinceCode,
            cityCode: authorizedCityMsg.cityCode,
            areaCode: authorizedCityMsg.areaCode
          })
          this.form.brandRegionCode = res.data
            .map(item => item.brandRegionCode)
            .join('、')
          this.marketCenterData = res.data
          this.form.marketCenterCode = res.data
            .map(item => item.smallCode)
            .join(',')
          this.form.marketCenterName = res.data
            .map(item => item.smallName)
            .join(',')
        }
      }
    }
  },
  computed: {
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      isMobile: state =>
        state.app.device === 'mobile' || state.app.isWxworkMoblie
    }),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    getDealer({ brandData }) {
      let str = ''
      let brand = brandData.map(item => item.bigBrandName)
      brand = [...new Set(brand)]
      brand.forEach(item => {
        str += `${this.authorizedCityMsg.city}${
          this.authorizedCityMsg.area || ''
        }-${this.businessMsg.legalPersonName}-${item}<br />`
      })
      return str
    },
    title({ businessMsg, authorizedCityMsg, shopTitleBrand }) {
      const city =
        this.form.createShopType == '2'
          ? '全国'
          : `${authorizedCityMsg.city || ''}${authorizedCityMsg.area || ''}`
      const str = `${
        this.form.dealerType === 0 ? '老经销商' : '新经销商'
      }增加新店申请表${this.type ? '(直营)' : ''}（${city}，${
        businessMsg.legalPersonName || ''
      }，门店品牌：${shopTitleBrand && shopTitleBrand.join(',')}）`
      this.form.title = str
      return str
    },
    shouRuBi() {
      if (
        this.form.assessmentfFiling.yearTotal &&
        this.form.assessmentfFiling.managementFee
      ) {
        return (
          this.form.assessmentfFiling.yearTotal /
          (this.form.assessmentfFiling.managementFee * 12)
        )
      } else {
        return ''
      }
    },
    brandAreaCode({ brandData, authorizedCityMsg }) {
      return {
        brandData,
        authorizedCityMsg
      }
    }
  },
  created() {
    const { type } = this.$route.query
    this.type = type
    this.form.channel = type ? '10' : '20'
    this.getDatadictionaryList()
    window.addEventListener('beforeunload', this.reloadBom)
  },
  mounted() {
    // this.$load.show()
    const userInfo = getStorage('userInfo')
    const id = this.$route.query.id
    this.optTime = moment().format('YYYY-MM-DD hh:mm:ss')

    const formData = {
      optJobNumber: userInfo?.code || '', //提单人工号
      optUserId: userInfo?.accountId || '',
      optUserName: userInfo?.realName || '',
      optDeptId: userInfo?.deptId || '',
      optDeptName: userInfo?.deptName || ''
    }
    this.form = {
      ...this.form,
      ...formData
    }
    if (id) {
      // this.loading = this.$load({
      //   lock: true,
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255)'
      // });
      this.$load.show()
      this.getDetail(id)
    } else {
      this.$nextTick(() => {
        let cacheData = {}
        if (this.type) {
          if (getSession('newShopFlowDirect') && !this.$router.query?.id) {
            cacheData = getSession('newShopFlowDirect').form || {}
            this.formData = cacheData
            this.form.businessLicenseId = cacheData.businessLicenseId
              ? String(cacheData.businessLicenseId)
              : ''
            for (let key in this.formData) {
              // console.log(this.formData[key]);
              if (key != 'businessLicenseId' && key != 'shopList') {
                if (this.formData[key]) {
                  this.form[key] = this.formData[key]
                }
              }
            }
          }
        } else {
          if (getSession('newShopFlow') && !this.$router.query?.id) {
            cacheData = getSession('newShopFlow').form || {}
            this.formData = cacheData
            this.form.businessLicenseId = cacheData.businessLicenseId
              ? String(cacheData.businessLicenseId)
              : ''
            for (let key in this.formData) {
              // console.log(this.formData[key]);
              if (key != 'businessLicenseId' && key != 'shopList') {
                if (this.formData[key]) {
                  this.form[key] = this.formData[key]
                }
              }
            }
          }
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.reloadBom)
    this.reloadBom()
  },
  methods: {
    // 用户界面变化
    reloadBom() {
      // console.log(1)
      if (this.isCache) {
        // console.log('缓存',this.$data);
        if (this.type) {
          setSession('newShopFlowDirect', JSON.stringify(this.$data))
        } else {
          setSession('newShopFlow', JSON.stringify(this.$data))
        }
      } else {
        if (this.type) {
          removeSession('newShopFlowDirect')
        } else {
          removeSession('newShopFlow')
        }
      }
    },
    // 获取编号中文名
    async getDatadictionaryList() {
      const res = await datadictionaryList({
        currPage: 1,
        pageSize: 1000,
        type: 'secondary_store_type'
      })
      this.dictionaryList.push(...res.data)
    },
    // 根据授权城市获取大区信息
    async authorizedCityGetMarketCenterData(list) {
      this.marketCenterData = {}
      this.form.marketCenterCode = ''
      this.form.marketCenterName = ''
      if (list.length === 0) return
      const bigBrandId = this.bigBrandMap.get('慕思经典')?.id
      const index = list.findIndex(v => v.bigBrandId === bigBrandId)
      if (index > -1) {
        new Promise(async (resolve, reject) => {
          if (this.authorizedCityMsg.hasOwnProperty('provinceCode')) {
            const res = await requestGetSizeBarListByDept({
              provinceCode: this.authorizedCityMsg?.provinceCode,
              cityCode: this.authorizedCityMsg?.cityCode,
              areaCode: this.authorizedCityMsg?.areaCode
            })
            resolve(res.data)
          } else {
            const timer = setInterval(async () => {
              if (this.authorizedCityMsg.hasOwnProperty('provinceCode')) {
                const res = await requestGetSizeBarListByDept({
                  provinceCode: this.authorizedCityMsg?.provinceCode,
                  cityCode: this.authorizedCityMsg?.cityCode,
                  areaCode: this.authorizedCityMsg?.areaCode
                })
                resolve(res.data)
                clearTimeout(timer)
              }
            }, 300)
          }
        }).then(res => {
          this.marketCenterData = res
          this.form.marketCenterCode = this.marketCenterData.smallCode
          this.form.marketCenterName = this.marketCenterData.smallName
        })
      }
    },
    // 获取新店品牌
    // 获取城市等级
    async handleGetMarketLevel(data) {
      if (
        data &&
        data.length > 0 &&
        this.authorizedCityMsg?.authorizedCityCode
      ) {
        const brandHasSame =
          [...new Set(data.map(item => item.bigBrandName))].length > 1
        if (brandHasSame) return
        const { data: marketLevel } = await requestGetCityLevel({
          brandId: data[data.length - 1].bigBrandId,
          authorizedCityCode: this.authorizedCityMsg?.authorizedCityCode
        })
        console.log(marketLevel)
        // this.marketLevelName = marketLevel[0].marketLevelName
        if (this.form.shopList.length > 0) {
          this.form.shopList[0].marketLevelName = marketLevel[0].marketLevelName
          this.form.shopList[0].marketLevelCode = marketLevel[0].marketLevelCode
        }
        this.form.marketLevelName = marketLevel[0].marketLevelName
        this.form.marketLevelCode = marketLevel[0].marketLevelCode
      } else if (
        data &&
        data.length > 0 &&
        !this.authorizedCityMsg?.authorizedCityCode
      ) {
        setTimeout(() => {
          this.handleGetMarketLevel(data)
        }, 500)
      } else {
        if (this.form.shopList.length > 0) {
          this.form.shopList[0].marketLevelName = ''
          this.form.shopList[0].marketLevelCode = ''
        }
        this.form.marketLevelName = ''
        this.form.marketLevelCode = ''
      }
    },
    async newShopBrandGet(data) {
      this.brandData = data || []
      // await this.authorizedCityGetMarketCenterData(this.brandData)
      this.handleGetMarketLevel(data)
      if (
        this.brandData?.length > 0 &&
        !this.brandData.some(item => item.name.includes('V6'))
      ) {
        this.form.dealerType = 0
      }
      // 获取送达方编号
      if (this.shopBrandNumberList.length === 0) {
        setTimeout(() => {
          // 获取送达方编号
          const list = this.brandData.map(
            item => item.parentId || item.bigBrandId
          )
          const arr = this.shopBrandNumberList
            .filter(i => list.includes(i.brandId))
            .map(v => v.shopBrandNumber)
          this.shopBrandNumber = arr.join('，')
        }, 2000)
      } else {
        if (this.brandData?.length > 0) {
          const list = data.map(item => item.parentId || item.bigBrandId)
          let arr = this.shopBrandNumberList
            .filter(i => list.includes(i.brandId))
            .map(v => v.shopBrandNumber)
          arr = [...new Set(arr)]
          this.shopBrandNumber = arr.join('，')
        } else {
          this.shopBrandNumber = ''
        }
      }
    },
    // 获取售达方
    async handleChangeBusiness(data) {
      this.businessMsg = data
      const r = await getAuthorizedCityData({
        businessLicenseId: data.id
      })
      if (r.code === 0) {
        this.authorizedCityList = r.data
      }
      // this.shopTitleBrand = []
      // this.form.shopList = []
      // this.$refs.newShopTable.addDerucci()
    },
    // 选择开店类型
    async handleChangeCreateShopType(value) {
      // console.log(value);
      if (value && value.code == '2') {
        const res = await getShopBrandData({
          businessLicenseId: this.form.businessLicenseId,
          ifEnabled: 0
        })
        this.brandData = this.resetBrandData(res.data)
        this.brandData.length == 0 &&
          this.$message({
            type: 'warning',
            message: '该售达方没有商超网点可授权品牌'
          })
      }
    },
    // 处理开店类型为网点的品牌数据
    resetBrandData(data) {
      if (data.length == 0) return []
      const brandArr = ['慕思通配', '慕思时尚', '慕思助眠']
      let newArr = data.filter(item => brandArr.includes(item.bigBrandName))
      let result = {}
      let finalResult = []
      for (let i = 0; i < newArr.length; i++) {
        result[newArr[i].name] = newArr[i]
      }
      for (let item in result) {
        finalResult.push(result[item])
      }
      return finalResult
    },
    initForm() {
      this.authorizedCityList = []
      this.authorizedCityMsg = {}
      this.form.shopList = []
      this.form.authorizedCityCode = ''
      this.form.brands = []
      this.form.createShopType = ''
      this.brandData = []
      this.shopTitleBrand = []
      this.shopBrandNumberList = []
      this.shopBrandNumber = ''
      this.businessMsg = {}
    },
    handleValidate(_, flag, message) {
      if (!flag && this.flagVisable) {
        this.$message.warning(message)
        this.flagVisable = false
      }
    },
    // 获取流程详情
    async getDetail(id) {
      try {
        const res = await getDraftDetial({
          fromId: id
        })
        // loading.close();
        if (res.code === 0) {
          const { data } = res
          this.orderNumber = data.orderNumber
          this.status = data.status
          const formData = JSON.parse(data.historicalData)
          this.type = formData.channel === '10' ? 'direct' : ''
          this.form.zsOrderNumber = data?.zsOrderNumber || ''
          // console.log(formData)
          this.optTime = data.optTime
          formData.id = String(formData.id)
          this.formData = formData
          this.form.businessLicenseId = formData.businessLicenseId
            ? String(formData.businessLicenseId)
            : ''
          if (!this.form.businessLicenseId) {
            // this.loading.close()
            this.$load.close()
          }
          //传送父级iframe高度
          postMessageIframe()
        }
      } catch (error) {
        console.log(error)
        // loading.close();
        // this.detailLoading.close()
        this.$alert(error, '提示', {
          confirmButtonText: '确定',
          type: 'error'
        })
      }
    },
    // 编辑获取售达方后执行
    async handleSuccess(data) {
      this.businessMsg = data
      for (let key in this.formData) {
        // console.log(this.formData[key]);
        if (key != 'businessLicenseId' && key != 'shopList') {
          if (this.formData[key]) {
            this.form[key] = this.formData[key]
          }
        }
      }
      if (typeof this.formData.brands == 'string') {
        this.form.brands = this.formData.brands.split(',')
      }
      const r = await getAuthorizedCityData({
        businessLicenseId: data.id
      })
      this.authorizedCityList = r.data
      this.form.oldShopId =
        this.form.ifOldShopId === '0' ? '' : this.form.oldShopId
      // await this.getAuthorizedCity(this.form.authorizedCityCode)
      const shopList = [...this.formData.shopList]
      if (shopList.length > 0) {
        shopList.forEach((item, index) => {
          if (this.dictionaryList.find(i => i.code === item.shopTypeValue)) {
            const data = this.dictionaryList.find(
              i => i.code === item.shopTypeValue
            )
            item.shopTypeValue = data.content
          }
          const fileListNames = [
            'fileList1',
            'fileList2',
            'fileList3',
            'fileList4',
            'fileList5',
            'fileList6',
            'fileList7'
          ]
          item.fileRecordList
            ?.filter(file =>
              fileListNames.some(name =>
                file.functionAttribution.includes(name)
              )
            )
            .reduce((result, file) => {
              fileListNames.forEach(name => {
                if (file.functionAttribution.includes(name)) {
                  result[name] = result[name] || []
                  result[name].push(file)
                }
              })
              return result
            }, item)
          item.brandList = item.shopBrandList.map(i => {
            return i.brandId
          })
          item.index = index
          item.shopTypeValue = item.shopTypeValue.trim()
          item.isShowCategory = item.displayShopType ? 1 : 0
          // item.ifOldShopId = this.form.ifOldShopId || '0'
          item.oldShopId = this.form.oldShopId || ''
        })
        this.form.shopList = JSON.parse(JSON.stringify(shopList))
        this.form.marketLevelName = this.form.shopList[0].marketLevelName
        this.form.marketLevelCode = this.form.shopList[0].marketLevelCode
      }
      if (this.formData.reserved) {
        this.brandData = JSON.parse(this.formData.reserved)
      }
      // setTimeout(() => {
      //   // 获取送达方编号
      //   const list = this.brandData.map(item => item.parentId)
      //   const arr = this.shopBrandNumberList
      //     .filter(i => list.includes(i.brandId))
      //     .map(v => v.shopBrandNumber)
      //   this.shopBrandNumber = arr.join('，')
      // }, 2000)
      // this.loading.close()
      if (this.form.id) {
        this.$load.close()
      }
    },
    // 保存
    save(status) {
      this.flagVisable = true
      if (status) {
        const isClassic = this.brandData.some(item =>
          item.bigBrandName.includes('慕思经典')
        )
        if (!this.type && isClassic) {
          this.$alert('请走慕思经典门店管理流程！', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.form.shopList.length == 0) {
              return this.$message.error('请添加申请开店门店信息')
            }
            this.commonCode(status)
          } else {
            this.$nextTick(() => {
              positionToError()
              return false
            })
          }
        })
      } else {
        this.commonCode(status)
      }
    },
    async commonCode(status) {
      this.$load.show()
      // this.fullscreenLoading = true
      const storeTypeArr = []
      // // 合成附件数组
      this.form.shopList.forEach(item => {
        storeTypeArr.push(item.storeTypeContent)
        item.fileRecordList = [
          ...(item?.fileList1 || []),
          ...(item?.fileList2 || []),
          ...(item?.fileList3 || []),
          ...(item?.fileList4 || []),
          ...(item?.fileList5 || []),
          ...(item?.fileList6 || []),
          ...(item?.fileList7 || [])
        ]
        const shopBrand = item.shopBrandList.find(
          brand =>
            brand.shopBrand.includes('慕思经典') &&
            brand.shopBrand !== '慕思经典-新渠道'
        )
        if (
          item.ifChildrenSupportingArea === '0' &&
          item.childrenSupportingArea > 0
        ) {
          item.childrenSupportingArea = 0
        }
        if (shopBrand && status === 10) {
          const zbrand_data = this.filterBrandWithZhuMian(item.shopBrandList)
          if (!zbrand_data.length) return
          item.shopBrandList = [...item.shopBrandList, ...zbrand_data]
          item.brandList = item.shopBrandList.map(i => i.brandId)
          this.form.brands = [
            ...new Set([
              ...this.form.brands,
              ...zbrand_data.map(j => j.brandId)
            ])
          ]
          this.shopTitleBrand = [
            ...new Set([
              ...this.shopTitleBrand,
              ...zbrand_data.map(j => j.name)
            ])
          ]
        }
      })
      this.form.storeType = [...new Set(storeTypeArr)].join(',')
      setTimeout(async () => {
        const formData = JSON.parse(JSON.stringify(this.form))
        formData.brands = formData.brands.join(',')
        formData.reserved = JSON.stringify(this.brandData)
        formData.status = status
        try {
          const res = await saveDraftData(formData)
          this.isCache = false
          this.$load.close()
          // this.fullscreenLoading = false
          if (res.code === 0) {
            this.$confirm(
              status === 10 ? '流程发布中' : '草稿保存成功',
              '提示',
              {
                confirmButtonText: '确定',
                // cancelButtonText: '取消',
                showCancelButton: false,
                type: 'success'
              }
            ).then(() => {
              this.$router.push({
                name: status ? 'myApply' : 'draft'
              })
            })
          } else if (res.code == '8848') {
            this.$router.push({
              name: status ? 'myApply' : 'draft'
            })
          }
        } catch (error) {
          console.log(error)
          this.$load.close()
          // this.fullscreenLoading = false
          // this.$message.error(error)
          if (error.data.code == '8848') {
            this.$router.push({
              name: status ? 'myApply' : 'draft'
            })
            return
          }
        }
      }, 500)
    },
    // 取消
    cancel() {
      this.isCache = false
      // this.reloadBom()
      this.$router.push({ path: '/flow/flowApply' })
    },
    // 上传附件
    handleUpload(file) {},
    // 是否租赁备案
    handleLease(data) {
      // console.log(data);
      if (data) {
        this.form.assessmentfFiling.leaseFilingReason = ''
        return
      }
      this.form.assessmentfFiling.fileList = []
    },
    // 获取流程列表
    async getFlowList(params) {
      const res = await getShopListPage({
        currPage: params.page,
        orderNumber: params.searchText || undefined,
        processCode: 'JXS007',
        status: 40
      })
      this.flowTotalCount = res.totalCount
      return res.data
    },
    async onOptionsListNotFound({ value, getDataListParams }, callback) {
      const res = await getShopListPage({
        orderNumber: value,
        pageSize: 10,
        processCode: 'JXS007',
        status: 40
      })
      callback(res.data)
    },
    // 详情
    oaDetail(orderNumber) {
      const url = `${
        process.env.VUE_APP_OA_URL
      }/mobile/plugin/loginForSession.jsp?loginid=MerchantSysAdmin&requestid=${
        this.oaRequestId || ''
      }&workflowid=${orderNumber || ''}`
      // console.log(url)
      window.open(url)
    },
    filterBrandWithZhuMian(arg) {
      // 已代理品牌
      const saveBrandList = this.$refs.brandSelect.saveBrandList
      const zhuMianBrand = saveBrandList.filter(item =>
        ['助眠系列', '芯睡眠'].includes(item.name)
      )
      const brand = []
      if (zhuMianBrand.length) {
        zhuMianBrand.forEach(i => {
          if (!arg.find(a => a.shopBrand === i.name)) {
            brand.push({
              ...arg[0],
              brandArea: '0',
              sampleSets: 0,
              updateReason: '1',
              brandId: i.brandId,
              shopBrand: i.name
            })
          }
          if (!this.brandData.find(a => a.name === i.name)) {
            this.brandData.push(i)
          }
        })
      }
      return brand
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/colors.scss';

.formItem {
  width: 20%;
}

.tip {
  color: $danger-dark-color;
  margin-bottom: 10px;
}

.sdShopName {
  min-height: 40px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 15px;
}

::v-deep .el-select {
  width: 100%;
}

.required-form-item-input {
  ::v-deep {
    .el-input.is-disabled {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input {
      .el-input__inner[type='number'] {
        padding-right: 0;
      }
    }
  }
}
.fix-footer {
  padding: 16px;
  position: fixed;
  bottom: 15px;
  background: #fff;
  box-shadow: 0px -3px 8px rgba($color: #ccc, $alpha: 0.7);
  z-index: 10;
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
