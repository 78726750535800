<!--
 * @Author: wangmq
 * @Date: 2022-12-12 10:31:41
 * @LastEditors: wangmq
 * @LastEditTime: 2023-04-14 09:22:12
-->
<template>
  <div class="dealerInterview">
    <div>
      <el-form
        class="flow-form"
        :inline="true"
        ref="ruleForm"
        label-position="top"
      >
        <RequestSelect
          :disabled="!!$route.query.id"
          :required="false"
          class="formItem"
          label="加盟类型"
          v-model="joinType"
          prop="joinType"
          type="join_status"
        />
      </el-form>
    </div>
    <component
      ref="formView"
      :is="compoentId"
      :isOA="isOA"
      :dataValue="formData"
      :validations="validations"
    ></component>
    <div class="d-footer" v-if="!isOA">
      <el-button type="primary" :loading="isLoading" @click="handleSave(1)"
        >提交</el-button
      >
      <el-button type="info" :loading="isLoading" @click="handleSave(0)"
        >保存</el-button
      >
      <el-button style="margin-left: 20px" @click="goBack">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  getCurrentInstance
} from 'vue'
import RequestSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import store from '@/store'
import { removeSession } from '@/utils/storage'
import { requestFlowDetail } from '@/api/flow/dealerInterview'
import { useRoute, useRouter } from '@/utils/router'

export default defineComponent({
  components: {
    RequestSelect,
    EmptyInterviewFlowVue: () =>
      import('./components/DealerInterview/EmptyInterviewFlow.vue'),
    TransferDealerFlowVue: () =>
      import('./components/DealerInterview/TransferDealerFlow.vue'),
    OldDealerNewFlowVue: () =>
      import('./components/DealerInterview/OldDealerNewFlow.vue')
  },
  setup(props, ctx) {
    const $route = useRoute()
    const $router = useRouter()
    const isOA = computed(() => {
      return store.state.dealershipTransfer.isOA
    })

    const formView = ref()
    const compoentView = new Map([
      ['0', 'EmptyInterviewFlowVue'],
      ['1', 'TransferDealerFlowVue'],
      ['2', 'OldDealerNewFlowVue']
    ])

    const joinType = ref()

    const isLoading = ref(false)

    const compoentId = computed(() => {
      return compoentView.get(String(joinType.value))
    })
    // 校验规则
    const validations = {
      contactInfo: ['phone', 'check_boss_number'],
      idCard: ['user_id'],
      age: ['age']
    }

    /**
     * @description: 表单验证
     * @param {boolean} flag
     * @param {string} message
     */

    /**
     * @description: 保存/提交
     * @param {*}
     */
    const handleSave = async code => {
      isLoading.value = true
      await formView.value.handleSave(code)
      isLoading.value = false
    }
    /**
     * @description: 返回到主页
     * @param {*}
     */
    const goBack = () => {
      removeSession('dealerInterview')
      $router.back(-1)
    }

    /**
     * @description: 获取表单详情
     */
    const formData = ref({})
    const getFlowDetail = async id => {
      const res = await requestFlowDetail(id)
      res.localMarketSituationList =
        res.localMarketSituationList?.map((v, i) => ({
          ...v,
          sort: i
        })) || []
      res.dealerBusinessRecordList =
        res.dealerBusinessRecordList?.map((v, i) => ({
          ...v,
          sort: i
        })) || []
      res.dealerCooperationRecordList =
        res.dealerCooperationRecordList?.map((v, i) => ({
          ...v,
          sort: i
        })) || []
      // 授权系列
      res.applyBrandSeries =
        res.applyBrandSeriesList?.map(item => item.name).join(',') || ''

      // 转让方代理系列
      res.assignorShopSeriesName =
        res.assignorBrandSeriesList?.map(item => item.name).join(',') || ''

      // 承接方代理系列
      res.contractorShopSeriesName =
        res.contractorBrandSeriesList?.map(item => item.name).join(',') || ''

      formData.value = res
      joinType.value = res.joinType
    }

    onMounted(async () => {
      if ($route.query.id) {
        await getFlowDetail($route.query.id)
      } else {
        joinType.value = '0'
      }
    })
    return {
      goBack,
      handleSave,
      compoentId,
      isLoading,
      joinType,
      formView,
      isOA,
      validations,
      formData
    }
  }
})
</script>

<style scoped lang="scss">
.dealerInterview {
  padding-bottom: 80px;
  .selectItem {
    margin: 0;
    width: 100%;
    &.is-error {
      margin-bottom: 22px;
    }
  }
  .d-footer {
    // height: 45px;
    padding: 16px;
    position: fixed;
    bottom: 15px;
    background: #fff;
    box-shadow: 0px -3px 8px rgba($color: #ccc, $alpha: 0.7);
    z-index: 1;
  }
}
</style>
