<template>
  <el-form
    v-if="bigBrandMap"
    ref="ruleForm"
    v-loading="submitLoading"
    v-bind="$attrs"
    status-icon
    class="flow-form"
    label-position="top"
    :inline="true"
    :hide-required-asterisk="disabled"
    v-on="$listeners"
    @validate="onValidate">
    <template v-if="title">
      <HeaderTitle class="main-title" :title="title" />
    </template>
    <div class="tips">
      <ol>
        <li v-for="(content, index) of tips" :key="index">{{ content }}</li>
      </ol>
    </div>
    <slot v-if="!loading" />
    <div style="height: 80px" />
    <footer v-if="!isOA" class="footer">
      <el-button type="primary" @click="onCommit('submit')"> 提交 </el-button>
      <el-button v-if="showSave" type="info" @click="onCommit('save')">
        保存
      </el-button>
      <el-button style="margin-left: 50px" @click="onCancel">取消</el-button>
    </footer>
  </el-form>
</template>

<script>
import { positionToError } from '@/utils/index'
import { setSession, getSession, removeSession } from '@/utils/storage'
import { mapState, mapGetters } from 'vuex'
import HeaderTitle from './headerTitle'
import { mergeStep, postMessageIframe } from '@/utils/index'

export default {
  components: { HeaderTitle },
  provide() {
    return {
      oaFlow: this
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    getDetail: {
      type: Function,
      default: null
    },
    onSubmit: {
      type: Function,
      default: null
    },
    tips: {
      type: Array,
      default: () => []
    },
    showSave: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const withCache = !this.$route.query.id
    return {
      withCache,
      loading: true,
      pageInfo: {
        type: 'create' // or 'detail' 默认新建表单
      },
      submitLoading: false
    }
  },

  computed: {
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA
    }),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    disabled({ isEdit, isOA }) {
      return isEdit || isOA
    }
  },
  created() {
    this.initData()
    window.addEventListener('beforeunload', this.$destroy.bind(this, undefined))
  },
  destroyed() {
    window.removeEventListener(
      'beforeunload',
      this.$destroy.bind(this, undefined)
    )
    if (this.withCache) {
      setSession(this.title, this.$attrs.model)
    } else {
      removeSession(this.title)
    }
  },
  methods: {
    async initData() {
      if (!this.getDetail) return
      const loadingClient = this.$loading({
        lock: true,
        text: '加载中,请稍等',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      try {
        const cachedFormData = getSession(this.title)
        removeSession(this.title)
        if (this.$route.query.id && this.getDetail) {
          this.pageInfo.type = 'detail'
        }

        await this.getDetail(
          this.pageInfo.type,
          this.withCache ? cachedFormData : undefined
        )
        this.errorCount = 0
        // 传送父级iframe高度
        postMessageIframe()
      } catch (error) {
        console.error('初始化数据失败', error)
        // if (!this.errorCount) {
        //   this.errorCount = 1
        // } else {
        //   this.errorCount++
        // }
        // if (this.errorCount > 2) return
        // this.$alert('刷新重试', '初始化数据失败', {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     if (action === 'confirm') {
        //       this.initData()
        //     }
        //   }
        // })
      } finally {
        loadingClient.close()
        this.loading = false
      }
    },
    setLoading(bool) {
      this.submitLoading = bool
    },
    async validateForm() {
      try {
        await this.$refs.ruleForm.validate()
        return false
      } catch (error) {
        this.$nextTick(positionToError)
        return true
      }
    },
    onCancel() {
      this.withCache = false
      this.$router.go(-1)
    },
    onValidate(_, flag, message) {
      if (!flag && message && this.validateFlag) {
        this.$message.warning(message)
        this.validateFlag = false
      }
    },
    onCommit: mergeStep(async function (type) {
      this.withCache = false
      this.submitLoading = true
      if (type === 'save') {
        if (this.onSubmit) {
          try {
            await this.onSubmit('save')

            this.$message.success('保存成功')
            this.$router.replace({
              name: 'draft'
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$emit('onSave')
        }
        // 草稿
        this.submitLoading = false
        return
      }
      this.validateFlag = true
      if (type === 'submit') {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(async valid => {
            if (!valid) {
              reject('请检查表单项')
              return this.$nextTick(positionToError)
            }
            if (this.onSubmit) {
              try {
                await this.onSubmit('submit')
                await this.$alert('流程发布中', '提示', {
                  confirmButtonText: '确定',
                  type: 'success'
                })
                // this.$message.success('流程发布中')
                this.$router.replace({
                  name: 'myApply'
                })
                resolve()
              } catch (error) {
                reject(error)
              }
            } else {
              // 提交
              this.$emit('onSubmit')
              resolve()
            }
          })
        }).finally(() => {
          this.submitLoading = false
        })
      }
    }),
    clearValidate(prop) {
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate(prop)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.flow-form {
  
  ol {
    list-style: none;
    padding: 0;
    line-height: 1.5;
    color: $danger-dark-color;
  }
  .main-title {
    ::v-deep {
      .title-cell {
        &::before {
          background-color: $title-dark-color;
        }
      }
    }
  }
  ::v-deep {
    .title-cell {
      margin-top: 30px;
      padding-left: 10px;
      margin-bottom: 10px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 4px;
        height: 10px;
        background-color: $primary-color;
        transform: translateY(-50%);
      }
    }
    .el-divider.el-divider--horizontal {
      display: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .el-table__row {
      .el-form-item {
        width: 100%;
        margin: 0;
        &.is-error {
          margin-bottom: 22px;
        }
      }
    }
  }
}
.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background-color: #fff;
  z-index: 99;
}
</style>
