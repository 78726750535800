<!--
 * @Author: wangmq
 * @Date: 2022-07-26 14:51:32
 * @LastEditors: wangmq
 * @LastEditTime: 2023-07-06 15:19:00
-->
<template>
  <div>
    <div class="title-cell">
      <div class="title" :style="{fontSize:size}">
        {{ title }}
      </div>
      <slot></slot>
    </div>
    <el-divider v-if="hiddenDivider"></el-divider>
    <slot name="form"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    hiddenDivider: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '16px'
    }
  }
}
</script>

<style lang="scss" scoped>
.title-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    flex-shrink: 0;
  }
}
</style>
