<template>
  <flow-form
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="门店系列管理申请表"
    :tips="[
      '1、门店系列管理申请表：经销商在已开业的门店调整品牌经营',
      '2、如经销商在门店装修期间需要调整门店经营品牌，该流程依旧适用，流程归档后会重新生成新的装修流程并把原装修流程失效',
      '3、流程根据选择的区域“门店状态”跳转对应的门店（如门店状态选择“开业”，那么在下面的门店编码只能根据这个经销商已经开业的门店里面选择）',
      '4、根据新的门店统计规则，两个相邻的门店（一面墙隔着）按照一个门店计算，如果经销商在原来门店隔壁拿到位置，同时不需要两个门店打通一起装修，那么在“门店是否隔墙”选择“是”，流程生成新的门店号给以装修。待装修结束门店开业再合并一起。'
    ]"
  >
    <div>
      <required-form-item-input
        v-for="(value, key) in { title: '标题', orderNumber: '单号' }"
        :key="key"
        class="formItem"
        :value="
          key === 'title'
            ? (form.declarationForm[key] = title)
            : form.declarationForm[key]
        "
        :label="value"
        disabled
        :required="false"
        placeholder="自动生成"
      />
      <!-- 招商单号 -->
      <RequiredFormItemInput
        v-model="form.zsOrderNumber"
        label="招商单号"
        class="formItem"
        prop="zsOrderNumber"
        placeholder="归档后生成"
        disabled
      />
    </div>
    <!-- <HeaderTitle title="经销商个人基础信息"/> -->
    <required-form-item-input
      v-for="(value, key) in {
        optJobNumber: '提单人工号',
        optUserName: '提单人',
        optDeptName: '提单部门',
        optTime: '提单日期'
      }"
      :key="key"
      v-model="form.declarationForm[key]"
      class="formItem"
      :prop="`declarationForm[${key}]`"
      :label="value"
      disabled
    />

    <required-form-item-select
      ref="businessRef"
      v-model="form.businessLicenseId"
      class="formItem"
      prop="businessLicenseId"
      label="售达方"
      label-key="licenseNumber"
      value-key="id"
      filterable
      remote
      popper-class="custom-business-select"
      :total-count="businessCountTotal"
      :get-data-list="getBusinessLicense"
      :get-next-list="getBusinessLicense"
      @onOptionsListNotFound="onNotFoundBusinessLicense"
      @change="
        () => {
          form.authorizedCityCode = ''
          form.shopId = ''
          brandIds = []
        }
      "
      @changeSelectedItem="
        e => {
          businessSelectedItem = e
          bossId = e ? e.bossId : ''
        }
      "
    >
      <template v-slot="{ scope }">
        <span>{{ scope.row.licenseNumber }}</span>
        <small>{{ scope.row.businessLicenseName }}</small>
      </template>
    </required-form-item-select>
    <required-form-item-input
      v-for="(value, key) in {
        businessLicenseName: '售达方名称',
        realName: '经销商（实际控股人）',
        contactInfo: '经销商（实际控股人）联系方式'
      }"
      :key="key"
      class="formItem"
      :label="value"
      :value="businessSelectedItem ? businessSelectedItem[key] : ''"
      disabled
      placeholder="请先选择售达方"
    />
    <required-form-item-select
      ref="shopRef"
      v-model="form.authorizedCityCode"
      class="formItem"
      prop="authorizedCityCode"
      label="授权城市"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      :get-data-list="getCitylist"
      :get-next-list="getCitylist"
      :get-data-list-params="businessSelectedItem"
      :total-count="authCityCountTotal"
      :select-props="{
        disabled: !form.businessLicenseId
      }"
      @change="
        () => {
          brandIds = []
          form.shopId = ''
        }
      "
      @onOptionsListNotFound="onNotFoundCityList"
      @changeSelectedItem="e => (authorizedSelectedItem = e)"
    />

    <!-- 营销中心 -->
    <RequiredFormItemInput
      v-if="isClassic"
      v-model="form.marketCenter"
      label="营销中心"
      class="formItem"
      prop="marketCenter"
      disabled
    />

    <RequiredFormItemInput
      delay-update
      class="formItem"
      placeholder="选择授权城市、申请类型后自动带出"
      :value="form.marketLevelName"
      prop="marketLevelName"
      label="城市级别"
      disabled
    />

    <required-form-item-input
      v-for="(value, key) in {
        province: '省份',
        city: '地级市',
        area: '区/县'
      }"
      :key="key"
      class="formItem"
      :label="value"
      :value="authorizedSelectedItem ? authorizedSelectedItem[key] : ''"
      disabled
      placeholder="请先选择授权城市"
    />
    <required-module-form-item-select
      v-model="form.applicationType"
      class="formItem"
      type="shop_application_type"
      label="申请类型"
      value-key="content"
      @changeSelectedItem="onChangeSelectedItemShopApplicationType"
    />
    <required-form-item-select
      v-model="form.shopStatus"
      class="formItem"
      label="门店状态"
      :data-list="[
        { label: '已开业', value: 0 },
        { label: '未开业', value: 1 }
      ]"
      :required="false"
      label-key="label"
      value-key="value"
    />
    <!-- :disabled="form.shopType !== '未开业'" -->
    <required-form-item-input
      v-if="form.shopStatus === 1"
      v-model="form.declarationForm.linkOrderNumber"
      class="formItem"
      prop="declarationForm.linkOrderNumber"
      label="生成未开业门店对应的OA单号"
    />
    <!-- :required="false" -->
    <required-form-item-input
      v-model="form.declarationForm.remark"
      class="formItem"
      prop="declarationForm.remark"
      label="调整原因"
    />
    <!-- <required-form-item-select
      class="formItem"
      prop="quarantine"
      label="装修类型"
      v-model="form.quarantine"
      :dataList="[
        { label: '部分装修', value: 1 },
        { label: '整体装修', value: 0 }
      ]"
      valueKey="value"
      labelKey="label"
    /> -->
    <required-form-item-input
      class="formItem"
      label="已代理授权品牌"
      :placeholder="
        form.businessLicenseId && !authBrandName
          ? '该售达方没有代理授权品牌'
          : '请先选择售达方'
      "
      :value="authBrandName"
      :required="false"
      disabled
    />
    <div style="margin: 10px 0">
      <CommonTable
        style="border: 0; border-radius: 0"
        :table-layout-direction="
          isMobile ? 'horizontal' : 'vertical'
        "
        :default-data-list="tableShopList"
        :columns="shopTableColumns"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
      />
    </div>
    <div v-if="shopDetailSelectItem" style="margin-bottom: 10px">
      <HeaderTitle title="生成门店明细" />
      <CommonTable
        style="border: 0; border-radius: 0"
        :table-layout-direction="
          isMobile ? 'horizontal' : 'vertical'
        "
        :default-data-list="shopBrandListFilter"
        :columns="shopBrandTableColumns"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
      />
    </div>
    <div v-if="shopDetailSelectItem" style="margin-bottom: 10px">
      <HeaderTitle title="门店减去系列明细" />
      <CommonTable
        style="border: 0; border-radius: 0"
        :table-layout-direction="
          isMobile ? 'horizontal' : 'vertical'
        "
        :default-data-list="form.invalidBrandOfList"
        :columns="reduceBrandTableColumns"
        :card-props="{ shadow: 'never', bodyStyle: 'padding: 0' }"
      />
    </div>
    <div class="overflow: hidden;">
      <required-formitem-upload-file
        v-for="(value, key) in formLayout.uploadList"
        :key="key"
        v-model="form[key]"
        prop="fileRecordList"
        class="formItem"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview"
        :required="value.required"
      >
        <div slot="describe">
          {{ value.label }}
          <!-- 1、店铺含外围照片不低于5张或视频<br>
          2、商场平面图标注慕思位置及人流动向、独立店地图定位<br>
          3、店铺CAD图纸<br>
          4、店铺租赁合同<br>
          5、新商预打款凭证 -->
        </div>
        <div slot="tip">单个文件不能超过400M</div>
      </required-formitem-upload-file>
    </div>
    <div>
      <required-form-item-select
        v-model="form.childrenSupportingAreaFlag"
        :value-translate="Number"
        class="formItem"
        label="是否有儿童配套专区"
        :required="false"
        :data-list="[
          { label: '是', value: 1 },
          { label: '否', value: 0 }
        ]"
        label-key="label"
        value-key="value"
        @changeSelectedItem="handleChildren"
      />
      <required-form-item
        v-if="form.childrenSupportingAreaFlag"
        prop="childrenSupportingArea"
        class="formItem"
        label="面积（平方米）"
        :rules="[
          {
            validator: regNonzero,
            trigger: 'blur'
          }
        ]"
      >
        <el-input-number
          v-model="form.childrenSupportingArea"
          :max="9999"
          :min="0"
          :controls="false"
        />
      </required-form-item>
    </div>

    <remark-item
      v-model="form.comments"
      style="margin-top: 40px"
      :file-list="form.formFileRecordList"
      @onUpload="
        file => {
          form.formFileRecordList = file.map(item => item.response)
        }
      "
    />
  </flow-form>
</template>

<script>
import dateFormat from 'dateformat'
import { mapGetters, mapState } from 'vuex'
import lodash from 'lodash'

import { storeNatureBrandListFilter } from '@/utils/flow'

import HeaderTitle from './components/headerTitle'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormitemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RemarkItem from './components/remarkItem'
import { checkBrandIsDerucciClassic } from './data/checkBrandIsDerucciClassic'

import CommonTable from '@/components/TablePro/Table.vue'
import { render } from '@/components/TablePro/fn'

import FlowForm from './components/flowForm'
import { common } from '@/mixin/mixin'

import * as BusinessApi from '@/api/attractPlatform/business'
import { requestGetCityLevel, requestGetSizeBarListByDeptMerge } from '@/api/common/'
import { Message, MessageBox } from 'element-ui'

const shopNatureTitleTips = `1. 寝具综合店：一个门店包含大于等于2个寝具系列（在一个空间里，无隔断）

2.沙发综合店：一个门店包含大于等于2个沙发系列（在一个空间里，无隔断）

3.客餐卧综合店：一个门店包含大于等于2个系列，至少含一个寝具系列、一个沙发系列

4. 寝具单系列店：一个门店一个寝具系列（芯睡眠、助眠系列为通配系列，不在单系列计算范围内）

5.沙发单系列店：一个门店一个沙发系列（芯睡眠、助眠系列为通配系列，不在单系列计算范围内）

6.整装店：包含电器、衣柜、橱柜、卫浴等全屋定制产品`

export default {
  components: {
    HeaderTitle,
    RequiredFormItem,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormitemUploadFile,
    RequiredModuleFormItemSelect,
    // eslint-disable-next-line vue/no-unused-components
    RequestModuleSelect,
    CommonTable,
    FlowForm,
    RemarkItem
  },
  mixins: [common],
  data() {
    const shopTableColumns = [
      {
        title: '门店编号',
        render: render((h, context) => {
          this.$forceUpdate()
          return (
            <RequiredFormItemSelect
              ref='shopSelectRef'
              v-model={this.form.shopId}
              prop='shopId'
              label='门店编号'
              show-label={false}
              label-key='shopNumber'
              value-key='id'
              clearable
              get-data-list={this.getShopDetailList}
              get-data-list-params={`${this.bossId},${this.form.authorizedCityCode},${this.form.shopStatus}`}
              disabled={!this.bossId || !this.form.authorizedCityCode}
              onChangeSelectedItem={selectedShopDetail => {
                if (this.dealerShop?.id === selectedShopDetail?.id) {
                  // 跟表单快照的门店id一致，使用快照数据
                  this.shopDetailSelectItem = this.dealerShop
                  if (!this.dealerShop.shopModelList) {
                    this.shopDetailSelectItem.shopModelList =
                      selectedShopDetail.shopModelList
                  }
                } else {
                  this.shopDetailSelectItem = selectedShopDetail
                }
              }}
              onChange={this.shopNumberChange}
            />
          )
        })
      },
      {
        title: '原门店品牌',
        name: 'brandName',
        renderText: text => {
          return this.form.originalStoreBrandName || text
        }
      },
      {
        title: '店态性质',
        name: 'storeNature',
        renderTitle: render((h, text) => {
          return (
            <span>
              <span>店态性质</span>
              <i
                style='color: #000; cursor: pointer;'
                title={shopNatureTitleTips}
                class='el-icon-question'
              />
            </span>
          )
        }),
        render: render((h, context) => {
          return (
            this.shopDetailSelectItem && (
              <RequiredModuleFormItemSelect
                v-model={this.form.storeNature}
                class='formItem'
                prop='storeNature'
                show-label={false}
                label='店态性质'
                type='shop_nature_code'
                onChange={() => {
                  this.brandIds = []
                }}
              />
            )
          )
        })
      },
      {
        title: '调整后门店品牌',
        name: 'brandName',
        render: render((h, context) => {
          return this.shopDetailSelectItem ? (
            <RequiredFormItemSelect
              ref='shopBrandList'
              v-model={this.brandIds}
              prop='shopBrandList'
              label='调整后门店品牌'
              value-key='id'
              label-key='name'
              get-data-list={this.getBrandlist}
              filter-data-list={this.brandFilterDataList}
              get-data-list-params={`${this.form.businessLicenseId},${this.form.authorizedCityCode}`}
              disabled={!this.form.storeNature}
              show-label={false}
              multiple
              onChangeSelectedItem={this.onBrandChange}
              onOnChangeItem={selectedItems => {
                // 如果选择慕思经典任意系列，需在品牌额外添加慕思助眠、芯睡眠
                const findTargetId = this.bigBrandMap.get('慕思经典')?.id
                const existTargetCodeInApplyBrandSeries = selectedItems?.filter((item) => {
                  return ![this.seriesMap.get('慕思经典-新渠道').code].includes(item.code)
                }).some(
                  item => {
                    return item.parentId === findTargetId
                  }
                )
                if (!existTargetCodeInApplyBrandSeries) return

                const insertSeriesList =
                  context.$refs?.shopBrandList?.$selectRef?.optionList?.filter(
                    item =>
                      [
                        this.seriesMap.get('助眠系列').code,
                        this.seriesMap.get('芯睡眠').code
                      ].includes(item.code)
                  )

                this.form.shopBrandList = lodash.unionBy(
                  this.form.shopBrandList,
                  insertSeriesList.map(item => {
                    return {
                      brandId: item.id,
                      brandName: item.name,
                      brandArea: '0',
                      sampleSets: '0',
                      ifEnabled: '0',
                      updateReason: '1'
                    }
                  }),
                  'brandId'
                )
                this.brandIds = lodash.uniq(
                  this.form.shopBrandList.map(item => item.brandId)
                )
              }}
            />
          ) : (
            <span>{context.text}</span>
          )
        })
      },
      {
        title: '新增的品牌',
        renderTitle: render((h, text) => <span class='red'>{text}</span>),
        cellStyle: { color: 'red' },
        renderText: () =>
          (this.isOA
            ? this.form.addBrandOfName
            : this.addBrandData.map(item => item.brandName).join(',')) || '无'
      },
      {
        title: '减去的品牌',
        renderTitle: render((h, text) => <span class='red'>{text}</span>),
        cellStyle: { color: 'red' },
        renderText: () =>
          this.form.invalidBrandOfList.map(item => item.brandName).join(',') ||
          '无'
      },
      {
        title: '门店名称',
        name: 'shopName'
      },
      {
        title: '渠道性质',
        name: 'shopType',
        render: render(
          (h, context) =>
            context.data && (
              <RequestModuleSelect
                ref=''
                v-model={context.data.shopType}
                type='store_type'
                disabled
                text-display
              />
            )
        )
      },
      {
        title: '渠道性质二级分类（商场名称）',
        name: 'shopType',
        renderText: (text, data) => (data ? data.shopTypeValue : text)
      },
      { title: '省份', name: 'province' },
      { title: '地级市', name: 'city' },
      { title: '区/县', name: 'area' },
      { title: '道路/街道', name: 'street' },
      { title: '门店楼层', name: 'shopFloor' },
      { title: '门店铺号', name: 'shopFloorNumber' },
      {
        title: '门店地址',
        renderText: (text, data) =>
          data &&
          this.stringJoin(
            data.province,
            data.city,
            data.area,
            data.street,
            data.shopTypeValue,
            data.shopFloor,
            data.shopFloorNumber
          )
      },
      { title: '原门店面积', name: 'shopArea' },
      {
        title: '调整后面积',
        cellStyle: { color: 'red' },
        renderText: () => this.shopArea
      },
      {
        title: '门店新系列计划开业时间',
        name: 'seriesPlanOpeningDate',
        width: 160,
        render: render((h, context) => {
          console.log(context)
          return (
            context.data && (
              <RequiredFormItemDatePicker
                class='formItem'
                // prop='seriesPlanOpeningDate'
                v-model={this.form.seriesPlanOpeningDate}
                label='经营开始时间'
                show-label={false}
              />
            )
          )
        })
      },
      {
        title: '门店新系列计划装修时间',
        name: 'seriesPlanDecorationDate',
        width: 160,
        render: render((h, context) => {
          return (
            context.data && (
              <RequiredFormItemDatePicker
                class='formItem'
                // prop='seriesPlanDecorationDate'
                v-model={this.form.seriesPlanDecorationDate}
                label='经营开始时间'
                show-label={false}
              />
            )
          )
        })
      }
    ]

    const shopBrandTableColumns = [
      {
        title: '序号',
        width: 50,
        renderText(_, __, index) {
          return index + 1
        }
      },
      { title: '门店编号', renderText: () => this.shopNumber },
      {
        title: '门店名称',
        name: 'shopName',
        renderText: (text, data) =>
          this.shopDetailSelectItem ? this.getShopName(data.brandName) : text
      },
      {
        title: '渠道性质',
        name: 'shopType',
        render: render((h, context) =>
          this.shopDetailSelectItem ? (
            <RequestModuleSelect
              v-model={this.shopDetailSelectItem.shopType}
              type='store_type'
              disabled
              text-display
            />
          ) : (
            context.text
          )
        )
      },
      {
        title: '渠道性质二级分类（商场名称）',
        renderText: () => this.shopDetailSelectItem.shopTypeValue
      },
      {
        title: '渠道性质三级分类',
        renderText: () => this.shopDetailSelectItem.shopTypeThree
      },
      {
        title: '门店地址',
        renderText: () => {
          const shopDetailSelectItem = this.shopDetailSelectItem
          return this.stringJoin(
            shopDetailSelectItem.province,
            shopDetailSelectItem.city,
            shopDetailSelectItem.area,
            shopDetailSelectItem.street,
            shopDetailSelectItem.shopTypeValue,
            shopDetailSelectItem.shopFloor,
            shopDetailSelectItem.shopFloorNumber
          )
        }
      },
      { title: '门店品牌', name: 'brandName' },
      {
        title: '品牌面积',
        name: 'brandArea',
        render: render((h, context) => (
          <RequiredFormItemInput
            v-model={
              this.form.shopBrandList[context.data.originIndex].brandArea
            }
            class='formItem'
            validations={['number']}
            show-label={false}
            label='品牌面积'
            prop={`shopBrandList.${context.data.originIndex}.brandArea`}
            maxLength={6}
          />
        ))
      },
      {
        title: '样品套数',
        name: 'brandArea',
        render: render((h, context) => (
          <RequiredFormItemInput
            v-model={
              this.form.shopBrandList[context.data.originIndex].sampleSets
            }
            class='formItem'
            validations={['number']}
            show-label={false}
            label='样品套数'
            prop={`shopBrandList.${context.data.originIndex}.sampleSets`}
            maxLength={4}
          />
        ))
      },
      {
        title: '是否装修',
        render: render((h, context) => (
          <RequiredModuleFormItemSelect
            prop={`shopBrandList.${context.data.originIndex}.ifEnabled`}
            v-model={
              this.form.shopBrandList[context.data.originIndex].ifEnabled
            }
            type='shop_if_decorate'
            show-label={false}
          />
        ))
      },
      {
        title: '系列类型',
        render: render((h, context) => (
          <RequiredModuleFormItemSelect
            prop={`shopBrandList.${context.data.originIndex}.updateReason`}
            required={checkBrandIsDerucciClassic(context.data.brandName)}
            v-model={
              this.form.shopBrandList[context.data.originIndex].updateReason
            }
            filterDataList={list => list.filter(item => item.status === 0)}
            type='shop_change'
            show-label={false}
            label='系列类型'
          />
        ))
      }
    ]

    const reduceBrandTableColumns = [
      {
        title: '序号',
        renderText(_, __, index) {
          return index + 1
        }
      },
      {
        title: '门店系列',
        name: 'brandName'
      },
      {
        title: '系列开业时间',
        name: 'authorizedTime'
      },
      {
        title: '系列取消原因',
        render: render((h, context) => (
          <RequiredModuleFormItemSelect
            prop={`invalidBrandOfList.${context.index}.closeReason`}
            required={checkBrandIsDerucciClassic(context.data.brandName)}
            v-model={context.data.closeReason}
            filterDataList={list => list.filter(item => item.status === 0 && item.content !== '转让失效')}
            type='shop_brand_close'
            show-label={false}
            label='系列取消原因'
          />
        ))
      }
    ]

    return {
      bossId: '',
      authBrandName: '',
      pageInfo: {
        type: 'create', // or 'edit' 默认新建表单
        platform: 'shop-series' // or 'oa' 默认渠道运营管理系统
      },
      isClassic: false,

      form: {
        id: '', // 如果是空的就是新增非空就是修改
        storeNature: '', // 门店性质
        applicationType: '', // 申请类型
        declarationFormId: '', // 如果是空的就是新增非空就是修改
        businessLicenseId: '', // 售达方id
        authorizedCityCode: '', // 授权城市id
        shopId: '', // 门店id
        // changeBrandName: "D3,0769,儿童", //调整后品牌
        shopArea: '', // 调整后面积
        shopName: '', // 门店名称
        quarantine: '', // 0不隔墙1隔墙
        shopNumber: '', // 门店编号
        shopFloorNumberAlias: '', // 隔墙门店编号
        shopStatus: '', // 门店开业状态
        cancelBrandOfId: '', // 取消品牌的Id接收格式：123,12124,1251,123366
        cancelBrandOfName: '', // 取消品牌的名字接收格式：3D,0769,凯奇,歌蒂娅
        addBrandOfId: '', // 新增品牌的Id接收格式：123,12124,1251,123366
        addBrandOfName: '', // 新增品牌的名字接收格式：3D,0769,凯奇,歌蒂娅
        marketCenter: '', // 营销中心
        marketCenterCode: '', // 营销中心

        marketLevelCode: '', // 市场级别编号
        marketLevelName: '', // 市场级别名称

        declarationForm: {
          // statusExplain: "开业",
          status: 0, // 0草稿1已发布2已失效3已归档
          // 表单信息
          processCode: '', // 流程编号
          title: '', // 标题
          orderNumber: '', // 单号
          optUserId: '', // 提单人id
          optUserName: '', // 提单人名字
          optJobNumber: '', // 提单人工号
          optDeptId: '', // 部门id
          optDeptName: '', // 部门
          optTime: dateFormat(Date.now(), 'yyyy-mm-dd hh:MM:ss'), // 提单时间
          remark: '', // 调整原因
          linkOrderNumber: '' // 生成未开业门店对应的OA单号
          // dealerType: "" //0否 1是
        },

        shopBrandList: null,

        fileRecordList: [], // 店铺含外围照片不低于5张或视频（必填）
        shopPositionList: [], // 商场平面图标注慕思位置及人流动向、独立店地图定位
        shopCadList: [], // 店铺CAD图纸（必填）
        shopLeaseContractList: [], // 店铺租赁合同
        paymentVoucherList: [], // 新商预打款凭证

        comments: '', // 备注文本
        formFileRecordList: [], // 备注文件

        originalStoreBrandName: '', // 原门店品牌,防止审核结束后无法获取
        childrenSupportingAreaFlag: 0,
        childrenSupportingArea: '',
        invalidBrandOfList: [], // 删除的品牌列表

        seriesPlanOpeningDate: '', // 门店新系列计划开业时间
        seriesPlanDecorationDate: '' // 门店新系列计划装修时间
      },
      // 选中的申请类型
      applicationTypeItem: null,
      // 选中的售达方
      businessSelectedItem: null,
      // 选中的授权城市
      authorizedSelectedItem: null,
      // 选中的门店
      shopDetailSelectItem: null,
      brandIds: [],
      businessCountTotal: null, // 售达方总条数
      authCityCountTotal: null, // 授权城市总条数
      addBrandData: [], // 新增的品牌列表
      shopTableColumns,
      shopBrandTableColumns,
      reduceBrandTableColumns,
      dealerShop: null, // 原门店数据、归档后读取，防止数据修改
      formLayout: {
        uploadList: {
          fileRecordList: {
            label: '店铺含外围照片不低于5张或视频',
            required: false
          },
          shopPositionList: {
            label: '商场平面图标注慕思位置及人流动向、独立店地图定位',
            required: false
          },
          shopCadList: { label: '店铺CAD图纸', required: false },
          shopLeaseContractList: { label: '店铺租赁合同', required: false },
          paymentVoucherList: { label: '新商预打款凭证', required: false }
        }
      }

    }
  },
  computed: {
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      allBrandData: state => state.commonConfig.allBrandData,
      isMobile: state => state.app.device === 'mobile' || state.app.isWxworkMoblie
    }),
    ...mapGetters(['userInfo']),
    ...mapGetters('commonConfig', ['bigBrandMap', 'seriesMap']),
    shopArea({ form }) {
      const shopArea = (form.shopBrandList || []).reduce(
        (accumulator, currItem) => {
          return accumulator + Number(currItem.brandArea || 0)
        },
        0
      )
      return shopArea
    },
    tableShopList() {
      return [this.shopDetailSelectItem]
    },
    shopNumber({ shopDetailSelectItem }) {
      let shopNumber = ''
      if (shopDetailSelectItem) {
        shopNumber = `${shopDetailSelectItem.shopNumber}`
      }
      return shopNumber
    },
    title({
      businessSelectedItem,
      authorizedSelectedItem,
      shopDetailSelectItem
    }) {
      let authCity = ''
      if (authorizedSelectedItem) {
        authCity = authorizedSelectedItem.authorizedCityName
      }
      const realName = businessSelectedItem ? businessSelectedItem.realName : ''
      const shopNumber = shopDetailSelectItem
        ? shopDetailSelectItem.shopNumber
        : ''
      const list = []
      if (authCity) {
        list.push(authCity)
      }
      if (realName) {
        list.push(realName)
      }
      if (shopNumber) {
        list.push(`调整门店：${shopNumber}`)
      }
      let str = '门店系列管理申请表'
      if (list.length) {
        str += `（${list.join('，')}）`
      }
      return str
      // 门店系列管理申请表（授权城市，经销商，调整门店：XX）
    },
    shopName({ form: { quarantine, shopBrandList }, shopDetailSelectItem }) {
      const brandObj = {}
      if (quarantine && shopDetailSelectItem) {
        shopDetailSelectItem.shopModelList.forEach(item => {
          brandObj[item.brandId] = item.brandName
        })
      }
      if (shopBrandList) {
        shopBrandList.forEach(item => {
          if (!brandObj[item.brandId]) {
            brandObj[item.brandId] = item.brandName
          }
        })
      }

      const brandName = Object.values(brandObj).join('/')
      return this.getShopName(brandName)
    },
    shopBrandListFilter({ form: { shopBrandList }}) {
      return shopBrandList
        ?.map((item, index) => {
          return {
            ...item,
            originIndex: index
          }
        })
        ?.filter(item => {
          return ![
            this.seriesMap.get('芯睡眠').id,
            this.seriesMap.get('助眠系列').id
          ].includes(item.brandId)
        })
    },
    authorizedCityCode_brandId({ form: { authorizedCityCode }, applicationTypeItem }) {
      return `${authorizedCityCode || ''}_${applicationTypeItem?.typeDetail || ''}`
    }
  },
  watch: {
    authorizedCityCode_brandId(value) {
      const [authorizedCityCode, brandId] = value.split('_')
      if (!authorizedCityCode || !brandId) {
        this.form.marketLevelCode = ''
        this.form.marketLevelName = ''
        return
      }
      requestGetCityLevel({
        brandId,
        authorizedCityCode
      }).then(res => {
        this.form.marketLevelCode = res.data?.[0]?.marketLevelCode ?? ''
        this.form.marketLevelName = res.data?.[0]?.marketLevelName ?? ''
      })
    },
    title: {
      immediate: true,
      handler(title) {
        this.form.declarationForm.title = title
      }
    },
    shopArea: {
      immediate: true,
      handler(shopArea) {
        this.form.shopArea = shopArea
      }
    },
    shopNumber: {
      immediate: true,
      handler(shopNumber) {
        this.form.shopNumber = shopNumber
      }
    },
    shopName: {
      immediate: true,
      handler(shopName) {
        this.form.shopName = shopName
      }
    },
    async 'form.businessLicenseId'(businessLicenseId) {
      this.authBrandName = await BusinessApi.shopApplyBrand({
        businessLicenseId
      }).then(res => res.data.map(item => item.name).join(','))
    },
    authorizedSelectedItem() {
      this.getGetSizeBarListByDept()
    },
    brandIds(){
      this.getGetSizeBarListByDept()
    }
  },
  methods: {
    getGetSizeBarListByDept() {
      if (!this.authorizedSelectedItem || this.brandIds.length === 0) {
        this.form.marketCenter = ''
        this.form.marketCenterCode = ''
        this.form.brandRegionCode = ''
      } else {
        return requestGetSizeBarListByDeptMerge({
          provinceCode: this.authorizedSelectedItem.provinceCode,
          cityCode: this.authorizedSelectedItem.cityCode,
          areaCode: this.authorizedSelectedItem.areaCode,
          townCode: this.authorizedSelectedItem.townCode,
          brandIds: this.brandIds.join(','),
          deptId: ''
        }).then(res => {
          this.form.marketCenter = res.data.smallName
          this.form.marketCenterCode = res.data.smallCode
          this.form.brandRegionCode = res.data.brandRegionCode
        })
      }
    },
    handleChildren({ value }) {
      if (!value && this.form.childrenSupportingArea > 0) {
        this.form.childrenSupportingArea = 0
      }
    },
    stringJoin(...rest) {
      return rest.filter(item => !!item).join('')
    },
    brandFilterDataList(list) {
      return storeNatureBrandListFilter(
        list,
        this.form.storeNature,
        this.brandIds
      )
    },
    getShopName(brandName) {
      if (!this.shopDetailSelectItem) return ''
      return `${this.stringJoin(
        this.shopDetailSelectItem.city,
        this.shopDetailSelectItem.area,
        this.shopDetailSelectItem.street,
        this.shopDetailSelectItem.shopTypeValue,
        this.shopDetailSelectItem.marketName,
        this.shopDetailSelectItem.shopFloor,
        this.shopDetailSelectItem.shopFloorNumber
      )}（${brandName}）专卖店`
    },
    shopNumberChange() {
      // 切换门店清空原品牌,用以显示门店带出品牌
      this.form.originalStoreBrandName = ''
      this.$nextTick(() => {
        this.form.storeNature = this.shopDetailSelectItem
          ? this.shopDetailSelectItem.storeNature
          : ''
      })
      this.$nextTick(() => {
        this.$refs.flowFormRef.$refs.ruleForm.validateField('shopId')
      })
    },
    onPreview(file) {
      window.open(file.url)
    },
    onBrandChange(selectedItems = []) {
      const findTargetId = this.bigBrandMap.get('慕思经典')?.id
      this.isClassic = selectedItems?.filter((item) => {
        return ![this.seriesMap.get('慕思经典-新渠道').code].includes(item.code)
      }).some(
        item => {
          return item.parentId === findTargetId
        }
      )

      const addBrandData = []
      const invalidBrandOfList = []
      // 原有的品牌列表
      const shopModelList = this.shopDetailSelectItem.shopModelList
      // 最终提交的品牌列表数据
      const shopBrandList = selectedItems.map(brandItem => {
        // 尝试查找form表单里面是否存在
        let alreadyItem = (this.form.shopBrandList || []).find(
          item => item.brandId === brandItem.id
        )

        // 在选中的门店内品牌查找
        const findShopDetailItem = shopModelList.find(
          item => item.brandId === brandItem.id
        )
        // 门店品牌找到该品牌
        if (findShopDetailItem) {
          // 明细也存在该品牌,将brandName赋值,因为详情接口没有返回brandName
          // 同时为id赋值,只需要给非新增赋值
          if (alreadyItem) {
            alreadyItem.id = findShopDetailItem.dsbId
            alreadyItem.brandName = findShopDetailItem.brandName
          } else {
            // 如果明细没有该品牌,则使用门店详情查找到的数据
            alreadyItem = findShopDetailItem
          }
        }
        // 新增品牌
        let newBrandItem = {
          brandId: brandItem.id,
          brandName: brandItem.name,
          brandArea: '',
          sampleSets: ''
        }
        // 明细或门店详情找到内找到,直接返回
        if (alreadyItem) {
          newBrandItem = {
            // 如果数据没有brandName,则使用选中的brandName
            brandName: brandItem.name,
            ...alreadyItem
          }
        }

        if (!findShopDetailItem) {
          addBrandData.push(newBrandItem)
        }

        return newBrandItem
      })

      // 找到删除的品牌, 隔墙则不存在删除
      if (!this.form.quarantine) {
        shopModelList.forEach(brandItem => {
          const findItem = shopBrandList.find(item => {
            return item.brandId === brandItem.brandId
          })
          if (!findItem) {
            const oldBrandItem = this.form.invalidBrandOfList.find(
              item => item.brandId === brandItem.brandId
            )
            invalidBrandOfList.push(oldBrandItem || brandItem)
          }
        })
      }
      this.form.shopBrandList = shopBrandList
      this.addBrandData = addBrandData

      this.form.invalidBrandOfList = invalidBrandOfList
    },
    onChangeSelectedItemShopApplicationType(selectedItem) {
      this.applicationTypeItem = selectedItem
    },
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    getCitylist({ page: currPage, getDataListParams: businessSelectedItem }) {
      if (!businessSelectedItem) return []
      return BusinessApi.shoplist({
        currPage,
        pageSize: 100,
        businessLicenseId: businessSelectedItem.id,
        bossId: businessSelectedItem.bossId
      }).then(res => {
        this.authCityCountTotal = res.totalCount
        return res.data
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundCityList(
      { value: authorizedCityCode, getDataListParams: businessSelectedItem },
      callback
    ) {
      if (!businessSelectedItem) return
      callback(
        await BusinessApi.shoplist({
          authorizedCityCode,
          businessLicenseId: (businessSelectedItem || {}).id,
          bossId: (businessSelectedItem || {}).bossId
        }).then(res => res.data)
      )
    },
    // 获取品牌系列列表
    getBrandlist({ getDataListParams: params }) {
      const [businessLicenseId, authorizedCityCode] = params.split(',')
      if (!businessLicenseId || !authorizedCityCode) return []
      return BusinessApi.brandlist({
        businessLicenseId,
        authorizedCityCode,
        pageSize: 100,
        currPage: 1
      }).then(res => res.data)
    },
    // 根据授权城市获取所有的门店详情列表
    async getShopDetailList({ getDataListParams: params }) {
      const [bossId, authorizedCityCode, shopStatus] = params.split(',')
      if (!bossId || !authorizedCityCode) return []
      return BusinessApi.shopBrand({
        bossId,
        authorizedCityCode,
        shopStatus,
        // 详情展示时,返回所有的门店列表
        type: Number(this.isOA ? 1 : 0),
        pageSize: 200,
        currPage: 1
      }).then(res => res.data)
    },
    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await BusinessApi.detail({
          declarationFormId: this.$route.query.id
        })
        initFormData = data
      }
      if (initFormData) {
        this.brandIds = (initFormData.shopBrandList || []).map(
          item => item.brandId
        )

        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
        this.dealerShop = initFormData.dealerShop
      }
      if (this.userInfo && !this.isOA) {
        const {
          optUserId,
          optJobNumber,
          optUserName,
          optDeptId,
          optDeptName,
          ...rest
        } = this.form.declarationForm || {}

        // 创建表单
        this.form.declarationForm = {
          ...rest,
          optUserId: optUserId || this.userInfo.accountId,
          optJobNumber: optJobNumber || this.userInfo.code,
          optUserName: optUserName || this.userInfo.realName,
          optDeptId: optDeptId || this.userInfo.deptId,
          optDeptName: optDeptName || this.userInfo.deptName
        }
      }
    },
    async onSubmit(type) {
      const shopBrandList = this.form.shopBrandList || []

      const cancelBrandOfId = []
      const cancelBrandOfName = []
      const addBrandOfName = []
      const addBrandOfId = []

      this.form.invalidBrandOfList.forEach(item => {
        cancelBrandOfId.push(item.brandId)
        cancelBrandOfName.push(item.brandName)
      })
      this.addBrandData.forEach(item => {
        addBrandOfId.push(item.brandId)
        addBrandOfName.push(item.brandName)
      })

      this.form.cancelBrandOfId = cancelBrandOfId.join(',')
      this.form.cancelBrandOfName = cancelBrandOfName.join(',')
      this.form.addBrandOfName = addBrandOfName.join(',')
      this.form.addBrandOfId = addBrandOfId.join(',')

      this.form.shopSampleSets = shopBrandList.reduce(
        (previousValue, currentValue) => {
          return previousValue + Number(currentValue.sampleSets) || 0
        },
        0
      )

      // 如果慕思经典系列含需要装修状态时，芯睡眠、助眠系列需加上装修状态为1
      const derucciClassicSeriesIds = this.allBrandData.filter(item => {
        return item.parentId === this.bigBrandMap.get('慕思经典')?.id && ![this.seriesMap.get('助眠系列').id, this.seriesMap.get('芯睡眠').id].includes(item.id)
      }).map(item => item.id)
      const isNeedDecorate = shopBrandList.some(
        item => derucciClassicSeriesIds.includes(item.brandId) && Number(item.ifEnabled) === 1
      )
      // 助眠系列，芯睡眠
      shopBrandList.forEach(item => {
        if ([this.seriesMap.get('助眠系列').id, this.seriesMap.get('芯睡眠').id].includes(item.brandId)) {
          console.log(item, String(Number(isNeedDecorate)))
          item.ifEnabled = String(Number(isNeedDecorate))
        }
      })

      if (!this.isClassic) {
        this.form.marketCenter = ''
        this.form.marketCenterCode = ''
      }

      if (type === 'submit') {
        this.form.updateUserName = this.userInfo.realName
        if (['慕思经典', '慕思经典-新渠道', '慕思经典+新渠道'].includes(this.form.applicationType)) {
          MessageBox.alert('请走慕思经典门店管理流程')
          return Promise.reject('请走慕思经典门店管理流程')
        }
        // this.form.declarationForm.status = 1
        return BusinessApi.shopSubmit(this.form)
      } else {
        // this.form.declarationForm.status = 0
        return BusinessApi.addSponsor(this.form)
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.red {
  color: red;
}
</style>
