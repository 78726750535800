<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      class="flow-form"
      :inline="true"
      label-position="top">
      <header-title title="转让城市情况" />
      <RequiredFormItemInput
        class="formItem"
        label="省份"
        placeholder="自动生成"
        v-model="authorizedCityParams.province"
        :required="false"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="地级市"
        placeholder="自动生成"
        v-model="authorizedCityParams.city"
        :required="false"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="区/县"
        placeholder="自动生成"
        v-model="authorizedCityParams.area"
        :required="false"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="授权城市确认"
        placeholder="自动生成"
        v-model="authorizedCityParams.authorizedCityName"
        :required="false"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="市场级别判定"
        placeholder="自动生成"
        v-model="authorizedCityParams.marketLevelName"
        :required="false"
        disabled />
      <RequiredModuleFormItemSelect
        class="formItem"
        label="开店类型"
        v-model="form.createShopType"
        type="open_type"
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        label="授权品牌确认"
        placeholder="自动生成"
        :value="brandIdList"
        multiple
        disabled
        :dataList="brandList"
        labelKey="name"
        valueKey="brandId" />
      <RequiredFormItemInput
        class="formItem"
        prop="localGdp"
        label="当地GDP"
        :rules="rules.localGdp"
        v-model="form.localGdp"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="perCapitaGdp"
        label="人均GDP"
        :rules="rules.perCapitaGdp"
        v-model="form.perCapitaGdp"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="plannedInvestment"
        label="计划投入资金（万）"
        :rules="rules.plannedInvestment"
        v-model="form.plannedInvestment"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="otherCapitalSource"
        label="其他资金来源"
        v-model="form.otherCapitalSource"
        :required="form.capitalSource == '其他'"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="urbanPopulation"
        label="城镇人口数量（万人）"
        :rules="rules.urbanPopulation"
        v-model="form.urbanPopulation"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="perCapitaIncome"
        label="人均可支配收入"
        :rules="rules.perCapitaIncome"
        v-model="form.perCapitaIncome"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="housePurchaseQuantity"
        label="今年市场交楼数量"
        :rules="rules.housePurchaseQuantity"
        v-model="form.housePurchaseQuantity"
        type="number"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="houseAveragePrice"
        label="房价均价"
        :rules="rules.houseAveragePrice"
        v-model="form.houseAveragePrice"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="planShopQuantity"
        label="首次计划开店数"
        :rules="rules.planShopQuantity"
        v-model="form.planShopQuantity"
        type="number"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        prop="advCost"
        label="计划投入广告费用"
        :rules="rules.advCost"
        v-model="form.advCost"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="modeOfOperation"
        label="代理店的经营方式"
        type="mode_of_operation"
        v-model="form.modeOfOperation"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="managementStyle"
        label="代理店的管理方式"
        type="management_style"
        v-model="form.managementStyle"
        :disabled="isOA" />
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemInput,
  RequiredFormItemSelect,
  RequiredModuleFormItemSelect,
  RequestModuleSelect
} from '@/components/CustomFormItem'
import { mapGetters, mapState } from 'vuex'
import { getJoinCityDetail } from '@/api/common'
import { checkNumber, checkFloatNumber } from '@/utils/validate'
import HeaderTitle from './headerTitle.vue'
export default {
  props: {
    formStatus: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    HeaderTitle,
    RequestModuleSelect,
    RequiredFormItemInput,
    RequiredModuleFormItemSelect,
    RequiredFormItemSelect
  },
  data() {
    return {
      form: {
        createShopType: '2', //开店类型
        localGdp: '', //当地GDP
        perCapitaGdp: '', //人均GDP
        plannedInvestment: '', //计划投入资金（万）
        capitalSource: '', //资金来源
        otherCapitalSource: '', //其他资金来源
        urbanPopulation: '', //城镇人口数量（万人）
        perCapitaIncome: '', //人均可支配收入
        housePurchaseQuantity: '', //今年市场交楼数量
        houseAveragePrice: '', //房价均价
        planShopQuantity: '', //首次计划开店数
        advCost: '', //计划投入广告费用
        modeOfOperation: '', //代理店的经营方式
        managementStyle: '' //代理店的管理方式
      },
      rules: {
        localGdp: [
          { validator: checkFloatNumber, trigger: ['blur', 'change'] }
        ], //当地GDP
        perCapitaGdp: [
          { validator: checkFloatNumber, trigger: ['blur', 'change'] }
        ], //人均GDP
        plannedInvestment: [
          { validator: checkFloatNumber, trigger: ['blur', 'change'] }
        ], //计划投入资金（万）
        urbanPopulation: [
          { validator: checkNumber, trigger: ['blur', 'change'] }
        ], //城镇人口数量（万人）
        perCapitaIncome: [
          { validator: checkFloatNumber, trigger: ['blur', 'change'] }
        ], //人均可支配收入
        housePurchaseQuantity: [
          { validator: checkNumber, trigger: ['blur', 'change'] }
        ], //今年市场交楼数量
        houseAveragePrice: [
          { validator: checkFloatNumber, trigger: ['blur', 'change'] }
        ], //房价均价
        planShopQuantity: [
          { validator: checkNumber, trigger: ['blur', 'change'] }
        ], //首次计划开店数
        advCost: [{ validator: checkFloatNumber, trigger: ['blur', 'change'] }] //计划投入广告费用
      }
    }
  },
  watch: {
    historicalData: {
      handler(val, old) {
        console.log('dealerJoinCity', val)
        let { bossJoinModel } = val
        this.form = { ...this.from, ...bossJoinModel.dealerJoinCity }
      },
      deep: true
    },
    authorizedCityParams: {
      handler(authorizedCityParams) {
        this.getJoinCityDetail(authorizedCityParams.authorizedCityCode)
      },
      deep: true
    }
  },
  computed: {
    ...mapState('dealershipTransfer', [
      'senderList',
      'isOA',
      'historicalData',
      'brandList',
      'authorizedCityParams',
      'brandIdList'
    ])
  },
  methods: {
    async getJoinCityDetail(authorizedCityCode) {
      let { code, data } = await getJoinCityDetail({ authorizedCityCode })
      if (code != 0 || data.length == 0 || this.form.id == data[0].id) return
      this.form = { ...this.form, ...data[0], createShopType: '2' }
    }
  }
}
</script>
