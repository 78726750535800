<template>
  <FlowForm
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="希诺米海外渠道合作申请表"
  >

    <RequiredFormItemInput delay-update class="formItem" :value="form.dealerDeclarationForm.title = title" label="标题" placeholder="自动生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.dealerDeclarationForm.orderNumber" label="单号" placeholder="自动生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.zsOrderNumber" label="招商单号" placeholder="自动生成" disabled />

    <RequiredFormItemInput delay-update class="formItem" :value="form.dealerDeclarationForm.optJobNumber" label="提单人工号" placeholder="自动生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.dealerDeclarationForm.optUserName" label="提单人" placeholder="自动生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.dealerDeclarationForm.optDeptName" label="提单部门" placeholder="自动生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.dealerDeclarationForm.optTime" label="提单日期" placeholder="自动生成" disabled />

    <RequiredFormItemInput delay-update class="formItem" :value="form.finalBusinessLicenseName" label="最终售达方名称" placeholder="归档后生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.finalLicenseNumber" label="最终售达方编号" placeholder="归档后生成" disabled />
    <RequiredFormItemInput delay-update class="formItem" :value="form.finalSenderNumber" label="最终送达方编号" placeholder="归档后生成" disabled />

    <RequiredFormItemInput delay-update class="formItem" label="经销商平台账号" :value="form.finalBossAccount" placeholder="归档后生成" disabled />

    <HeaderTitle style="margin-top: 20px" title="营业执照信息" />
    <RequiredFormItemInput v-model="form.businessLicenseName" prop="businessLicenseName" class="formItem" label="营业执照名称" @blur="onBusinessLicenseNameBlur" />
    <RequiredFormItemInput v-model="form.businessLicenseShortName" prop="businessLicenseShortName" class="formItem" label="营业执照简称" />
    <RequiredFormItemInput v-model="form.businessLicenseAddress" prop="businessLicenseAddress" class="formItem" label="客户地址" />
    <RequiredModuleFormItemSelect
      v-model="form.countryCode"
      module="public"
      type="country"
      prop="countryCode"
      class="formItem"
      label="国家"
      @change="
        () => {
          form.firstAdministrativeRegionCode = ''
          form.firstAdministrativeRegionName = ''
          form.secondAdministrativeRegionCode = ''
          form.secondAdministrativeRegionName = ''
        }
      "
      @changeSelectedItem="(e)=>form.countryName = e?.content"
    />
    <RequiredModuleFormItemSelect
      v-model="form.firstAdministrativeRegionCode"
      dependent-parent-code
      empty-disabled
      :parent-code="form.countryCode"
      module="public"
      type="province"
      prop="firstAdministrativeRegionCode"
      class="formItem"
      label="一级行政区域"
      @change="
        () => {
          form.secondAdministrativeRegionCode = ''
          form.secondAdministrativeRegionName = ''
        }
      "
      @changeSelectedItem="(e)=>form.firstAdministrativeRegionName = e?.content"
    />
    <RequiredModuleFormItemSelect v-model="form.secondAdministrativeRegionCode" dependent-parent-code empty-disabled :parent-code="form.firstAdministrativeRegionCode" module="public" type="city" prop="secondAdministrativeRegionCode" class="formItem" label="二级行政区域" @changeSelectedItem="(e)=>form.secondAdministrativeRegionName = e?.content" />
    <RequiredModuleFormItemSelect v-model="form.salesDepartmentCode" type="overseas_sinomi_department" prop="salesDepartmentCode" class="formItem" label="销售部门" @changeSelectedItem="(e)=>form.distributionChannelName = e?.content" />
    <RequiredModuleFormItemSelect v-model="form.distributionChannelCode" type="cooperation_code" prop="distributionChannelCode" class="formItem" label="销售渠道" @changeSelectedItem="(e)=>form.salesDepartmentName = e?.content" />
    <RequiredModuleFormItemSelect v-model="form.sendOtherCountryFlag" type="flow_common_select_ifEnable" prop="sendOtherCountryFlag" class="formItem" label="是否需要发往他国" />
    <RequiredModuleFormItemSelect v-model="form.sendOtherCountryName" prop="sendOtherCountryName" module="public" type="country" class="formItem" value-key="content" label="发往的国家" :required="!!Number(form.sendOtherCountryFlag)" />
    <RequiredFormItemInput v-model="form.contactPersonName" prop="contactPersonName" class="formItem" label="联系人名称" />
    <RequiredFormItemInput v-model="form.contactPersonPhone" prop="contactPersonPhone" class="formItem" label="联系人电话" />
    <RequiredFormItemInput v-model="form.eMail" prop="eMail" class="formItem" label="联系人邮箱" />
    <RequiredModuleFormItemSelect v-model="form.customerOriginCode" type="dealer_source" prop="customerOriginCode" class="formItem" label="客户来源" @changeSelectedItem="(e)=>form.customerOriginName = e?.content" />
    <RequiredModuleFormItemSelect v-model="form.tradingItemCode" type="exchange_provision" prop="tradingItemCode" class="formItem" label="交易条款" @changeSelectedItem="(e)=>form.tradingItemName = e?.content" />
    <RequiredModuleFormItemSelect v-model="form.paymentConditionCode" type="settlement_type" prop="paymentConditionCode" class="formItem" label="付款方式" @changeSelectedItem="(e)=>form.paymentConditionName = e?.content" />
    <RequiredFormItemInput v-model="form.bank" prop="bank" class="formItem" :required="false" label="开户行名称" />
    <RequiredFormItemInput v-model="form.bankCode" prop="bankCode" class="formItem" :required="false" label="银行代码" />
    <RequiredFormItemInput v-model="form.bankAccount" prop="bankAccount" class="formItem" :required="false" label="银行账号" />
    <RequiredFormItemDatePicker v-model="form.authorizeTime" prop="authorizeTime" class="formItem" label="客户合作时间" />
    <RequiredFormItemInput v-model="form.transactionCurrency" prop="transactionCurrency" class="formItem" label="交易币别" placeholder="美元" />
    <RequiredFormItemSelect
      v-model="form.businessOwnerAccount"
      :get-data-list="getUserList"
      prop="businessOwnerAccount"
      class="formItem"
      label="业务负责人"
      value-key="account"
      :label-key="({username,account})=>`${username}（${account}）`"
      filterable
      remote
      clearable
      @onOptionsListNotFound="onNotFoundUserList"
      @onChangeItem="(selectItem)=> {
        form.businessOwnerName = selectItem?.username
      }"
    />
    <RequiredFormItemInput v-model="form.businessOwnerAccount" disabled prop="businessOwnerAccount" class="formItem" label="业务负责人工号" />

    <HeaderTitle style="margin-top: 20px" title="实控人信息" />
    <RequiredFormItemInput v-model="form.contactInfo" prop="contactInfo" class="formItem" label="实控人电话" />
    <RequiredFormItemInput v-model="form.realName" :disabled="!!bossInfo" prop="realName" class="formItem" label="实控人名称" />
    <RequiredFormItemSelect
      ref="shopRef"
      v-model="form.authorizedCityCode"
      class="formItem"
      prop="authorizedCityCode"
      label="授权城市"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      filterable
      remote
      :get-data-list="getCitylist"
      @onOptionsListNotFound="onNotFoundCityList"
      @changeSelectedItem="
        e => {
          form.authorizedCityName = e ? e.authorizedCityName : ''
        }
      "
    >
      <template v-slot="{ scope }">
        <span>{{ scope.row.province !== scope.row.authorizedCityName ? `${scope.row.province}-`:'' }}{{ scope.row.authorizedCityName }}</span>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemSelect
      ref="applyBrandListRef"
      prop="applySeriesList"
      :value="form.applySeriesList.map(item => item.code)"
      multiple
      class="formItem"
      label-key="name"
      value-key="code"
      label="授权系列"
      placeholder="下拉选择/目前仅支持A2系列"
      :get-data-list="getBrandseriesList"
      @onChangeItem="changeApplyBrandListSelectedItem"
    />

    <HeaderTitle style="margin-top: 20px" title="事由说明" />
    <RequiredFormItemInput v-model="form.remark" prop="remark" style="width: 100%;" type="textarea" class="formItem" label="事由说明" />

    <RequiredFormItemUploadFile
      v-model="form.orderContractFileList"
      prop="orderContractFileList"
      class="formItem"
      label="上传附件"
      limit-size="400"
      style="width:100%"
      :on-preview="onPreview"
    >
      <div slot="describe">（客户签字的订单合同（采购合同）/电子档）</div>
      <div slot="tip">单个文件不能超过400M</div>
    </RequiredFormItemUploadFile>
  </FlowForm>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { nextTick, ref, computed, watch } from 'vue'
import FlowForm from './components/flowForm.vue'
import HeaderTitle from './components/headerTitle.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect.vue'

import * as overseasChannelJoinApi from '@/api/flow/overseasSinomiChannelJoinApi.js'
import { authorizedcityListpage } from '@/api/businessOos'
import { brandseriesList, requestBossListByContactInfo, requestBossListByBusinessLicenseName, requestGetUserList } from '@/api/common'

import store from '@/store'
import { useRoute } from '@/utils/router'
import { debounce } from 'throttle-debounce'

const route = useRoute()

const flowFormRef = ref(null)
const bossInfo = ref(null)
const form = ref({
  dealerDeclarationForm: {
    id: '', // 有id需要提交
    title: '',
    optUserId: '', // 提单人id
    optUserName: '',
    optJobNumber: '',
    optDeptId: '', // 部门id
    optDeptName: '',
    optTime: '' // 提单日期
  },

  bossId: '', // 实控人id，非必须，存在的实控人加上
  businessLicenseId: '', // 营业执照id，非必须，存在的实控人加上

  bossAccount: '', // 经销商平台账号

  finalBossAccount: '',
  finalBossId: '',
  finalBusinessLicenseId: '',
  finalBusinessLicenseName: '',
  finalLicenseNumber: '',
  finalSenderNumber: '',

  authorizedCityCode: '', //	是	strng	授权城市编码
  authorizedCityName: '', //	是	strng	授权城市名称

  applySeriesList: [], // 授权系列

  realName: '', // 真实姓名
  contactInfo: '', // 联系方式
  businessLicenseName: '', // 营业执照名称
  businessLicenseShortName: '', // 售达方简称
  businessLicenseAddress: '', // 客户地址（执照地址）
  countryCode: '', // 国家编码
  countryName: '', // 国家名字
  firstAdministrativeRegionCode: '', // 一级行政区域编码
  firstAdministrativeRegionName: '', // 一级行政区域名称
  secondAdministrativeRegionCode: '', // 二级行政区域编码
  secondAdministrativeRegionName: '', // 二级行政区域名称
  distributionChannelCode: '', // 销售渠道编码
  distributionChannelName: '', // 销售渠道 中文名称
  salesDepartmentCode: '', // 销售部门编码
  salesDepartmentName: '', // 销售部门 中文名称
  sendOtherCountryFlag: '', // 是否需要发往他国 1是，0否
  sendOtherCountryName: '', // 发往国家
  contactPersonName: '', // 联系人
  contactPersonPhone: '', // 联系电话
  eMail: '', // 负责人邮箱
  customerOriginCode: '', // 客户来源编码
  customerOriginName: '', // 客户来源 中文名称
  tradingItemCode: '', // 交易条款编码
  tradingItemName: '', // 交易条款 中文名称
  paymentConditionCode: '', // 付款方式 编码
  paymentConditionName: '', // 付款方式 中文名称
  bank: '', // 开户行
  bankCode: '', // 开户行编号
  bankAccount: '', // 开户行账号
  authorizeTime: '', // 授权时间
  transactionCurrency: '美元', // 交易币别
  businessOwnerName: '', // 业务负责人名称
  businessOwnerAccount: '', // 业务负责人工号
  remark: '', // 备注/事由说明
  // 订单合同附件
  orderContractFileList: []
})

watch(() => form.value.contactInfo, debounce(1000, function(contactInfo) {
  if (!store.getters['dealershipTransfer/isOA']) {
    if (contactInfo) {
      onContactInfoChange()
    } else {
      bossInfo.value = null
    }
  }
}))

const title = computed(() => {
  const list = []

  const seriesList = []
  form.value.applySeriesList.forEach((item, index) => {
    if (!index) {
      seriesList.push(`授权品牌：${item.name}`)
    } else {
      seriesList.push(item.name)
    }
  })
  if (seriesList.length) {
    list.push(seriesList.join('、'))
  }

  if (form.value.authorizedCityName) {
    list.push(`授权城市：${form.value.authorizedCityName}`)
  }

  let str = '希诺米海外渠道合作申请表'
  if (list.length) {
    str += `（${list.join('，')}）`
  }
  return str
})

async function onSubmit(type) {
  const { data } = await overseasChannelJoinApi.saveDraft(form.value)
  if (type !== 'submit') {
    return formId
  }
  const formId = data.id
  const formData = new FormData()
  formData.append('formId', formId)
  return overseasChannelJoinApi.submit(formData)
}
async function getDetail(type, cachedFormData) {
  let initFormData = cachedFormData
  // 编辑状态
  if (type === 'detail') {
    const { data } = await overseasChannelJoinApi.detail({
      formId: route.query.id
    })
    initFormData = data
  }
  if (initFormData) {
    for (const key in form.value) {
      if (initFormData[key] !== undefined) {
        form.value[key] = initFormData[key]
      }
    }
  }
  if (store.getters.userInfo && !store.getters['dealershipTransfer/isOA']) {
    const {
      optUserId,
      optJobNumber,
      optUserName,
      optDeptId,
      optDeptName,
      ...rest
    } = form.value.dealerDeclarationForm || {}
    form.value.dealerDeclarationForm = {
      ...rest,
      optUserId: store.getters.userInfo.accountId,
      optJobNumber: store.getters.userInfo.code,
      optUserName: store.getters.userInfo.realName,
      optDeptId: store.getters.userInfo.deptId,
      optDeptName: store.getters.userInfo.deptName
    }
  }
}
function onPreview(file) {
  window.open(file.url)
}

async function getUserList({ page, searchText: searchVal }) {
  return requestGetUserList({
    page,
    limit: 10,
    searchVal
  }).then(res => {
    const { list, ...result } = res.page
    return { data: list, ...result }
  })
}
async function onNotFoundUserList({ value: searchVal }, callback) {
  callback(
    await requestGetUserList({
      searchVal
    }).then(res => res.page.list)
  )
}

function getCitylist({ page: currPage, searchText: authorizedCityName }) {
  return authorizedcityListpage({
    currPage,
    addr: authorizedCityName,
    pageSize: 10,
    overseasFlag: 1,
    ifEnabled: 0
  })
}
// 当前列表没有默认选项,触发
async function onNotFoundCityList({ value: authorizedCityCode }, callback) {
  callback(
    await authorizedcityListpage({
      authorizedCityCode
    }).then(res => res.data)
  )
}

function getBrandseriesList(params) {
  return brandseriesList({
    brandLevel: 1,
    code: '60'
  }).then(res => res.data)
}
const applyBrandListRef = ref(null)
function changeApplyBrandListSelectedItem(selectItems) {
  form.value.applySeriesList = (selectItems || []).map(({ id, code, name }) => ({ id, code, name }))
  nextTick(() => {
    flowFormRef.value.$refs.ruleForm.validateField('applySeriesList')
  })
}

async function onContactInfoChange() {
  return requestBossListByContactInfo({
    contactInfo: form.value.contactInfo,
    ifEnabled: 0
  }).then(({ data }) => {
    bossInfo.value = data[0]
    if (bossInfo.value?.realName) {
      form.value.realName = bossInfo.value?.realName
    }
  })
}

async function onBusinessLicenseNameBlur() {
  if (!form.value.businessLicenseName) {
    return
  }
  const data = await requestBossListByBusinessLicenseName({
    businessLicenseName: form.value.businessLicenseName,
    ifEnabled: 0
  }).then(res => res.data[0])
  form.value.businessLicenseId = data?.id || ''
  form.value.bossId = data?.bossId
  if (data) {
    form.value.contactInfo = data.bossContactInfo

    form.value.businessLicenseShortName = data.businessLicenseShortName
    form.value.businessLicenseAddress = data.businessLicenseAddress
    form.value.countryCode = data.countryCode
    form.value.countryName = data.countryName
    form.value.firstAdministrativeRegionCode = data.firstAdministrativeRegionCode
    form.value.firstAdministrativeRegionName = data.firstAdministrativeRegionName
    form.value.secondAdministrativeRegionCode = data.secondAdministrativeRegionCode
    form.value.secondAdministrativeRegionName = data.secondAdministrativeRegionName
    form.value.distributionChannelCode = data.distributionChannelCode
    form.value.distributionChannelName = data.distributionChannelName
    form.value.salesDepartmentCode = data.salesDepartmentCode
    form.value.salesDepartmentName = data.salesDepartmentName
    form.value.sendOtherCountryFlag = data.sendOtherCountryFlag
    form.value.sendOtherCountryName = data.sendOtherCountryName
    form.value.contactPersonName = data.contactPersonName
    form.value.contactPersonPhone = data.contactPersonPhone
    form.value.eMail = data.eMail
    form.value.customerOriginCode = data.customerOriginCode
    form.value.customerOriginName = data.customerOriginName
    form.value.tradingItemCode = data.tradingItemCode
    form.value.tradingItemName = data.tradingItemName
    form.value.paymentConditionCode = data.paymentConditionCode
    form.value.paymentConditionName = data.paymentConditionName
    form.value.bank = data.bank
    form.value.bankCode = data.bankCode
    form.value.bankAccount = data.bankAccount
    form.value.authorizeTime = data.authorizeTime
    form.value.transactionCurrency = data.transactionCurrency
    form.value.businessOwnerName = data.businessOwnerName
    form.value.businessOwnerAccount = data.businessOwnerAccount
  }
}
</script>

<style scoped lang="scss"></style>
