var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HeaderTitle',{staticStyle:{"margin-top":"10px"},attrs:{"id":"anchor-bz","title":"备注"}}),_c('RequiredFormItemInput',{staticClass:"formItem",staticStyle:{"width":"100%"},attrs:{"delay-update":"","inputProps":{
      type: 'textarea',
      resize: 'none',
      rows: 6,
      maxlength: '500',
      showWordLimit: true
    },"required":false,"label":"备注","disabled":_vm.disabled},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_c('div',[_c('UploadFile',{staticClass:"formItem",attrs:{"label":"上传附件","required":false,"disabled":_vm.disabled},on:{"onUploaded":file => _vm.handleUpload(file)},model:{value:(_vm.imageList),callback:function ($$v) {_vm.imageList=$$v},expression:"imageList"}},[_c('div',{attrs:{"slot":"tip"},slot:"tip"},[_vm._v("单文件不能超过400M")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }