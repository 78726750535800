<template>
  <div>
    <el-select placeholder="请选择" v-model="address" @change="getAddress">
      <el-option v-for="item in addressList" :key="item.id" :label="item[type]" :value="item.id" />
    </el-select>
  </div>
</template>

<script>
  import {
    getProvinceOrCityOrAreaList
  } from "@/api/common/";
  export default {
    data() {
      return {
        address: '',
        addressList: []
      }
    },
    props: {
      type: {
        type: String,
        default: 'province'
      },
      province: {
        type: String || Number,
        default: null
      },
      city: {
        type: String || Number,
        default: null
      },
      value:{
          type:String,
          default:''
      }
    },
    created() {
      console.log(this.type);
      if (this.type == 'province') {
        this.getProvinceData(this.type).then(r => {
          this.addressList = r.filter(item=>item[this.type])
        })
      }
    },
    watch:{
        province(newVal) {

        }
    },
    methods: {
      // 获取省市区接口
      async getProvinceData(type, province = null, city = null) {
        const res = await getProvinceOrCityOrAreaList({
          type,
          province,
          city
        })
        if (!res.code) {
          return res.data
        }
      },
      getAddress(e) {
        const data = this.addressList.find(item=>item.id == e)
        this.$emit('handleChange',data)
        this.$emit('input',e)
      }
    },
  }

</script>

<style lang='scss' scoped>

</style>
