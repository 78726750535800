<template>
  <div class="dealerInterview">
    <el-form
      class="flow-form"
      :model="formData"
      :inline="true"
      ref="ruleForm"
      label-position="top"
      @validate="handleValidate"
    >
      <HeaderTitle style="margin-bottom: 10px">
        <template #form>
          <div>
            <RequestInput
              class="formItem"
              label="流程标题"
              :value="flowTitle"
              :required="false"
              readonly
            />
          </div>
        </template>
      </HeaderTitle>
      <HeaderTitle title="与经销商个人基础信息">
        <template #form>
          <div>
            <RequestInput
              delay-update
              prop="realName"
              class="formItem"
              label="经销商（实控人）"
              v-model="formData.realName"
            />
            <RequestInput
              delay-update
              prop="idCard"
              class="formItem"
              label="身份证"
              v-model="formData.idCard"
              type="number"
              :validations="validations['idCard'] || []"
            />
            <RequestInput
              delay-update
              prop="contactInfo"
              class="formItem"
              label="联系方式"
              v-model="formData.contactInfo"
              type="number"
              :validations="validations['contactInfo'] || []"
            />
            <RequestSelect
              prop="sex"
              class="formItem"
              label="性别"
              v-model="formData.sex"
              type="sex"
            />
            <RequestSelect
              prop="education"
              class="formItem"
              label="学历"
              v-model="formData.education"
              type="education"
            />
            <RequestInput
              delay-update
              prop="age"
              class="formItem"
              label="年龄"
              v-model="formData.age"
              type="number"
              :validations="validations['age'] || []"
            />
            <RequestSelect
              prop="maritalStatus"
              class="formItem"
              label="婚姻状况"
              v-model="formData.maritalStatus"
              type="marital_status"
            />
            <RequestInput
              delay-update
              prop="nativePlace"
              class="formItem"
              label="籍贯"
              v-model="formData.nativePlace"
            />
          </div>
        </template>
      </HeaderTitle>
      <HeaderTitle title="与慕思的合作情况">
        <template v-if="!isOA">
          <div>
            <el-button type="primary" @click="addDealerMsg">新增</el-button>
            <el-button
              :disabled="!dealerCooperationRecordListRowId.length"
              type="danger"
              @click="delDealerMsg"
              >删除</el-button
            >
          </div>
        </template>
        <template #form>
          <el-form-item style="width: 100%">
            <el-table
              border
              row-key="sort"
              ref="filterTable"
              :data="formData.dealerCooperationRecordList"
              @selection-change="handleSelectionDealerChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="序号" type="index" width="55">
              </el-table-column>
              <el-table-column label="省份">
                <template #default="{ row, $index }">
                  <div>
                    <!-- 
                      :parent-code="authorizedCityMsg.cityCode" -->
                    <RequestSelect
                      :prop="`dealerCooperationRecordList.${$index}.province`"
                      class="selectItem"
                      :showLabel="false"
                      v-model="row.province"
                      label="省份"
                      type="province"
                      module="public"
                      valueKey="content"
                      @changeSelectedItem="
                        e => (row.provinceCode = e ? e.code : '')
                      "
                      :selectListeners="{
                        change: data => {
                          if (data) {
                            row.city = ''
                            row.cityCode = ''
                            row.agentCity = ''
                          }
                        }
                      }"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="地级市">
                <template #default="{ row, $index }">
                  <div>
                    <!-- 
                      :parent-code="authorizedCityMsg.cityCode" -->
                    <RequestSelect
                      :disabled="!!!row.province"
                      :prop="`dealerCooperationRecordList.${$index}.city`"
                      class="selectItem"
                      :showLabel="false"
                      v-model="row.city"
                      label="地级市"
                      :parent-code="row.provinceCode"
                      type="city"
                      module="public"
                      valueKey="content"
                      dependentParentCode
                      @changeSelectedItem="
                        e => (row.cityCode = e ? e.code : '')
                      "
                      :selectListeners="{
                        change: data => {
                          if (data) {
                            row.agentCity = ''
                          }
                        }
                      }"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="代理城市">
                <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
                <template #default="{ row, $index }">
                  <div>
                    <!-- 
                      :parent-code="authorizedCityMsg.cityCode" -->
                    <RequestSelect
                      :disabled="!!!row.city"
                      :prop="`dealerCooperationRecordList.${$index}.agentCity`"
                      class="selectItem"
                      :showLabel="false"
                      v-model="row.agentCity"
                      label="代理城市"
                      :parent-code="row.cityCode"
                      type="area"
                      module="public"
                      valueKey="content"
                      dependentParentCode
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="代理品牌（加盟品牌）">
                <template #default="{ row, $index }">
                  <div>
                    <required-brand-form-item-select
                      class="selectItem"
                      :prop="`dealerCooperationRecordList.${$index}.agentBrand`"
                      :show-label="false"
                      label="代理品牌（加盟品牌）"
                      v-model="row.agentBrand"
                      :old-value-keys="['id']"
                      :select-props="{
                        valueKey: 'name'
                      }"
                      :value-translate="
                        value => {
                          return value && typeof value === 'string'
                            ? value.split(',')
                            : []
                        }
                      "
                      :value-output-translate="
                        value => {
                          if (
                            Object.prototype.toString.call(value) ===
                            '[object Array]'
                          ) {
                            return value.join(',')
                          }
                          return value
                        }
                      "
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="近三年提货情况（万）">
                <template #default="{ row, $index }">
                  <div>
                    <!--   :prop="`dealerCooperationRecordList.${$index}.dealerLastThreeYearApplySituation`" -->
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`dealerCooperationRecordList.${$index}.applySituation`"
                      v-model="row.applySituation"
                      label="近三年提货情况"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </HeaderTitle>
      <HeaderTitle title="现在从事经营的行业情况">
        <template v-if="!isOA">
          <div>
            <el-button type="primary" @click="addCurrent">新增</el-button>
            <el-button
              :disabled="!dealerBusinessRecordListRowId.length"
              type="danger"
              @click="delCurrent"
              >删除</el-button
            >
          </div>
        </template>
        <template #form>
          <el-form-item style="width: 100%">
            <el-table
              border
              row-key="sort"
              ref="filterTable"
              :data="formData.dealerBusinessRecordList"
              @selection-change="handleSelectionCurrentChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="序号" type="index" width="55">
              </el-table-column>
              <el-table-column label="品牌名称">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`dealerBusinessRecordList.${$index}.brandName`"
                      v-model="row.brandName"
                      label="品牌名称"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="行业">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`dealerBusinessRecordList.${$index}.industryType`"
                      v-model="row.industryType"
                      label="行业"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="经营开始时间">
                <template #default="{ row, $index }">
                  <div>
                    <RequestDatePick
                      class="selectItem"
                      :prop="`dealerBusinessRecordList.${$index}.businessStartTime`"
                      v-model="row.businessStartTime"
                      label="经营开始时间"
                      :showLabel="false"
                      type="date"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="经营情况概述（年销售额）（万）">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`dealerBusinessRecordList.${$index}.businessSummary`"
                      v-model="row.businessSummary"
                      label="经营情况概述（年销售额）"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="从事经营时间">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`dealerBusinessRecordList.${$index}.businessTotalTime`"
                      v-model="row.businessTotalTime"
                      label="从事经营时间"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </HeaderTitle>
      <HeaderTitle title="当地市场情况（建材、家居、家电）">
        <template v-if="!isOA">
          <div>
            <el-button type="primary" @click="addMarketData">新增</el-button>
            <el-button
              :disabled="!marketDataListRowId.length"
              type="danger"
              @click="delMarketData"
              >删除</el-button
            >
          </div>
        </template>
        <template #form>
          <el-form-item style="width: 100%">
            <el-table
              border
              row-key="sort"
              ref="filterTable"
              :data="formData.localMarketSituationList"
              @selection-change="handleSelectionMarketChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="序号" type="index" width="55">
              </el-table-column>
              <el-table-column label="品牌名称">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`localMarketSituationList.${$index}.brandName`"
                      v-model="row.brandName"
                      label="品牌名称"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="经营情况概述">
                <template #default="{ row, $index }">
                  <div>
                    <RequestInput
                      delay-update
                      class="selectItem"
                      :prop="`localMarketSituationList.${$index}.businessSummary`"
                      v-model="row.businessSummary"
                      label="经营情况概述"
                      :showLabel="false"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </HeaderTitle>
      <HeaderTitle title="申请加盟城市情况">
        <template #form>
          <div>
            <RequestFormSelect
              prop="joinProvince"
              class="formItem"
              v-model="formData.joinProvince"
              label="省份"
              :get-data-list="getProvince"
              value-key="province"
              label-key="province"
              @changeSelectedItem="
                e => (formData.joinProvinceCode = e ? e.provinceCode : '')
              "
              :selectListeners="{
                change: data => {
                  if (data) {
                    formData.joinCity = ''
                    formData.joinCityCode = ''
                    formData.joinArea = ''
                    formData.joinAreaCode = ''
                    formData.joinTown = ''
                  }
                }
              }"
            />
            <RequestFormSelect
              prop="joinCity"
              :disabled="!!!formData.joinProvince"
              class="formItem"
              v-model="formData.joinCity"
              label="地级市"
              :get-data-list="getAuthCityList"
              :get-data-list-params="formData.joinProvince"
              value-key="city"
              label-key="city"
              @changeSelectedItem="
                e => (formData.joinCityCode = e ? e.cityCode : '')
              "
              :selectListeners="{
                change: data => {
                  if (data) {
                    formData.joinArea = ''
                    formData.joinAreaCode = ''
                    formData.joinTown = ''
                  }
                }
              }"
            />
            <RequestFormSelect
              prop="joinArea"
              :disabled="!!!formData.joinCity"
              class="formItem"
              v-model="formData.joinArea"
              :required="false"
              label="区/县"
              :get-data-list="getAuthAreaList"
              :get-data-list-params="`${formData.joinProvince},${formData.joinCity}`"
              value-key="area"
              label-key="area"
              @changeSelectedItem="
                e => (formData.joinAreaCode = e ? e.areaCode : '')
              "
              :selectListeners="{
                change: data => {
                  if (data) {
                    formData.joinTown = ''
                  }
                }
              }"
            />
            <RequestInput
              delay-update
              :required="false"
              prop="joinTown"
              class="formItem"
              label="镇/乡"
              v-model="formData.joinTown"
            />
            <RequestFormSelect
              :disabled="!formData.joinCity"
              prop="authorizedCode"
              class="formItem"
              label="授权城市确认"
              :get-data-list="getAuthAreaList"
              :get-data-list-params="`${formData.joinProvince},${formData.joinCity}`"
              v-model="formData.authorizedCode"
              :label-key="
                item => `${item.city}${item.area || ''}${item.town || ''}`
              "
              valueKey="authorizedCityCode"
              @changeSelectedItem="
                e => {
                  formData.authorizedCity = e ? e.authorizedCityName : ''
                  formData.cityLevel = e ? e.marketLevelName : ''
                }
              "
            />
            <RequestInput
              delay-update
              prop="cityLevel"
              class="formItem"
              label="城市级别"
              v-model="formData.cityLevel"
              readonly
              :disabled="true"
            />
            <required-brand-form-item-select
              ref="dealeShopBrandRef"
              class="formItem"
              prop="applyBrandSeries"
              v-model="formData.applyBrandSeries"
              label="授权品牌确认"
              :select-props="{
                valueKey: 'name'
              }"
              :value-translate="
                value => {
                  return value && typeof value === 'string'
                    ? value.split(',')
                    : []
                }
              "
              :value-output-translate="
                value => {
                  if (
                    Object.prototype.toString.call(value) === '[object Array]'
                  ) {
                    return value.join(',')
                  }
                  return value
                }
              "
              @changeSelectedItem="
                e =>
                  (formData.applyBrandSeriesList = (e || []).map(item => ({
                    code: item.code,
                    name: item.name
                  })))
              "
            />
            <RequestInput
              delay-update
              prop="planShopArea"
              class="formItem"
              label="计划慕思门店面积"
              v-model="formData.planShopArea"
            />
            <RequestInput
              delay-update
              prop="permanentPopulationQuantity"
              class="formItem"
              label="所在地区常驻人口数量(万人)"
              v-model="formData.permanentPopulationQuantity"
              type="number"
            />
            <RequestInput
              delay-update
              prop="flowPopulationQuantity"
              class="formItem"
              label="所在地区流动人口数量(万人)"
              v-model="formData.flowPopulationQuantity"
              type="number"
            />
            <RequestInput
              delay-update
              prop="localGdp"
              class="formItem"
              label="当地GDP(亿)"
              v-model="formData.localGdp"
              type="number"
            />
            <RequestInput
              delay-update
              prop="perCapitaGdp"
              class="formItem"
              label="人均GDP(万)"
              v-model="formData.perCapitaGdp"
              type="number"
            />
            <RequestInput
              delay-update
              prop="perCapitaIncome"
              class="formItem"
              label="人均可支配收入(万)"
              v-model="formData.perCapitaIncome"
              type="number"
            />
            <RequestInput
              delay-update
              prop="urbanPopulation"
              class="formItem"
              label="城镇人口数量(万人)"
              v-model="formData.urbanPopulation"
              type="number"
            />
            <RequestInput
              delay-update
              prop="thisYearhouseDeliverQuantity"
              class="formItem"
              label="今年市场交楼数量(户) "
              v-model="formData.thisYearhouseDeliverQuantity"
              type="number"
            />
            <RequestInput
              delay-update
              prop="houseAveragePrice"
              class="formItem"
              label="房价均价(元)"
              v-model="formData.houseAveragePrice"
              type="number"
            />
          </div>
        </template>
      </HeaderTitle>
      <!-- <HeaderTitle title="意向品牌">
        <template #form>
          <div>
            <required-brand-form-item-select
              ref="dealeShopBrandRef"
              class="formItem"
              prop="applyBrandSeries"
              v-model="formData.applyBrandSeries"
              label="授权意向品牌"
              :select-props="{
                valueKey: 'name'
              }"
              :value-translate="
                value => {
                  return value && typeof value === 'string'
                    ? value.split(',')
                    : []
                }
              "
              :value-output-translate="
                value => {
                  if (
                    Object.prototype.toString.call(value) === '[object Array]'
                  ) {
                    return value.join(',')
                  }
                  return value
                }
              "
              @changeSelectedItem="
                e =>
                  (formData.applyBrandSeriesList = (e || []).map(item => ({
                    code: item.code,
                    name: item.name
                  })))
              "
            />
          </div>
        </template>
      </HeaderTitle> -->
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
  onUnmounted,
  watchEffect
} from 'vue'
import HeaderTitle from '../headerTitle.vue'
import RequestInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequestSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequestFormSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequestDatePick from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import { getProvinceOrCityOrAreaList } from '@/api/common/'
import { getSession, setSession, removeSession } from '@/utils/storage'
import { getStorage } from '@/utils/storage'
import moment from 'moment'
import { MessageBox, Message } from 'element-ui'
import { requestDraftFlow, requestSubmitFlow } from '@/api/flow/dealerInterview'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router/composables'
const userInfo = getStorage('userInfo')

export default defineComponent({
  components: {
    HeaderTitle,
    RequestInput,
    RequestSelect,
    RequestFormSelect,
    RequestDatePick,
    RequiredBrandFormItemSelect,
    UploadFile
  },
  props: {
    isOA: Boolean,
    validations: Object,
    dataValue: Object
  },
  setup(props, ctx) {
    const $route = useRoute()
    const $router = useRouter()
    const isCache = ref(true)
    const formId = ref()
    const dealerBusinessRecordListRowId = ref([])
    const dealerCooperationRecordListRowId = ref([])
    const marketDataListRowId = ref([])
    const formData = ref({
      dealerDeclarationForm: {
        title: '经销商意向加盟申请表',
        optUserId: userInfo?.accountId || '',
        optUserName: userInfo?.realName || '',
        optJobNumber: userInfo?.code || '',
        optDeptId: userInfo?.deptId || '',
        optDeptName: userInfo?.deptName || '',
        optTime: ''
      },
      realName: '',
      age: '',
      idCard: '',
      sex: null,
      education: '',
      maritalStatus: '',
      joinType: 2,
      joinTypeName: '新系列',
      joinProvince: '',
      joinProvinceCode: '',
      joinCity: '',
      joinCityCode: '',
      joinArea: '',
      joinAreaCode: '',
      joinTown: '',
      authorizedCode: '',
      authorizedCity: '',
      cityLevel: '',
      permanentPopulationQuantity: '',
      flowPopulationQuantity: '',
      localGdp: '',
      perCapitaGdp: '',
      perCapitaIncome: '',
      urbanPopulation: '',
      thisYearhouseDeliverQuantity: '',
      houseAveragePrice: '',
      applyBrandSeriesList: [],
      applyBrandSeries: '',
      planShopArea: '',
      dealerCooperationRecordList: [
        {
          id: '',
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          agentCity: '',
          agentBrand: '',
          applySituation: ''
        }
      ],
      dealerBusinessRecordList: [
        {
          id: '',
          brandName: '',
          industryType: '',
          businessSummary: '',
          businessTotalTime: '',
          businessStartTime: ''
        }
      ],
      localMarketSituationList: [
        {
          id: '',
          brandName: '',
          businessSummary: ''
        }
      ],
    })

    // watch(formData.value, newVal => {
    //   const val = newVal
    //   const applyBrandSeries = val.applyBrandSeries
    //     ? '门店品牌：' + val.applyBrandSeries
    //     : ''
    //   let data = [val.authorizedCity, val.realName, applyBrandSeries].filter(
    //     item => item !== ''
    //   )
    //   val.dealerDeclarationForm.title =
    //     data.length > 0
    //       ? `经销商意向加盟申请表（${data.join('，')}）`
    //       : `经销商意向加盟申请表`
    // })
    // watchEffect(() => {
    //   const val = formData.value
    //   const applyBrandSeries = val.applyBrandSeries
    //     ? '门店品牌：' + val.applyBrandSeries
    //     : ''
    //   let data = [val.authorizedCity, val.realName, applyBrandSeries].filter(
    //     item => item !== ''
    //   )
    //   val.dealerDeclarationForm.title =
    //     data.length > 0
    //       ? `经销商意向加盟申请表（${data.join('，')}）`
    //       : `经销商意向加盟申请表`
    // })
    watchEffect(() => {
      if (formId.value && props.dataValue?.dealerDeclarationForm?.id) {
        formData.value = props.dataValue
      }
    })

    // /**
    //  * @description: 获取表单详情
    //  */
    // const getFlowDetail = async () => {
    //   const res = await requestFlowDetail(formId.value)
    //   formData.value = res
    // }
    /**
     * @description: 新增当地市场情况
     */
    const addMarketData = () => {
      formData.value.localMarketSituationList.push({
        sort: formData.value.localMarketSituationList.length,
        id: '',
        brandName: '',
        businessSummary: ''
      })
    }
    /**
     * @description: 删除当地市场情况
     */
    const delMarketData = () => {
      const list = formData.value.localMarketSituationList.filter(
        l => !marketDataListRowId.value.includes(l.sort)
      )
      formData.value.localMarketSituationList = [...list]
      if (formData.value.localMarketSituationList.length === 0) {
        formData.value.localMarketSituationList = [
          {
            sort: formData.value.localMarketSituationList.length,
            id: '',
            brandName: '',
            businessSummary: ''
          }
        ]
      }
    }
    /** 选择当地市场列
     * @description:
     * @param {Array<{id:string}>} r
     */
    const handleSelectionMarketChange = r => {
      marketDataListRowId.value = r.map(item => item.sort)
    }
    /**
     * @description: 新增与慕思的合作情况
     */
    const addDealerMsg = () => {
      formData.value.dealerCooperationRecordList.push({
        sort: formData.value.dealerCooperationRecordList.length,
        id: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        agentCity: '',
        agentBrand: '',
        applySituation: ''
      })
    }
    /**
     * @description: 删除与慕思的合作情况
     */
    const delDealerMsg = () => {
      const list = formData.value.dealerCooperationRecordList.filter(
        l => !dealerCooperationRecordListRowId.value.includes(l.sort)
      )
      formData.value.dealerCooperationRecordList = [...list]
      if (formData.value.dealerCooperationRecordList.length === 0) {
        formData.value.dealerCooperationRecordList = [
          {
            sort: formData.value.dealerCooperationRecordList.length,
            id: '',
            province: '',
            provinceCode: '',
            city: '',
            cityCode: '',
            agentCity: '',
            agentBrand: '',
            applySituation: ''
          }
        ]
      }
    }
    /** 选择经销商列
     * @description:
     * @param {Array<{id:string}>} r
     */
    const handleSelectionDealerChange = r => {
      dealerCooperationRecordListRowId.value = r.map(item => item.sort)
    }

    /**
     * @description: 新增现在从事经营的行业情况
     */
    const addCurrent = () => {
      formData.value.dealerBusinessRecordList.push({
        sort: formData.value.dealerBusinessRecordList.length,
        id: '',
        brandName: '',
        industryType: '',
        businessSummary: '',
        businessTotalTime: '',
        businessStartTime: ''
      })
    }
    /**
     * @description: 删除现在从事经营的行业情况
     */
    const delCurrent = () => {
      const list = formData.value.dealerBusinessRecordList.filter(
        l => !dealerBusinessRecordListRowId.value.includes(l.sort)
      )
      formData.value.dealerBusinessRecordList = [...list]
      if (formData.value.dealerBusinessRecordList.length === 0) {
        formData.value.dealerBusinessRecordList = [
          {
            sort: formData.value.dealerBusinessRecordList.length,
            id: '',
            brandName: '',
            industryType: '',
            businessSummary: '',
            businessTotalTime: '',
            businessStartTime: ''
          }
        ]
      }
    }
    /** 选择经销商列
     * @description:
     * @param {Array<{id:string}>} r
     */
    const handleSelectionCurrentChange = r => {
      dealerBusinessRecordListRowId.value = r.map(item => item.sort)
    }

    /**
     * @description: 获取授权城市
     * @param {*} getDataListParams
     * @return {Array<{province:string,city:string,area:string,town:string,id:string}>}
     */
    const getProvince = async () => {
      return await getProvinceOrCityOrAreaList({
        type: 'province'
      }).then(res => res.data)
    }
    const getAuthCityList = ({ getDataListParams: province }) => {
      if (!province) return []
      return getProvinceOrCityOrAreaList({
        type: 'city',
        province
      }).then(({ data }) => data)
    }
    const getAuthAreaList = ({ getDataListParams: dataString }) => {
      const [province, city] = dataString.split(',')
      if (!province || !city) return []
      return getProvinceOrCityOrAreaList({
        type: 'area',
        province,
        city
      }).then(({ data }) => data)
    }

    /**
     * @description: 文件预览
     * @param {*} url
     * @return {*}
     */
    const handlePreview = ({ url }) => {
      window.open(url)
    }
    const handleUpload = (fileList, fileName) => {
      formData.value.fileList = fileList.map(item => {
        return {
          ...item.response,
          functionAttribution: `${fileName}`
        }
      })
    }
    const flagVisable = ref(true)
    const handleValidate = (_, flag, message) => {
      if (!flag && flagVisable.value) {
        Message.warning(message)
        flagVisable.value = false
      }
    }

    const getFormFlowTitle = () => {
      const val = formData.value
      const applyBrandSeries = val.applyBrandSeries
        ? '门店品牌：' + val.applyBrandSeries
        : ''
      let data = [val.authorizedCity, val.realName, applyBrandSeries].filter(
        item => item !== ''
      )
      return data.length > 0
        ? `经销商意向加盟申请表（${data.join('，')}）`
        : `经销商意向加盟申请表`
    }
    const flowTitle = computed(() => {
      const title = getFormFlowTitle()
      return title
    })
    /**
     * @description:保存0/提交1
     * @param {0|1} code
     * @return {*}
     */
    const ruleForm = ref()
    const handleSave = async code => {
      isCache.value = false
      formData.value.dealerDeclarationForm.optTime = moment().format(
        'yyyy-MM-DD HH:mm:ss'
      )
      formData.value.dealerDeclarationForm.title = getFormFlowTitle()
      if (code) {
        flagVisable.value = true
        ruleForm.value.validate(async valid => {
          if (valid) {
            try {
              const res = await requestDraftFlow(formData.value)
              await requestSubmitFlow({
                formId: res.id
              })
              MessageBox.alert('提交成功！', '提示', {
                type: 'success'
              }).then(() => {
                removeSession('dealerInterview')
                $router.push({
                  name: 'myApply'
                })
              })
            } catch (error) {
              console.log(error.message)
            }
          }
        })
      } else {
        await requestDraftFlow(formData.value)
        MessageBox.alert('保存成功！', '提示', {
          type: 'success'
        }).then(() => {
          removeSession('dealerInterview')
          $router.push({
            name: 'draft'
          })
        })
      }
    }
    /**
     * @description: 是否启动缓存机制
     */
    const establishCache = () => {
      console.log('establishCache')
      const formOptions = formData.value
      if (isCache.value) {
        setSession('dealerInterview', formOptions)
      } else {
        removeSession('dealerInterview')
      }
    }
    window.addEventListener('beforeunload', establishCache)
    onMounted(async () => {
      formId.value = $route.query.id || null
      const cacheData = getSession('dealerInterview')
      if (cacheData && !formId.value) {
        formData.value = cacheData
        return
      }
      // if (formId.value) {
      //   await getFlowDetail()
      // }
    })
    onUnmounted(() => {
      window.removeEventListener('beforeunload', establishCache)
    })
    onBeforeRouteLeave((to, from, next) => {
      establishCache()
      next()
    })
    return {
      handleSave,
      handleUpload,
      handlePreview,
      getAuthAreaList,
      getAuthCityList,
      getProvince,
      handleSelectionCurrentChange,
      delCurrent,
      addCurrent,
      handleValidate,
      delDealerMsg,
      addDealerMsg,
      handleSelectionDealerChange,
      addMarketData,
      delMarketData,
      handleSelectionMarketChange,marketDataListRowId,
      formData,
      dealerCooperationRecordListRowId,
      dealerBusinessRecordListRowId,
      flowTitle,
      ruleForm
    }
  }
})
</script>

<style scoped lang="scss">
.selectItem {
  margin: 0;
  width: 100%;
  &.is-error {
    margin-bottom: 22px;
  }
}
</style>
